import { createAction, handleActions } from 'redux-actions';
import { api } from "api";
import { initialize as initializeForm } from "redux-form";
import { NotificationManager } from "react-notifications";
import { push } from "react-router-redux";
import _ from "lodash";
import { reset } from 'redux-form';
import { USER_ADMIN } from "../../../utility/constants";
import moment from 'moment';

const LOADER = 'LOADER_REPORTES_OFERTAS_Y_ADJUDICADOS';
const DATA = 'DATA_REPORTES_OFERTAS_Y_ADJUDICADOS';
const ITEM_DATA = 'ITEM_REPORTES_OFERTAS_Y_ADJUDICADOS';
const PAGE = 'PAGE_REPORTES_OFERTAS_Y_ADJUDICADOS';
const ORDERING = 'ORDERING_REPORTES_OFERTAS_Y_ADJUDICADOS';
const SEARCH = 'SEARCH_REPORTES_OFERTAS_Y_ADJUDICADOS';
const TOTALES = 'TOTALES_REPORTES_OFERTAS_Y_ADJUDICADOS';
const GRAFICA = 'GRAFICA_REPORTES_OFERTAS_Y_ADJUDICADOS';

// FILTROS DE REPORTES PROYECTOS Y OFERTAS
const FILTRO_FECHA_INICIO_OFERTAS_Y_ADJUDICADOS = 'FILTRO_FECHA_INICIO_OFERTAS_Y_ADJUDICADOS';
const FILTRO_FECHA_FIN_OFERTAS_Y_ADJUDICADOS = 'FILTRO_FECHA_FIN_OFERTAS_Y_ADJUDICADOS';
const FILTRO_TRANSPORTISTA_OFERTAS_Y_ADJUDICADOS = 'FILTRO_TRANSPORTISTA_OFERTAS_Y_ADJUDICADOS';

const FILTRO_PRECIO_MAX_PROYECTOS = 'FILTRO_PRECIO_MAX_PROYECTOS';
const FILTRO_PRECIO_MIN_PROYECTOS = 'FILTRO_PRECIO_MIN_PROYECTOS';
const PRECIO_MAX_PROYECTOS = 'PRECIO_MAX_PROYECTOS';
const PRECIO_MIN_PROYECTOS = 'PRECIO_MIN_PROYECTOS';
// -----------------------------------
// Pure Actions
// -----------------------------------

const setLoader = loader => ({
    type: LOADER,
    loader,
});

const setData = data => ({
    type: DATA,
    data,
});

const setItem = item => ({
    type: ITEM_DATA,
    item,
});

const setPage = page => ({
    type: PAGE,
    page,
});

const setOrdering = ordering => ({
    type: ORDERING,
    ordering,
});

const setSearch = search => ({
    type: SEARCH,
    search,
});

const setTotales = totales => ({
    type: TOTALES,
    totales,
});

const setGrafica = data_grafica => ({
    type: GRAFICA,
    data_grafica,
});

// ------------------------------------
// Actions
// ------------------------------------

const infoProyectosPublicadados = () => async (dispatch, getStore) => {
    const resource = getStore().ofertasYAdjudicados;
    const params = {};

    if (resource.filtro_fecha_inicio && resource.filtro_fecha_fin) {
        params.fecha_inicio = moment(resource.filtro_fecha_inicio).format('YYYY-MM-DD');
        params.fecha_fin = moment(resource.filtro_fecha_fin).format('YYYY-MM-DD');
    }
    if (resource.filtro_transportista) {
        params.transportista = resource.filtro_transportista.id;
    }
    dispatch(setLoader(true));
    try {
        const response = await api.get('reportes/data_precio_max_min_ofertas_y_adjudicaciones', params)
        dispatch(PrecioMinTransportista(response.precio_minimo));
        dispatch(PrecioMaxTransportista(response.precio_maximo));
        dispatch(filtroPrecioMinTransportista(response.precio_minimo));
        dispatch(filtroPrecioMaxTransportista(response.precio_maximo));
    } catch (err) {
        // NotificationManager.error('Error en la edición', 'ERROR', 3000);
    } finally {
        dispatch(setLoader(false));
    }
};

const listarTransportistasTop = (page = 1) => (dispatch, getStore) => {
    const resource = getStore().ofertasYAdjudicados;
    const params = {};

    if (resource.filtro_fecha_inicio && resource.filtro_fecha_fin) {
        params.fecha_inicio = moment(resource.filtro_fecha_inicio).format('YYYY-MM-DD');
        params.fecha_fin = moment(resource.filtro_fecha_fin).format('YYYY-MM-DD');
    }
    if (resource.filtro_transportista) {
        params.transportista = resource.filtro_transportista.id;
    }
    params.filtro_precio_min = resource.filtro_precio_min;
    params.filtro_precio_max = resource.filtro_precio_max;

    dispatch(setLoader(true));
    api.get('reportes/transportistas_top_proyectos_realizados', params).then((response) => {
        dispatch(setData(response));
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const dataGraficaOfertaYAdjudicadoReporte = () => (dispatch, getStore) => {
    const resource = getStore().ofertasYAdjudicados;
    const params = {};

    if (resource.filtro_fecha_inicio && resource.filtro_fecha_fin) {
        params.fecha_inicio = moment(resource.filtro_fecha_inicio).format('YYYY-MM-DD');
        params.fecha_fin = moment(resource.filtro_fecha_fin).format('YYYY-MM-DD');
    }
    if (resource.filtro_transportista) {
        params.transportista = resource.filtro_transportista.id;
    }
    params.filtro_precio_min = resource.filtro_precio_min;
    params.filtro_precio_max = resource.filtro_precio_max;

    dispatch(setLoader(true));
    api.get('reportes/data_grafica_reporte_proyectos_ofertas_y_adjudicaciones', params).then((response) => {
        dispatch(setGrafica(response));
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const dataReporteProyectoTotalPagado = () => (dispatch, getStore) => {
    const resource = getStore().ofertasYAdjudicados;
    const params = {};

    if (resource.filtro_fecha_inicio && resource.filtro_fecha_fin) {
        params.fecha_inicio = moment(resource.filtro_fecha_inicio).format('YYYY-MM-DD');
        params.fecha_fin = moment(resource.filtro_fecha_fin).format('YYYY-MM-DD');
    }
    if (resource.filtro_transportista) {
        params.transportista = resource.filtro_transportista.id;
    }
    params.filtro_precio_min = resource.filtro_precio_min;
    params.filtro_precio_max = resource.filtro_precio_max;

    dispatch(setLoader(true));
    api.get('reportes/reporte_proyecto_total_pagos', params).then((response) => {
        dispatch(setTotales(response));
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const changeFechaInicio = filtro_fecha_inicio => (dispatch) => {
    dispatch({ type: FILTRO_FECHA_INICIO_OFERTAS_Y_ADJUDICADOS, filtro_fecha_inicio });
    dispatch(dataReporteProyectoTotalPagado())
    dispatch(listarTransportistasTop())
    dispatch(dataGraficaOfertaYAdjudicadoReporte())
};

const changeFechaFinal = filtro_fecha_fin => (dispatch) => {
    dispatch({ type: FILTRO_FECHA_FIN_OFERTAS_Y_ADJUDICADOS, filtro_fecha_fin });
    dispatch(dataReporteProyectoTotalPagado())
    dispatch(listarTransportistasTop())
    dispatch(dataGraficaOfertaYAdjudicadoReporte())
};

const changeTransportista = filtro_transportista => (dispatch) => {
    dispatch({ type: FILTRO_TRANSPORTISTA_OFERTAS_Y_ADJUDICADOS, filtro_transportista });
    dispatch(dataReporteProyectoTotalPagado())
    dispatch(listarTransportistasTop())
    dispatch(dataGraficaOfertaYAdjudicadoReporte())
};

const filtroPrecioMaxTransportista = filtro_precio_max => (dispatch) => {
    dispatch({ type: FILTRO_PRECIO_MAX_PROYECTOS, filtro_precio_max })
};

const filtroPrecioMinTransportista = filtro_precio_min => (dispatch) => {
    dispatch({ type: FILTRO_PRECIO_MIN_PROYECTOS, filtro_precio_min })
};

const PrecioMaxTransportista = precio_max => (dispatch) => {
    dispatch({ type: PRECIO_MAX_PROYECTOS, precio_max })
};

const PrecioMinTransportista = precio_min => (dispatch) => {
    dispatch({ type: PRECIO_MIN_PROYECTOS, precio_min })
};
export const actions = {
    listarTransportistasTop,
    dataGraficaOfertaYAdjudicadoReporte,
    dataReporteProyectoTotalPagado,
    changeFechaInicio,
    changeFechaFinal,
    changeTransportista,
    infoProyectosPublicadados,
    filtroPrecioMaxTransportista,
    filtroPrecioMinTransportista,
    PrecioMaxTransportista,
    PrecioMinTransportista,
};

// ------------------------------------
// Reducers
// ------------------------------------

export const reducers = {
    [LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    [DATA]: (state, { data }) => {
        return {
            ...state,
            data,
        };
    },
    [ITEM_DATA]: (state, { item }) => {
        return {
            ...state,
            item,
        };
    },
    [PAGE]: (state, { page }) => {
        return {
            ...state,
            page,
        };
    },
    [ORDERING]: (state, { ordering }) => {
        return {
            ...state,
            ordering,
        };
    },
    [SEARCH]: (state, { search }) => {
        return {
            ...state,
            search,
        };
    },
    [SEARCH]: (state, { search }) => {
        return {
            ...state,
            search,
        };
    },
    [TOTALES]: (state, { totales }) => {
        return {
            ...state,
            totales,
        };
    },
    [GRAFICA]: (state, { data_grafica }) => {
        return {
            ...state,
            data_grafica,
        };
    },
    // FILTROS DE REPORTES PROYECTOS Y OFERTAS
    [FILTRO_FECHA_INICIO_OFERTAS_Y_ADJUDICADOS]: (state, { filtro_fecha_inicio }) => {
        return {
            ...state,
            filtro_fecha_inicio,
        };
    },
    [FILTRO_FECHA_FIN_OFERTAS_Y_ADJUDICADOS]: (state, { filtro_fecha_fin }) => {
        return {
            ...state,
            filtro_fecha_fin,
        };
    },
    [FILTRO_TRANSPORTISTA_OFERTAS_Y_ADJUDICADOS]: (state, { filtro_transportista }) => {
        return {
            ...state,
            filtro_transportista,
        };
    },
    [FILTRO_PRECIO_MAX_PROYECTOS]: (state, { filtro_precio_max }) => {
        return {
            ...state,
            filtro_precio_max,
        };
    },
    [FILTRO_PRECIO_MIN_PROYECTOS]: (state, { filtro_precio_min }) => {
        return {
            ...state,
            filtro_precio_min,
        };
    },
    [PRECIO_MAX_PROYECTOS]: (state, { precio_max }) => {
        return {
            ...state,
            precio_max,
        };
    },
    [PRECIO_MIN_PROYECTOS]: (state, { precio_min }) => {
        return {
            ...state,
            precio_min,
        };
    },
};

export const initialState = {
    loader: false,
    data: {
        results: [],
        count: 0,
    },
    data_grafica: [],
    item: {},
    totales: {},
    page: 1,
    ordering: '',
    search: '',
    filtro_fecha_inicio: null,
    filtro_fecha_fin: moment().endOf('month'),
    filtro_transportista: null,
    filtro_precio_min: 0,
    filtro_precio_max: 100,
    precio_min: 0,
    precio_max: 100000,
};

export default handleActions(reducers, initialState);
