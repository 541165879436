import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { Link } from "react-router-dom";

const alerta_img = require('assets/img/icons/alert.svg');

export default class NotFound extends Component {
    render() {
        return (
            <div className="blue-gradient-bg">
                <div className="contenedor-principal">
                    <div className="col-12 h-100 d-flex justify-content-center align-items-center flex-wrap">
                        <div className="col-12 login-wrapper d-flex flex-column">
                            <div className="col-xl-4 col-lg-6 col-md-6 col-11 mb-3 text-center">
                                <img src={require('assets/img/logo_completo.svg')} className="img-fluid" alt="Logo" />
                            </div>
                            <div className="card card-login col-xl-4 col-lg-6 col-md-7 col-11 mb-5">
                                <div name="logindiv" className="form-validate d-flex flex-sm-row align-items-center" >
                                    <div className="col-12">
                                        <div className="d-flex justify-content-center flex-column align-items-center mb-3">
                                            <img src={alerta_img} alt="Alerta" width={50} className="img-fluid blink-danger" />
                                            <span className="naranja bold mt-1 text-lgs">Error 404</span>
                                            <span className="gris-oscuro bold mt-1 text-lg">Página no encontrada</span>
                                        </div>
                                        <div className="d-flex flex-wrap justify-content-center align-items-center mt-3">
                                            <div className="col-md-5 col-12">
                                                <Link className="btn btn-dark btn-block" to="/">Regresar</Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
