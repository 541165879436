import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { validate, validators } from 'validate-redux-form';
import { Field, reduxForm, formValueSelector, } from 'redux-form';
import { renderField, renderSwitch, SelectField } from '../../../Utils/renderField/renderField';
import { USER_ADMIN, USER_CONTRATISTA_EMPRESA, USER_CONTRATISTA_INDIVIDUAL, USER_TRANSPORTISTA } from '../../../../../utility/constants';

import { RenderDollar } from '../../../Utils/renderField/renderReadField';
import moment from 'moment';

let Devoluciones = props => {
    const { realizarDevolucion, item = {} } = props;

    const {
        creado = "--",
        monto_a_devolver = 0,
        razon = "--",
        justificacion = "--",
        devolucion_concluida = true,
        beneficiario = {},
        solicitado_por = {},
        responsable = {},
        viaje = {},
        proyecto = {},
    } = item ? item : {}

    const {
        username = "--",
        nombre = "--",
        telefono = "--",
        email = "--",
    } = beneficiario ? beneficiario : {}

    const {
        username: solicitado_por_username = "--",
        nombre: solicitado_por_nombre = "--",
        tipo_usuario: solicitado_por_tipo_usuario = 1,
    } = solicitado_por ? solicitado_por : {}

    const {
        username: responsable_username = "--",
        nombre: responsable_nombre = "--",
        telefono: responsable_telefono = "--",
        email: responsable_email = "--",
    } = responsable ? responsable : {}

    // const {
    //     proyecto = {},
    // } = viaje ? viaje : {}

    const {
        nombre: nombreProyecto = "--",
        justificacion_cancelacion = "",
    } = proyecto ? proyecto : {}


    return (
        <div className="form-group grid-container col-12 px-0 px-sm-4 pt-4">

            <div className="row">
                <div className="col-12">
                    <label className="h5 m-0 mb-2 px-0">Información del contratista beneficiario </label>
                </div>

                <div className="col-12">
                    <span className="m-0 gris bold">Nombre del proyecto</span>
                    <p className="bold text-sm gris-oscuro mb-2">{nombreProyecto}</p>
                </div>

                <div className="col-sm-6">
                    <span className="m-0 gris bold">Usuario</span>
                    <p className="bold text-sm gris-oscuro mb-2">{username}</p>
                </div>


                <div className="col-sm-6">
                    <span className="m-0 gris bold">Nombre</span>
                    <p className="bold text-sm gris-oscuro mb-2">{nombre}</p>
                </div>

                <div className="col-sm-6">
                    <span className="m-0 gris bold">Teléfono</span>
                    <p className="bold text-sm gris-oscuro mb-2">{telefono}</p>
                </div>

                <div className="col-sm-6">
                    <span className="m-0 gris bold">E-mail</span>
                    <p className="bold text-sm gris-oscuro mb-2">{email}</p>
                </div>

                <div className="col-sm-6">
                    <span className="m-0 gris bold">Fecha de cancelación</span>
                    <p className="bold text-sm gris-oscuro mb-2">{moment(creado).format('DD-MM-YYYY HH:mm')}</p>
                </div>

                <div className="col-sm-6">
                    <span className="m-0 gris bold">Monto de devolución</span>
                    <p className=" text-sm gris-oscuro mb-2 bold total-pagar naranja"><RenderDollar className="bold total-pagar naranja" value={monto_a_devolver} /></p>
                </div>
            </div>

            <br />
            <div className="row">

                <div className="col-12">
                    <label className="h5 m-0 mb-2 px-0">Petición realizado por:</label>
                </div>

                <div className="col-12">
                    <span className="m-0 gris bold">Tipo de usuario:</span>
                    <p className="bold text-sm gris-oscuro mb-2">{
                        solicitado_por_tipo_usuario == USER_ADMIN
                            ? "ADMINISTRADOR" :
                            solicitado_por_tipo_usuario == USER_TRANSPORTISTA
                                ? "TRANSPORTISTA" :
                                solicitado_por_tipo_usuario == USER_CONTRATISTA_EMPRESA
                                    ? "CONTRATISTA EMPRESA" :
                                    solicitado_por_tipo_usuario == USER_CONTRATISTA_INDIVIDUAL
                                        ? "CONTRATISTA INDIVIDUAL" : "--"
                    }
                    </p>
                </div>

                <div className="col-sm-6">
                    <span className="m-0 gris bold">Usuario</span>
                    <p className="bold text-sm gris-oscuro mb-2">{solicitado_por_username}</p>
                </div>


                <div className="col-sm-6">
                    <span className="m-0 gris bold">Nombre:</span>
                    <p className="bold text-sm gris-oscuro mb-2">{solicitado_por_nombre}</p>
                </div>


                <div className="col-12">
                    <span className="m-0 gris bold">Razón:</span>
                    <p className="bold text-sm gris-oscuro mb-2">{razon}</p>
                </div>

                <div className="col-12">
                    <span className="m-0 gris bold">Justificación</span>
                    <p className="bold text-sm gris-oscuro mb-2">{justificacion}</p>
                </div>

                {justificacion_cancelacion ?
                    < div className="col-12">
                        <span className="m-0 gris bold">Un transportista, desadjudico su oferta, con la justificación:</span>
                        <p className="bold text-sm gris-oscuro mb-2">{justificacion_cancelacion}</p>
                    </div>
                    :

                    <div className="col-12 text-right">
                        <span className="azul bold text-sm">* Recuerde realizar el cobro de la penalización</span>
                    </div>

                }

            </div>


            {
                devolucion_concluida &&
                <Fragment>
                    <br />
                    <div className="row">

                        <div className="col-12">
                            <label className="h5 m-0 mb-2 px-0">Administrador responsable de la devolución:</label>
                        </div>

                        <div className="col-sm-6">
                            <span className="m-0 gris bold">Usuario</span>
                            <p className="bold text-sm gris-oscuro mb-2">{responsable_username}</p>
                        </div>


                        <div className="col-sm-6">
                            <span className="m-0 gris bold">Nombre:</span>
                            <p className="bold text-sm gris-oscuro mb-2">{responsable_nombre}</p>
                        </div>

                        <div className="col-sm-6">
                            <span className="m-0 gris bold">Teléfono</span>
                            <p className="bold text-sm gris-oscuro mb-2">{responsable_telefono}</p>
                        </div>

                        <div className="col-sm-6">
                            <span className="m-0 gris bold">E-mail</span>
                            <p className="bold text-sm gris-oscuro mb-2">{responsable_email}</p>
                        </div>

                    </div>
                </Fragment>
            }
            <div className="d-flex flex-wrap justify-content-center align-items-center mt-5">
                <div className="col-md-4 col-12">
                    <Link className="btn btn-dark btn-block" to="/devoluciones">Cancelar</Link>
                </div>
                {!devolucion_concluida &&
                    <div className="col-md-4 col-12 mt-md-0 mt-3">
                        <button type="button" className="btn btn-primary btn-block" onClick={realizarDevolucion}>Realizar devolución</button>
                    </div>
                }
            </div>
        </div >
    )
};
export default Devoluciones
