import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { validate } from 'validate-redux-form';
import { Field, reduxForm, formValueSelector, FieldArray } from 'redux-form';
import { renderField, renderSwitch, SelectField, renderFieldRadio2 } from '../../../Utils/renderField/renderField';
import { tipo_vehiculo } from '../../../../../utility/constants';
const eliminar_img = require('assets/img/icons/borrar.svg');
import { validationOtros } from '../../../../../utility/validationForm'

const renderContactos = ({ fields, meta: { error, submitFailed }, contactos }) => {
    if (!fields.length) {
        fields.push({});
    }
    return (
        <div className=" col-sm-12 p-0 mt-2">
            <div className=" col-sm-12 form-group np-r p-0">
                <div className="react-bs-container-header table-header-wrapper">
                    <table className="table mb-0">
                        <thead>
                            <tr>
                                <th style={{ width: "20%" }}></th>
                                <th>CIUDAD</th>
                                <th style={{ width: "35%" }}>CABECERA</th>
                            </tr>

                        </thead>

                    </table>

                </div>
                {/*Contenido de la tabla*/}
                <div className="contenido-ciudades">
                    <table className="table table-sm  m-0 table-striped">
                        <tbody>
                            {fields.map((ciudad, index) => (
                                <tr key={index}>
                                    <td className="text-center " style={{ width: "20%" }}>
                                        <img src={eliminar_img} alt="Eliminar"
                                            className="action_img"
                                            onClick={() => {
                                                fields.remove(index)
                                            }}
                                        />
                                    </td>
                                    <td className="">
                                        <Field
                                            name={`${ciudad}.nombre`}
                                            type="text"
                                            component={renderField}
                                            maxLength={validationOtros.numeroLetro50.MaxLength}
                                        />
                                    </td>
                                    <td className="text-center" style={{ width: "35%" }}>
                                        <Field
                                            label=''
                                            type="radio"
                                            value={index}
                                            name={`cabecera`}
                                            parse={val => +val}
                                            component={renderFieldRadio2}
                                        />
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
                <div className="d-flex justify-content-center mt-3">
                    <button type="button" className="btn btn-secondary py-1" onClick={() => fields.push({})}>
                        AGREGAR CIUDAD
                </button>
                </div>
            </div>
        </div>
    )
}


let Form = props => {
    const { handleSubmit } = props;
    return (
        <form onSubmit={handleSubmit}>
            <div className="form-group grid-container col-12 px-0 px-sm-4">
                <div className="row  col-md-12 p-0">
                    <div className="col-md-6 p-0">
                        <div className="col-md-12 col-sm-12">
                            <label htmlFor="nombre" className="m-0">Nombre del País</label>
                        </div>
                        <div className="col-md-12  form-group">
                            <Field name="nombre" component={renderField} type="text" className="form-control" maxLength={validationOtros.numeroLetro50.MaxLength} />
                        </div>
                    </div>
                </div>
                <div className="row  col-md-12 p-0">
                    <div className="col-md-12 p-0">
                        <div className="col-md-12 col-sm-12">
                            <label htmlFor="nombre" className="m-0">Ciudades del País</label>
                        </div>
                        <div className="col-md-12  form-group">
                            <FieldArray name="ciudades" component={renderContactos} />
                        </div>
                    </div>
                </div>



                <div className="d-flex flex-wrap justify-content-center align-items-center mt-5">
                    <div className="col-md-4 col-12">
                        <Link className="btn btn-dark btn-block" to="/paises">Cancelar</Link>
                    </div>
                    <div className="col-md-4 col-12 mt-md-0 mt-3">
                        <button type="submit" className="btn btn-primary btn-block">Guardar</button>
                    </div>
                </div>
            </div>
        </form>
    )
};

const validatePrimary = (values) => {
    let errors = {};
    let DetalleArrayErrors = [];
    if (values && values.ciudades && values.ciudades.length) {
        values.ciudades.forEach((detalle, detalleIndex) => {
            DetalleArrayErrors[detalleIndex] = validate(detalle, {
                nombre: validationOtros.numeroLetro50.Combine,
            })
        });
    }
    errors.ciudades = DetalleArrayErrors
    errors = {
        ...errors,
        ...validate(values, {
            nombre: validationOtros.numeroLetro50.Combine,
        })
    }
    return errors
};

Form = reduxForm({
    form: 'PaisForm',
    validate: validatePrimary
})(Form);

export default Form
