import React, { Component } from 'react'
import LoadMask from 'Utils/LoadMask/LoadMask';
import CardForm from 'Utils/Cards/CardForm';
import ContraForm from './Form';
import {Redirect} from 'react-router-dom';

export default class Editar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            editar: false,
            rtu_img: null
        };
    }

    componentWillMount(){
        if(this.props.match.params.id){
            this.props.leer(this.props.match.params.id);
            this.setState({
                editar: true,
            });
        }
    }
    actualizar = (data) => {
        this.props.editar(this.props.match.params.id, {...data, rtu_img: null}, [{"file": this.state.rtu_img, "name": "rtu_img"}])
    }; 
    setRtuImg = (rtu_img) => {
        this.setState({rtu_img});
    };
    render() {
        const { crear} = this.props;
        const { loader,updateData, item } = this.props;
        return (
            <div className="mb-4 col-12 mt-4">
                <CardForm titulo="EDITANDO CONTRATISTA">
                    <LoadMask loading={loader} blur>
                        {
                        this.state.editar ?
                            <ContraForm
                                onSubmit={this.actualizar}
                                editando={true}
                                updateData={updateData}
                                item={item}
                                setRtuImg={this.setRtuImg}
                            />
                            : <Redirect to="/contratistas_validados" /> 
                        }
                    </LoadMask>
                </CardForm>
            </div>
        )
    }
}
