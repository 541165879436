import React, { Fragment } from 'react';
import { connect } from 'react-redux'
import { Link } from 'react-router-dom';
import { validate, validators, combine } from 'validate-redux-form';
import { Field, reduxForm, formValueSelector, } from 'redux-form';
import { renderField, renderFilePicker, renderNumber, renderPasswordField } from '../../../Utils/renderField/renderField';
import { validationContratista } from '../../../../../utility/validationForm'
import { USER_CONTRATISTA_EMPRESA, USER_CONTRATISTA_INDIVIDUAL } from '../../../../../utility/constants'

let ContraForm = props => {
    const { handleSubmit, editando, item, setRtuImg } = props;
    return (
        <form onSubmit={handleSubmit}>
            <div className="form-group grid-container col-12 px-0 px-sm-4">
                <div className="row  col-md-12 p-0">
                    <div className="col-md-6 p-0">
                        <div className="col-md-12 col-sm-12">
                            <label htmlFor="nombre" className="m-0">{item && item.tipo_usuario && item.tipo_usuario === USER_CONTRATISTA_INDIVIDUAL ? "Nombre completo*" : "Nombre comercial*"}</label>
                        </div>
                        <div className="col-md-12  form-group">
                            <Field name="nombre" component={renderField} type="text" className="form-control" maxLength={validationContratista.nombre.MaxLength} />
                        </div>
                    </div>
                    <div className="col-md-6 p-0">
                        <div className="col-md-12 col-sm-12">
                            <label htmlFor="username" className="m-0">Usuario*</label>
                        </div>
                        <div className="col-md-12  form-group">
                            <Field name="username" component={renderField} type="text" className="form-control" maxLength={validationContratista.username.MaxLength} />
                        </div>
                    </div>
                </div>
                <div className="row  col-md-12 p-0">
                    <div className="col-md-6 p-0">
                        <div className="col-md-12 col-sm-12">
                            <label htmlFor="email" className="m-0">Email*</label>
                        </div>
                        <div className="col-md-12  form-group">
                            <Field name="email" component={renderField} type="text" className="form-control" maxLength={validationContratista.email.MaxLength} />
                        </div>
                    </div>
                    <div className="col-md-6 p-0">
                        <div className="col-md-12 col-sm-12">
                            <label htmlFor="nit" className="m-0">NIT*</label>
                        </div>
                        <div className="col-md-12  form-group">
                            <Field name="nit" component={renderField} type="text" className="form-control" maxLength={validationContratista.nit.MaxLength} />
                        </div>
                    </div>
                </div>

                <div className="row  col-md-12 p-0">
                    <div className="col-md-6 p-0">
                        <div className="col-md-12 col-sm-12">
                            <label htmlFor="telefono" className="m-0">Teléfono*</label>
                        </div>
                        <div className="col-md-12  form-group">
                            <Field name="telefono" component={renderField} type="text" className="form-control" maxLength={validationContratista.telefono.MaxLength} />
                        </div>
                    </div>
                    <div className="col-md-6 p-0 d-flex">
                        {(editando) &&
                            <div className="col-md-12 col-sm-12 align-self-center">
                                <Field name="new_password" placeholder="Nueva contraseña" component={renderPasswordField} minLength={6} className="form-control" />
                            </div>
                        }
                    </div>
                </div>


                {item && item.tipo_usuario && item.tipo_usuario === USER_CONTRATISTA_EMPRESA &&
                    <div className="row  col-md-12 p-0">
                        <div className="col-md-6 p-0">
                            <div className="col-md-12 col-sm-12">
                                <label htmlFor="rtu" className="m-0">RTU / Patente de comercio*</label>
                            </div>
                            <div className="col-md-12  form-group">
                                <Field name="rtu" component={renderField} type="text" className="form-control" maxLength={validationContratista.rtu.MaxLength} />
                            </div>
                        </div>
                        <div className="col-md-6 p-0">
                            <div className="col-md-12 col-sm-12">
                                <label htmlFor="rtu_img" className="m-0">Imagen de RTU / Patente de comercio</label>
                            </div>
                            <div className="col-md-12  form-group">
                                <Field photo={item.rtu_img ? item.rtu_img : null} setFile={setRtuImg} name="rtu_img" component={renderFilePicker} />
                            </div>
                        </div>
                    </div>
                }
                <div className="col-12 text-right">
                    <span className="azul bold text-xsm">* Campos requeridos</span>
                </div>
                <div className="d-flex flex-wrap justify-content-center align-items-center mt-5">
                    <div className="col-md-5 col-lg-5 col-xl-4 col-12">
                        <Link className="btn btn-dark btn-block" to="/contratistas_validados">Cancelar</Link>
                    </div>
                    <div className="col-md-5 col-lg-5 col-xl-4 col-12 mt-md-0 mt-3">
                        <button type="submit" className="btn btn-primary btn-block">Guardar</button>
                    </div>
                </div>
            </div>
        </form>
    )
};
ContraForm = reduxForm({
    form: 'EditarContratistaForm',
    validate: data => {
        let errors = {};
        if (data && data.new_password && data.new_password.length < 6) {
            errors.new_password = "Mínimo 6 carácteres"
        }
        if (data && data.new_password && data.new_password.length > 150) {
            errors.new_password = "Máximo 150 carácteres"
        }
        return {
            ...errors, ...validate(data, {
                nombre: validationContratista.nombre.Combine,
                username: validationContratista.username.Combine,
                email: validationContratista.email.Combine,
                telefono: validationContratista.telefono.Combine,
                nit: validationContratista.nit.Combine,
                rtu: validationContratista.rtu.Combine,
            })
        }
    }
})(ContraForm);

export default ContraForm;
