import React from 'react';
import { Field, reduxForm } from 'redux-form';
import {
    validate,
    validatorFromFunction,
    validators,
    combine,
} from 'validate-redux-form';
import {
    renderField,
    renderFilePicker,
    renderNumber,
    renderPasswordField,
} from '../../Utils/renderField/renderField';
import { Link } from "react-router-dom";
import { validationAdministrador } from '../../../../utility/validationForm'

const ProfileForm = (props) => {
    const { handleSubmit, me, setAvatar } = props;
    return (
        <form action="" onSubmit={handleSubmit} className="py-4">
            <div className="form-group grid-container">
                <div className="row  col-12 p-0">

                    <div className="row  col-12 p-0 mb-5">
                        <div className="col-12 col-md-6">
                            <div className="col-md-12 col-sm-12">
                                <h5 className="m-0 azul bold ">{me && me.username ? me.username : "--"} </h5>
                            </div>
                        </div>
                        <div className="col-12 col-md-6">
                            <div className="col-md-12 col-sm-12">
                                <h5 className="m-0 azul bold ">{me && me.email ? me.email : "--"} </h5>
                            </div>
                        </div>
                    </div>

                    <div className="col-12 col-md-6 p-0">
                        <div className="col-md-12 col-sm-12">
                            <label htmlFor="nombre" className="m-0">Nombre y apellido</label>
                        </div>
                        <div className="col-md-12  form-group">
                            <Field name="nombre" component={renderField} type="text" className="form-control" maxLength={validationAdministrador.nombre.MaxLength} />
                        </div>
                    </div>
                    <div className="col-12 col-md-6 p-0">
                        <div className="col-md-12 col-sm-12">
                            <label htmlFor="telefono" className="m-0">Teléfono</label>
                        </div>
                        <div className="col-md-12  form-group">
                            <Field name="telefono"
                                component={renderNumber}
                                type="text"
                                className="form-control"
                                maxLength={validationAdministrador.telefono.MaxLength}
                            />
                        </div>
                    </div>
                    <div className="col-md-6 p-0 d-flex">
                        <div className="col-md-12 col-sm-12 align-self-center">
                            <Field name="new_password" placeholder="Nueva contraseña" component={renderPasswordField} minLength={6} className="form-control" />
                        </div>
                    </div>
                    <div className="col-12 col-md-6 p-0">
                        <div className="col-md-12 col-sm-12">
                            <label htmlFor="avatar" className="m-0">Fotografía</label>
                        </div>
                        <div className="col-md-12  form-group">
                            <Field photo={me && me.avatar ? me.avatar : null} setFile={setAvatar} onlyImagen name="avatar" component={renderFilePicker} />
                        </div>
                    </div>
                </div>
                <div className="d-flex flex-wrap justify-content-center align-items-center mt-5">
                    <div className="col-md-4 col-12">
                        <Link className="btn btn-dark btn-block" to="/">Cancelar</Link>
                    </div>
                    <div className="col-md-4 col-12 mt-md-0 mt-3">
                        <button type="submit" className="btn btn-primary btn-block">Guardar</button>
                    </div>
                </div>
            </div>
        </form>
    );
};

export default reduxForm({
    form: 'profile', // a unique identifier for this form
    validate: (data) => {
        let errors = {};
        if (data && data.new_password && data.new_password.length < 6) {
            errors.new_password = "Mínimo 6 carácteres"
        }
        if (data && data.new_password && data.new_password.length > 150) {
            errors.new_password = "Máximo 150 carácteres"
        }
        return {
            ...errors, ...validate(data, {
                nombre: validationAdministrador.nombre.Combine,
                telefono: validationAdministrador.telefono.Combine,
            })
        }
    },

})(ProfileForm);
