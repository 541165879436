import { createAction, handleActions } from 'redux-actions';
import { api } from "api";
import {initialize as initializeForm} from "redux-form";
import {NotificationManager} from "react-notifications";
import {push} from "react-router-redux";
import _ from "lodash";
import {reset} from 'redux-form';
import { USER_ADMIN } from "../../../utility/constants";

const LOADER = 'LOADER_CONTRATISTAS';
const DATA = 'DATA_CONTRATISTAS';
const ITEM_DATA = 'ITEM_CONTRATISTAS';
const PAGE = 'PAGE_CONTRATISTAS';
const ORDERING = 'ORDERING_CONTRATISTAS';
const SEARCH = 'SEARCH_CONTRATISTAS';

// -----------------------------------
// Pure Actions
// -----------------------------------

const setLoader = loader => ({
    type: LOADER,
    loader,
});

const setData = data => ({
    type: DATA,
    data,
});

const setItem = item => ({
    type: ITEM_DATA,
    item,
});

const setPage = page => ({
    type: PAGE,
    page,
});

const setOrdering = ordering => ({
    type: ORDERING,
    ordering,
});

const setSearch = search => ({
    type: SEARCH,
    search,
});

// ------------------------------------
// Actions
// ------------------------------------
const listarValidados = (page = 1) => (dispatch, getStore) => {
    const resource = getStore().contratistas;
    const params = { page };
    params.ordering = resource.ordering;
    params.search = resource.search;
    dispatch(setLoader(true));
    api.get('user/contratistas_validados', params).then((response) => {
        dispatch(setData(response));
        dispatch(setPage(page));
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const listarPendientesValidar = (page = 1) => (dispatch, getStore) => {
    // const resource = getStore().contratistas;
    const params = { page };
    // params.ordering = resource.ordering;
    // params.search = resource.search;
    dispatch(setLoader(true));
    api.get('user/contratistas_pendientes_validar', params).then((response) => {
        dispatch(setData(response));
        dispatch(setPage(page));
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const leer = id => (dispatch) => {
    dispatch(setLoader(true));
    api.get(`${'user'}/${id}`).then((response) => {
        dispatch(setItem(response));
        dispatch(initializeForm('EditarContratistaForm', response));
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const crear = data => (dispatch) => {
    dispatch(setLoader(true));
    api.post('user', data).then(() => {
        NotificationManager.success('Registro creado', 'Éxito', 3000);
        dispatch(cleanForm()); // Limpiar el formulario
        dispatch(push('/contratistas_validados'));
    }).catch(() => {
        NotificationManager.error('Error en la creación', 'ERROR');
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

const editar = (id, data = {}, attachments=[]) => (dispatch) => {
    dispatch(setLoader(true));
    api.putAttachments(`${'user'}/${id}`, data, attachments).then(() => {
        NotificationManager.success('Registro actualizado', 'Éxito', 3000);
        dispatch(push('/contratistas_validados'));
    }).catch(() => {
        NotificationManager.error('Error en la edición', 'ERROR', 3000);
    }).finally(() => {
        dispatch(setLoader(false));
    });
}  

const eliminar = id => (dispatch) => {
    dispatch(setLoader(true));
    api.eliminar(`${'user'}/${id}`).then(() => {
        dispatch(listarValidados());
        NotificationManager.success('Registro eliminado', 'Éxito', 3000);
    }).catch(() => {
        NotificationManager.error('Error en la transacción', 'ERROR', 3000);
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const cleanForm = () => (dispatch) => {
    dispatch(initializeForm('EditarContratistaForm', {}));
};

const searchChange = search => (dispatch) => {
    dispatch(setSearch(search));
    dispatch(listarValidados());
};

const onSortChange = ordering => (dispatch, getStore) => {
    const sort = getStore().contratistas.ordering;
    if (ordering === sort) {
        dispatch(setOrdering(`-${ordering}`));
    } else {
        dispatch(setOrdering(ordering));
    }
    // dispatch(listar());
};

const aprobarPendiente = id => (dispatch) => {
    dispatch(setLoader(true));
    api.put(`${'user'}/${id}/validar_contratista`).then(() => {
        dispatch(listarPendientesValidar());
        dispatch(push('/contratistas'));
        NotificationManager.success('Contratista aprobado', 'Éxito', 3000);
    }).catch(() => {
        NotificationManager.error('Error en la transacción', 'ERROR', 3000);
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

const rechazarPendiente = (id, justificacion = null) => (dispatch) => {
    const data = {}
    if (justificacion) {
        data.justificacion = justificacion;
    }
    dispatch(setLoader(true));
    api.put(`${'user'}/${id}/rechazar_contratista`, data).then(() => {
        dispatch(listarPendientesValidar());
        dispatch(push('/contratistas'));
        NotificationManager.success('Contratista rechazado y eliminado', 'Éxito', 3000);
    }).catch(() => {
        NotificationManager.error('Error en la transacción', 'ERROR', 3000);
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

export const actions = {
    leer,
    crear,
    editar,
    eliminar,
    searchChange,
    onSortChange,
    // desactivar,
    // activar,
    cleanForm,
    listarPendientesValidar,
    aprobarPendiente,
    rechazarPendiente,
    listarValidados
};

// ------------------------------------
// Reducers
// ------------------------------------

export const reducers = {
    [LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    [DATA]: (state, { data }) => {
        return {
            ...state,
            data,
        };
    },
    [ITEM_DATA]: (state, { item }) => {
        return {
            ...state,
            item,
        };
    },
    [PAGE]: (state, { page }) => {
        return {
            ...state,
            page,
        };
    },
    [ORDERING]: (state, { ordering }) => {
        return {
            ...state,
            ordering,
        };
    },
    [SEARCH]: (state, { search }) => {
        return {
            ...state,
            search,
        };
    },
    [SEARCH]: (state, { search }) => {
        return {
            ...state,
            search,
        };
    },
};

export const initialState = {
    loader: false,
    data: {
        results: [],
        count: 0,
    },
    item: {},
    page: 1,
    ordering: '',
    search: '',
};

export default handleActions(reducers, initialState);
