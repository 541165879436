import React, { Component } from 'react'
import Grid from "../../../Utils/Grid";
import Card from "Utils/Cards/Card"
import { TableHeaderColumn } from "react-bootstrap-table";
import Link from "react-router-dom/es/Link";
import LoadMask from 'Utils/LoadMask/LoadMask';
import HeaderPanelFinanciero from "Utils/Grid/HeaderPanelFinanciero";
import HeaderReportes from '../../../Utils/Grid/HeaderReportes';
import DatePicker from "react-datepicker";
import { RenderNumber, RenderDollar } from '../../../Utils/renderField/renderReadField';
import moment from 'moment';
import { INGRESO, EGRESO } from '../../../../../utility/constants';
import Chart from "react-google-charts";

export default class ListarMovimiento extends Component {
    componentWillMount() {
        this.props.getDataDashboard();
    }

    render() {
        const { filtroFechaInicio, filtroFechaFin } = this.props; //Funciones
        const { data, loader, filtro_fecha_inicio, filtro_fecha_fin } = this.props; //Constantes
        return (
            <div className="mb-4 col-12 mt-4">
                <Card titulo="HOME">
                    <HeaderReportes
                        filtro2={
                            <DatePicker
                                showPopperArrow={false}
                                dateFormat="DD/MM/YYYY"
                                placeholderText="Fecha inicial"
                                onChange={(value) => { filtroFechaInicio(value) }}
                                selected={filtro_fecha_inicio}
                            />
                        }
                        filtroText2="Fecha Inicio"
                        filtro3={
                            <DatePicker
                                showPopperArrow={false}
                                dateFormat="DD/MM/YYYY"
                                placeholderText="Fecha final"
                                onChange={(value) => { filtroFechaFin(value) }}
                                selected={filtro_fecha_fin}
                            />
                        }
                        filtroText3="Fecha Fin"
                    />
                    <LoadMask loading={loader} blur>
                        <div className="row px-0">
                            <div className="col-12 col-md-12 col-lg-8 mb-3 px-0 px-lg-3">
                                <div className="card-proyecto">
                                    <div className="d-flex justify-content-center">
                                        <span className="gris-oscuro text-center mb-1 bold mt-1">Ingresos totales vs IVA total ($)</span>
                                    </div>
                                    <div>
                                        <Chart
                                            width={'95%'}
                                            height={'100%'}
                                            chartType="ColumnChart"
                                            className="d-flex justify-content-center"
                                            loader={<div>Loading Chart</div>}
                                            data={[
                                                ['Nombre', 'Valor', { role: 'style' }],
                                                ['Ingresos totales', data.total_ingresos, 'color: #10426D'],
                                                // ['IVA Total', data.total_ingresos_iva, 'color: #D92027'],
                                                ['IVA Total suscripcion', data.total_ingresos_iva_suscripcion, 'color: #D92027'],
                                                ['IVA Total viaje sin comision', data.total_ingresos_iva_viajes_sin_comision, 'color: #D92027'],
                                                ['IVA Total comision viaje', data.total_ingresos_iva_comision_viajes, 'color: #D92027']
                                            ]}
                                            options={{
                                                legend: { position: "none" },
                                                backgroundColor: "transparent"
                                                // colors: ['#FA7D09','#7EA04D', '#10426D'],
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-md-12 col-lg-4 mb-3 px-0 px-lg-3">
                                <div className="card-proyecto">
                                    <div className="d-flex justify-content-center">
                                        <span className="gris-oscuro text-center mb-1 bold mt-1">Deuda total</span>
                                    </div>
                                    <div>
                                        <Chart
                                            width={'95%'}
                                            height={'100%'}
                                            chartType="ColumnChart"
                                            loader={<div>Loading Chart</div>}
                                            data={[
                                                ['Nombre', 'Valor', { role: 'style' }],
                                                ['Pagos pendientes a transportistas', data.total_pago_pendiente, 'color: #FA7D09'],
                                                ['Devoluciones', data.total_devoluciones, 'color: #7EA04D']
                                            ]}
                                            options={{
                                                legend: { position: "none" },
                                                backgroundColor: "transparent"
                                                // colors: ['#FA7D09','#7EA04D', '#10426D'],
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-md-12 col-lg-3 mb-3 px-0 px-lg-3">
                                <div className="card-proyecto">
                                    <div className="d-flex justify-content-center">
                                        <span className="gris-oscuro text-center mb-1 bold mt-1">Total de proyectos publicados</span>
                                    </div>
                                    <div className="d-flex justify-content-center">
                                        <Chart
                                            width={'95%'}
                                            height={'100%'}
                                            className="d-flex justify-content-center"
                                            chartType="Gauge"
                                            loader={<div>Loading Chart</div>}
                                            data={[
                                                ['Etiqueta', 'Valor'],
                                                [' ', data.total_publicados],
                                            ]}
                                            options={{
                                                redColor: "#D92027",
                                                redFrom: data.total_publicados - (data.total_publicados * 0.1), redTo: data.total_publicados,
                                                yellowColor: "#fcc42c",
                                                yellowFrom: data.total_publicados - (data.total_publicados * 0.2), yellowTo: data.total_publicados - (data.total_publicados * 0.1),
                                                max: data.total_publicados,
                                                min: 0,
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-md-12 col-lg-3 mb-3 px-0 px-lg-3">
                                <div className="card-proyecto">
                                    <div className="d-flex justify-content-center">
                                        <span className="gris-oscuro text-center mb-1 bold mt-1">Total de proyectos adjudicados</span>
                                    </div>
                                    <div className="d-flex justify-content-center">
                                        <Chart
                                            width={'95%'}
                                            height={'100%'}
                                            className="d-flex justify-content-center"
                                            chartType="Gauge"
                                            loader={<div>Loading Chart</div>}
                                            data={[
                                                ['Etiqueta', 'Valor'],
                                                [' ', data.total_adjudicaciones],
                                            ]}
                                            options={{
                                                redColor: "#D92027",
                                                redFrom: data.total_publicados - (data.total_publicados * 0.1), redTo: data.total_publicados,
                                                yellowColor: "#fcc42c",
                                                yellowFrom: data.total_publicados - (data.total_publicados * 0.2), yellowTo: data.total_publicados - (data.total_publicados * 0.1),
                                                max: data.total_publicados,
                                                min: 0,
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-md-12 col-lg-3 mb-3 px-0 px-lg-3">
                                <div className="card-proyecto">
                                    <div className="d-flex justify-content-center">
                                        <span className="gris-oscuro text-center mb-1 bold mt-1">Total de proyectos en curso</span>
                                    </div>
                                    <div className="d-flex justify-content-center">
                                        <Chart
                                            width={'95%'}
                                            height={'100%'}
                                            className="d-flex justify-content-center"
                                            chartType="Gauge"
                                            loader={<div>Loading Chart</div>}
                                            data={[
                                                ['Etiqueta', 'Valor'],
                                                [' ', data.total_en_curso],
                                            ]}
                                            options={{
                                                redColor: "#D92027",
                                                redFrom: data.total_publicados - (data.total_publicados * 0.1), redTo: data.total_publicados,
                                                yellowColor: "#fcc42c",
                                                yellowFrom: data.total_publicados - (data.total_publicados * 0.2), yellowTo: data.total_publicados - (data.total_publicados * 0.1),
                                                max: data.total_publicados,
                                                min: 0,
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-md-12 col-lg-3 mb-3 px-0 px-lg-3">
                                <div className="card-proyecto">
                                    <div className="d-flex justify-content-center">
                                        <span className="gris-oscuro text-center mb-1 bold mt-1">Total de proyectos finalizados</span>
                                    </div>
                                    <div className="d-flex justify-content-center">
                                        <Chart
                                            width={'95%'}
                                            height={'100%'}
                                            className="d-flex justify-content-center"
                                            chartType="Gauge"
                                            loader={<div>Loading Chart</div>}
                                            data={[
                                                ['Etiqueta', 'Valor'],
                                                [' ', data.total_finalizados],
                                            ]}
                                            options={{
                                                redColor: "#D92027",
                                                redFrom: data.total_publicados - (data.total_publicados * 0.1), redTo: data.total_publicados,
                                                yellowColor: "#fcc42c",
                                                yellowFrom: data.total_publicados - (data.total_publicados * 0.2), yellowTo: data.total_publicados - (data.total_publicados * 0.1),
                                                max: data.total_publicados,
                                                min: 0,
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </LoadMask>
                </Card>
            </div>
        )
    }
}
