import { createAction, handleActions } from 'redux-actions';
import { api } from "api";
import { initialize as initializeForm } from "redux-form";
import { NotificationManager } from "react-notifications";
import { push } from "react-router-redux";
import _ from "lodash";
import moment from "moment";
import { reset } from 'redux-form';
import { USER_ADMIN } from "../../../utility/constants";

const LOADER = 'LOADER_CENTRO_NOTIFICACION';
const DATA = 'DATA_CENTRO_NOTIFICACION';
const ITEM_DATA = 'ITEM_CENTRO_NOTIFICACION';
const PAGE = 'PAGE_CENTRO_NOTIFICACION';
const TOTAL_NOTIFICACIONES = 'TOTAL_NOTIFICACIONES';
const NOTIFICACIONES_NUEVAS = 'NOTIFICACIONES_NUEVAS';
const FILTRO_FECHA_INICIO = 'FILTRO_FECHA_INICIO_CENTRO_NOTIFICACION';
const FILTRO_FECHA_FIN = 'FILTRO_FECHA_FIN_CENTRO_NOTIFICACION';
const FILTRO_USUARIO = 'FILTRO_USUARIO_CENTRO_NOTIFICACION';

// -----------------------------------
// Pure Actions
// -----------------------------------

const setLoader = loader => ({
    type: LOADER,
    loader,
});

const setData = data => ({
    type: DATA,
    data,
});

const setItem = item => ({
    type: ITEM_DATA,
    item,
});

const setPage = page => ({
    type: PAGE,
    page,
});

const setNotificacionesNuevas = notificaciones_nuevas => ({
    type: NOTIFICACIONES_NUEVAS,
    notificaciones_nuevas,
});

const setTotalNotificaciones = total_notificaciones => ({
    type: TOTAL_NOTIFICACIONES,
    total_notificaciones,
});


// ------------------------------------
// Actions
// ------------------------------------

const listar = (page = 1) => (dispatch, getStore) => {
    const resource = getStore().centroNotificaciones;
    const params = { page };
    if (resource.filtro_fecha_inicio) {
        params.fecha_inicio = moment(resource.filtro_fecha_inicio).format('YYYY-MM-DD');
    }
    if (resource.filtro_fecha_fin) {
        params.fecha_fin = moment(resource.filtro_fecha_fin).format('YYYY-MM-DD');
    }
    dispatch(setLoader(true));
    api.get('notificacion', params).then((response) => {
        dispatch(setData(response));
        dispatch(setPage(page));
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};


export const comprobarNotificaciones = () => (dispatch, getStore) => {
    const resource = getStore().centroNotificaciones;
    api.get('notificacion/comprobar').then((response) => {
        if (resource.notificaciones_nuevas == 0) {
            if (response.resp > resource.total_notificaciones) {
                dispatch(setNotificacionesNuevas(response.resp - resource.total_notificaciones));
                dispatch(setTotalNotificaciones(response.resp));
            }
        } else if (response.resp > resource.total_notificaciones) {
            dispatch(setNotificacionesNuevas(response.resp - resource.total_notificaciones));
        }

    }).catch(() => {
    }).finally(() => {
    });
};




const filtroFechaInicio = filtro_fecha_inicio => (dispatch) => {
    dispatch({ type: FILTRO_FECHA_INICIO, filtro_fecha_inicio })
    dispatch(listar());
};

const filtroFechaFin = filtro_fecha_fin => (dispatch) => {
    dispatch({ type: FILTRO_FECHA_FIN, filtro_fecha_fin })
    dispatch(listar());
};

const filtroUsuario = filtro_usuario => (dispatch) => {
    dispatch({ type: FILTRO_USUARIO, filtro_usuario })
    dispatch(listar());
};

const resetNotificacionesNuevas = () => (dispatch) => {
    dispatch(setNotificacionesNuevas(0));
};


export const actions = {
    listar,
    filtroFechaInicio,
    filtroFechaFin,
    filtroUsuario,
    comprobarNotificaciones,
    resetNotificacionesNuevas,
};

// ------------------------------------
// Reducers
// ------------------------------------

export const reducers = {
    [LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    [DATA]: (state, { data }) => {
        return {
            ...state,
            data,
        };
    },
    [ITEM_DATA]: (state, { item }) => {
        return {
            ...state,
            item,
        };
    },
    [PAGE]: (state, { page }) => {
        return {
            ...state,
            page,
        };
    },
    [FILTRO_FECHA_INICIO]: (state, { filtro_fecha_inicio }) => {
        return {
            ...state,
            filtro_fecha_inicio,
        };
    },
    [FILTRO_FECHA_FIN]: (state, { filtro_fecha_fin }) => {
        return {
            ...state,
            filtro_fecha_fin,
        };
    },


    [NOTIFICACIONES_NUEVAS]: (state, { notificaciones_nuevas }) => {
        return {
            ...state,
            notificaciones_nuevas,
        };
    },

    [TOTAL_NOTIFICACIONES]: (state, { total_notificaciones }) => {
        return {
            ...state,
            total_notificaciones,
        };
    },
};

export const initialState = {
    loader: false,
    data: {
        results: [],
        count: 0,
    },
    item: {},
    page: 1,
    total_notificaciones: 0,
    notificaciones_nuevas: 0,
    filtro_fecha_inicio: moment().startOf('month'),
    filtro_fecha_fin: moment().endOf('month'),
};

export default handleActions(reducers, initialState);
