import React, { Fragment} from 'react';


function CardForm(props) {
    const {noPaddingXContent} = props;
    return (
        <Fragment>
            <div className="d-flex align-items-baseline ml-3 mb-3">
                <h3>{props.titulo}</h3>
            </div>
            <div className="mb-4 card-tabla card-small">
                <div className={'card-content-form' + (noPaddingXContent ? ' px-0' : ' px-0 px-sm-4')}>
                    <div className={`col-12 col-lg-${props.col ? props.col : '8'}`}>
                        {props.children}
                    </div>
                </div>
            </div>
        </Fragment>

    )
}

export default CardForm;
