import React, { Component, Fragment } from 'react'
import Grid from "../../../Utils/Grid";
import Card from "Utils/Cards/Card"
import Slider, { Range } from 'rc-slider';
import { TableHeaderColumn } from "react-bootstrap-table";
import { standardActions } from "../../../Utils/Grid/StandardActions";
import Link from "react-router-dom/es/Link";
import LoadMask from 'Utils/LoadMask/LoadMask';
import HeaderReportes from "Utils/Grid/HeaderReportes";
import Select, { Async } from "react-select";
import classNames from 'classnames';
import DatePicker from "react-datepicker";
import { api } from "api";
import chroma from 'chroma-js';
import { ESTADO_PROYECTO } from '../../../../../utility/constants';
import { RenderNumber, RenderDollar } from '../../../Utils/renderField/renderReadField';
// Grafica
import Chart from "react-google-charts";
import {
    BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, Cell, Label
} from 'recharts';

import 'rc-slider/assets/index.css';

const dot = (color = '#ccc') => ({
    alignItems: 'center',
    display: 'flex',
    ':before': {
        backgroundColor: color,
        borderRadius: 10,
        content: '" "',
        display: 'block',
        marginRight: 8,
        height: 10,
        width: 10,
    },
});


const getUser = (search) => {
    return api.get("user/transportistas_validados", { search }).then((data) => {
        if (data) return data.results;
        return [];
    }).catch(() => {
        return [];
    });
};

export default class OfertasYAdjudicados extends Component {
    componentWillMount() {
        this.props.infoProyectosPublicadados().then(() => {
            this.props.dataReporteProyectoTotalPagado()
            this.props.listarTransportistasTop()
            this.props.dataGraficaOfertaYAdjudicadoReporte()
        });


    }

    tamanoWith(ofertas, adjudicados) {
        if (ofertas == 0) {
            return "100%"
        }

        let porcentaje = (adjudicados * 100) / ofertas
        porcentaje = Math.round(porcentaje);
        porcentaje = `${porcentaje.toString()}%`
        return porcentaje
    }



    onChangeSlider = (value) => {
        this.props.filtroPrecioMinTransportista(value[0]);
        this.props.filtroPrecioMaxTransportista(value[1]);
    }

    filtrarXPrecio = (value) => {
        this.props.dataReporteProyectoTotalPagado()
        this.props.listarTransportistasTop()
        this.props.dataGraficaOfertaYAdjudicadoReporte()
    }

    render() {
        const { listar: onPageChange, onSortChange, eliminar } = this.props; //Funciones
        const { totales, data, data_grafica = "", loader, page, filtro_precio_max, filtro_precio_min, precio_max, precio_min } = this.props; //Constantes
        const { ofertas = 0, adjudicados = 0 } = data_grafica

        return (
            <div className="mb-4 col-12 mt-4">
                <Card titulo="REPORTE OFERTAS VS ADJUDICADOS">
                    <HeaderReportes
                        filtro1={
                            <DatePicker
                                showPopperArrow={false}
                                dateFormat="DD/MM/YYYY"
                                placeholderText="Fecha inicial"
                                onChange={(value) => { this.props.changeFechaInicio(value) }}
                                selected={this.props.filtro_fecha_inicio}
                            />
                        }
                        filtroText1="Fecha Inicio"
                        filtro2={
                            <DatePicker
                                showPopperArrow={false}
                                dateFormat="DD/MM/YYYY"
                                placeholderText="Fecha final"
                                onChange={(value) => { this.props.changeFechaFinal(value) }}
                                selected={this.props.filtro_fecha_fin}
                            />
                        }
                        filtroText2="Fecha Fin"
                        filtro3={
                            <Async
                                isClearable={true}
                                backspaceRemovesValue={false}
                                value={this.props.filtro_transportista}
                                isSearchable={true}
                                loadOptions={getUser}
                                getOptionValue={(option) => option["id"]}
                                getOptionLabel={(option) => option["nombre"]}
                                type="text"
                                onChange={(e) => this.props.changeTransportista(e)}
                                multi={false}
                                autoload={false}
                                cache={false}
                                className={classNames("react-select-container w-100")}
                                defaultOptions={true}
                                placeholder="Filtrar por transportista.."
                            />
                        }
                        filtroText3="Transportista"
                        filtro4={
                            <div className="mx-lg-1 d-flex flex-column flex-wrap align-items-end">
                                <Range
                                    min={precio_min}
                                    max={precio_max}
                                    defaultValue={[precio_min, precio_max]}
                                    tipFormatter={value => `${value}%`}
                                    allowCross={false}
                                    step="100"
                                    className="mb-1"
                                    onChange={this.onChangeSlider}
                                    onAfterChange={this.filtrarXPrecio}
                                />
                                <span className="gris-oscuro text-xsm">{`Precio: $.${filtro_precio_min} - $.${filtro_precio_max}`}</span>
                            </div>
                        }
                        filtroText4="Rango de precio"
                    />
                    <div className="col-12 p-0">
                        <div className="row p-0">
                            <div className="col-lg-6 col-12 mb-3 px-0 px-lg-3">
                                <div className="card-proyecto mb-2 py-3">
                                    <span className="px-3 gris-oscuro bold mx-3 my-2">PROYECTOS</span>
                                    <div className="d-flex h-100 mb-3 align-items-center px-3">
                                        <LoadMask loading={loader} blur>
                                            <div className="  row mx-4">
                                                <div className="flex-1 d-flex  flex-column">
                                                    <div className="col-12">
                                                        <span className="gris-oscuro bold">Ofertas</span>
                                                    </div>
                                                    <div className="bg-gris-claro d-flex justify-content-end">
                                                        <div className="w-100 bg-verde py-1">
                                                            <h2 className="ml-2">{ofertas}</h2>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="flex-1 mx-1 text-right">
                                                    <div className="col-12">
                                                        <span className="gris-oscuro bold">Adjudicados</span>
                                                    </div>
                                                    <div className="bg-gris-claro text-right">
                                                        <div className="bg-naranja  py-1" style={{ width: this.tamanoWith(ofertas, adjudicados) }}>
                                                            <h2 className="mr-2">{adjudicados}</h2>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </LoadMask>
                                    </div>
                                </div>
                                <div className="card-proyecto pb-4 py-2">
                                    <div className="col-12 my-3">
                                        <span className="gris-oscuro bold">TRANSPORTISTAS CON MAS PROYECTOS REALIZADOS</span>
                                    </div>
                                    <div className="col-12">
                                        <Grid hover striped data={data} loading={loader} pagination={false}>
                                            <TableHeaderColumn
                                                isKey
                                                dataField="oferta__transportista__nombre"
                                            >
                                                TRANSPORTISTAS
                                            </TableHeaderColumn>
                                            <TableHeaderColumn
                                                dataField="count"
                                            >
                                                PROYECTOS
                                            </TableHeaderColumn>
                                        </Grid>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-6 col-12 mb-3 px-0 px-lg-3">
                                <div className="col-12 p-0">
                                    <LoadMask loading={loader} blur>
                                        <div className="card-proyecto p-2 px-4 align-items-center flex-wrap flex-column flex-sm-row">
                                            <div className="flex-1 justify-content-center">
                                                <span className="gris-oscuro bold text-sm">TOTAL PAGO TRANSPORTISTAS</span>
                                            </div>
                                            <div className="flex-2">
                                                <RenderDollar value={totales && totales.total_pago_transportista ? totales.total_pago_transportista : 0} className="naranja text-reporte ml-lg-3 ml-0" />
                                            </div>
                                        </div>
                                    </LoadMask>
                                </div>
                                <div className="col-12 my-2 p-0">
                                    <LoadMask loading={loader} blur>
                                        <div className="card-proyecto p-2 px-4 align-items-center flex-wrap flex-column flex-sm-row">
                                            <div className="flex-1 justify-content-center">
                                                <span className="gris-oscuro bold text-sm">TOTAL FACTURADO PROYECTOS</span>
                                            </div>
                                            <div className="flex-2">
                                                <RenderDollar value={totales && totales.total_facturado_proyecto ? totales.total_facturado_proyecto : 0} className="azul text-reporte ml-lg-3 ml-0" />
                                            </div>
                                        </div>
                                    </LoadMask>
                                </div>
                            </div>
                        </div>
                        <div className="row p-0">

                        </div>
                    </div>
                </Card>
            </div >
        )
    }
}
