import { createAction, handleActions } from 'redux-actions';
import { api } from "api";
import {initialize as initializeForm} from "redux-form";
import {NotificationManager} from "react-notifications";
import {push} from "react-router-redux";
import _ from "lodash";
import moment from "moment";
import {reset} from 'redux-form';
import { USER_ADMIN } from "../../../utility/constants";

const LOADER = 'LOADER_BITACORA';
const DATA = 'DATA_BITACORA';
const ITEM_DATA = 'ITEM_BITACORA';
const PAGE = 'PAGE_BITACORA';
const ORDERING = 'ORDERING_BITACORA';
const SEARCH = 'SEARCH_BITACORA';
const FILTRO_FECHA_INICIO = 'FILTRO_FECHA_INICIO_BITACORA';
const FILTRO_FECHA_FIN = 'FILTRO_FECHA_FIN_BITACORA';
const FILTRO_USUARIO = 'FILTRO_USUARIO_BITACORA';

// -----------------------------------
// Pure Actions
// -----------------------------------

const setLoader = loader => ({
    type: LOADER,
    loader,
});

const setData = data => ({
    type: DATA,
    data,
});

const setItem = item => ({
    type: ITEM_DATA,
    item,
});

const setPage = page => ({
    type: PAGE,
    page,
});

const setOrdering = ordering => ({
    type: ORDERING,
    ordering,
});

const setSearch = search => ({
    type: SEARCH,
    search,
});

// ------------------------------------
// Actions
// ------------------------------------

const listar = (page = 1) => (dispatch, getStore) => {
    const resource = getStore().bitacora;
    const params = { page };
    if (resource.filtro_fecha_inicio) {
        params.fecha_inicio = moment(resource.filtro_fecha_inicio).format('YYYY-MM-DD');
    }
    if (resource.filtro_fecha_fin) {
        params.fecha_fin = moment(resource.filtro_fecha_fin).format('YYYY-MM-DD');
    }
    if (resource.search) {
        params.search = resource.search;
    }
    if (resource.filtro_usuario) {
        params.usuario = resource.filtro_usuario.id;
    }
    dispatch(setLoader(true));
    api.get('bitacora', params).then((response) => {
        dispatch(setData(response));
        dispatch(setPage(page));
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const leer = id => (dispatch) => {
    dispatch(setLoader(true));
    api.get(`${'bitacora'}/${id}`).then((response) => {
        dispatch(setItem(response));
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const searchChange = search => (dispatch) => {
    dispatch(setSearch(search));
    dispatch(listar());
};

const filtroFechaInicio = filtro_fecha_inicio => (dispatch) => {
    dispatch({ type: FILTRO_FECHA_INICIO, filtro_fecha_inicio })
    dispatch(listar());
};

const filtroFechaFin = filtro_fecha_fin => (dispatch) => {
    dispatch({ type: FILTRO_FECHA_FIN, filtro_fecha_fin })
    dispatch(listar());
};

const filtroUsuario = filtro_usuario => (dispatch) => {
    dispatch({ type: FILTRO_USUARIO, filtro_usuario })
    dispatch(listar());
};

export const actions = {
    listar,
    leer,
    searchChange,
    filtroFechaInicio,
    filtroFechaFin,
    filtroUsuario,
};

// ------------------------------------
// Reducers
// ------------------------------------

export const reducers = {
    [LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    [DATA]: (state, { data }) => {
        return {
            ...state,
            data,
        };
    },
    [ITEM_DATA]: (state, { item }) => {
        return {
            ...state,
            item,
        };
    },
    [PAGE]: (state, { page }) => {
        return {
            ...state,
            page,
        };
    },
    [ORDERING]: (state, { ordering }) => {
        return {
            ...state,
            ordering,
        };
    },
    [SEARCH]: (state, { search }) => {
        return {
            ...state,
            search,
        };
    },
    [SEARCH]: (state, { search }) => {
        return {
            ...state,
            search,
        };
    },
    [FILTRO_FECHA_INICIO]: (state, { filtro_fecha_inicio }) => {
        return {
            ...state,
            filtro_fecha_inicio,
        };
    },
    [FILTRO_FECHA_FIN]: (state, { filtro_fecha_fin }) => {
        return {
            ...state,
            filtro_fecha_fin,
        };
    },
    [FILTRO_USUARIO]: (state, { filtro_usuario }) => {
        return {
            ...state,
            filtro_usuario,
        };
    },
};

export const initialState = {
    loader: false,
    data: {
        results: [],
        count: 0,
    },
    item: {},
    page: 1,
    ordering: '',
    search: '',
    filtro_fecha_inicio: moment().startOf('month'),
    filtro_fecha_fin: moment().endOf('month'),
    filtro_usuario: null,
};

export default handleActions(reducers, initialState);
