import React, { Component } from 'react'
import CardForm from 'Utils/Cards/CardForm';
import LoadMask from 'Utils/LoadMask/LoadMask';
import Form from './ConfigGlobalForm';
import EditarConfigGlobalForm from './ConfigGlobalForm';

export default class Crear extends Component {
    componentWillMount() {
        this.props.leer();
    };

  render() {
    const { crear,loader } = this.props;
    return (
        <div className="mb-4 col-12 mt-4">
          <CardForm
              titulo="CONFIGURACIÓN GLOBAL">
              <LoadMask loading={loader} blur>
                <Form onSubmit={crear}/>
              </LoadMask>
          </CardForm>
      </div>
    )
  }
}
