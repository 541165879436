import React, {Component} from 'react'
import Grid from "../../../Utils/Grid";
import {TableHeaderColumn} from "react-bootstrap-table";
import {standardActions} from "../../../Utils/Grid/StandardActions";
import Link from "react-router-dom/es/Link";
import Card from "Utils/Cards/Card"
import Header from "Utils/Grid/Header";
import { tipo_vehiculo } from '../../../../../utility/constants';

export default class List extends Component{
    componentWillMount() {
        const { listar, page } = this.props;
        listar(page);
    }

    render() {
        const { listar: onPageChange, onSortChange, eliminar } = this.props;
        const { data, loader, page } = this.props;
        return(
            <div className="mb-4 col-12 mt-4">
                <Card
                    titulo="RANGOS DE PRECIOS">
                    <Header to="rango_precio/crear" textBtn="Crear rango" {...this.props} />
                    <Grid hover striped data={data} loading={loader} onPageChange={onPageChange}
                          onSortChange={onSortChange} page={page} >
                        <TableHeaderColumn
                            dataField="id"
                            dataAlign="center"
                            dataSort
                            width='20%'
                            dataFormat={standardActions({ editar: "rango_precio", eliminar})}
                        />
                        <TableHeaderColumn
                            dataAlign="left"
                            dataField="pais_origen"
                            width='20%'
                            dataSort
                            dataFormat={(cell) => cell && cell.nombre ? cell.nombre : ""}

                        >
                            PAÍS ORIGEN
                        </TableHeaderColumn>
                        <TableHeaderColumn                            
                            dataField="ciudad_origen"
                            dataSort
                            width='20%'
                            dataFormat={(cell) => cell && cell.nombre ? cell.nombre : ""}

                        >
                            CIUDAD ORIGEN
                        </TableHeaderColumn>

                        <TableHeaderColumn
                            dataAlign="left"
                            dataField="pais_destino"
                            width='20%'
                            dataSort
                            dataFormat={(cell) => cell && cell.nombre ? cell.nombre : ""}

                        >
                            PAÍS DESTINO
                        </TableHeaderColumn>
                        <TableHeaderColumn                            
                            dataField="ciudad_destino"
                            dataSort
                            width='20%'
                            dataFormat={(cell) => cell && cell.nombre ? cell.nombre : ""}

                        >
                            CIUDAD DESTINO
                        </TableHeaderColumn>
                        <TableHeaderColumn                            
                            dataField="vehiculo"
                            dataSort
                            width='20%'
                            dataFormat={(cell) => cell && cell.nombre ? cell.nombre : ""}

                        >
                            VEHICULO
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataAlign="left"
                            dataField="id"
                            width='10%'
                            dataSort
                            isKey
                        >
                            CÓDIGO
                        </TableHeaderColumn>
                                                
                    </Grid>
                </Card>
            </div>
        )
    }
}
