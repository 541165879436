import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { NotificationContainer } from 'react-notifications';

import { Login, Profile, Registro } from './common/components/LoginRegister';
import Demo from './common/components/Demo/Demo';
import ProtectedRoute from './ProtectedRoute';
import Examples from './common/components/Examples/Basic';
import NotFound from './common/components/layout/NotFound/NotFound';
import Loader from './common/components/layout/Loader/Loader';
import sendRecoverPassword from './common/components/LoginRegister/RecoverPassword/SendRecovery';
import PassRecover from './common/components/LoginRegister/ChangePassword/';

import '../assets/fonts/fonts.css';

require('../../node_modules/font-awesome/css/font-awesome.css');
require('../../node_modules/bootstrap/dist/css/bootstrap.css');
import 'bootstrap/dist/css/bootstrap.min.css';
import Grids from './common/components/Examples/Grids';
import Notificaciones from './common/components/Examples/Notificaciones';
import ExampleTabs from './common/components/Examples/Tabs/Tabs';
require('../style/index.css');

try { require('../assets/sw') } catch (e) {
    console.error('sw ', e)
}

try {require('../assets/firebase-messaging-sw')}catch (e) {
    console.error('Firebase messaging', e)
}

// ADMINISTRADORES
import { AdminsList, CrearAdmin } from './common/components/App/Administradores';
// CONTRATISTAS
import { ContratistaPendientesValidarList, ContratistaVerContratista } from './common/components/App/Contratistas';
// CONTRATISTAS VALIDADOS
import { ContratistaListadoValidados, ContratistaEditarValidados } from './common/components/App/Contratistas';
// TRANSPORTISTAS
import { TransportistasPendientesValidarList, TransportistaVerPendiente } from './common/components/App/Transportistas';
// TRANSPORTISTAS VALIDADOS
import { TransportistasListadoValidados,  TransportistasEditarValidados,TransportistaVerValidado } from './common/components/App/Transportistas';
// PANEL FINANCIERO TRANSPORTISTA
import { PanelFinancieroListarMovimiento, PanelFinancieroRealizarRetiro} from "./common/components/App/PanelFinanciero"
//Tipos de Vehiculo
import {TiposVehiculoCrear, TiposVehiculoListar} from "./common/components/App/TipoVehiculo";
//Creacion de PAISES y CIUDADES
import {PaisCrear, PaisListar} from "./common/components/App/Paises";
//configuraciones Globales
import {ConfigGlobal} from "./common/components/App/ConfigGlobal";
//Configuracion de rangos de precio por pais, ciudad > origin y destino,
import {RangoPrecioCrear, RangoPrecioListar} from "./common/components/App/RangoPrecio";
// PROYECTOS
import { DetalleProyecto, DetalleOferta } from "./common/components/App/Proyectos";
// BITACORA
import { BitacoraListar, BitacoraVer } from "./common/components/App/Bitacora";
// REPORTES
import {ReporteProyectosYOfertas, ReporteOfertasYAdjudicados, ReporteFinanciero} from "./common/components/App/Reportes";
// PUBLICIDADES
import { PublicidadesPendientesValidarList, PublicidadVer, PublicidadesValidadosList } from './common/components/App/Publicidades';
// DEVOLUCIONES
import {DevolucionesListar, DevolucionVer} from "./common/components/App/Devoluciones";
// CENTRO NOTIFICACIONES
import { CentroNotificacionesListar } from "./common/components/App/CentroNotificaciones";
// DASHBOARD
import { Dashboard } from "./common/components/App/Dashboard";

module.exports = (
    <div>
        <div className="container__content">
            <Switch>
                <Route exact path="/login" component={Login} />
                <Route exact path="/registro" component={Registro} />
                <Route exact path="/recuperar_pass" component={sendRecoverPassword}/>
                <Route exact path="/cambiar_pass/:token" component={PassRecover}/>
                <ProtectedRoute exact path="/" component={Dashboard} />
                <ProtectedRoute exact path="/loader" component={Loader} />
                <ProtectedRoute exact path="/page2" component={Examples} />
                <ProtectedRoute exact path="/user-profile" component={Profile} />
                <ProtectedRoute exact path="/grids" component={Grids} />
                <ProtectedRoute exact path="/notifications" component={Notificaciones} />
                <ProtectedRoute exact path="/tabs" component={ExampleTabs} />
                {/* ADMINISTRADORES */}
                <ProtectedRoute exact path="/administradores" component={AdminsList} />
                <ProtectedRoute exact path="/administrador/crear" component={CrearAdmin} />
                <ProtectedRoute exact path="/administrador/:id/editar" component={CrearAdmin} />

                {/* CONTRATISTAS */}
                <ProtectedRoute exact path="/contratistas" component={ContratistaPendientesValidarList} />
                <ProtectedRoute exact path="/contratista/:id/" component={ContratistaVerContratista}/>
                <ProtectedRoute exact path="/contratistas_validados" component={ContratistaListadoValidados}/>
                <ProtectedRoute exact path="/contratista/:id/editar" component={ContratistaEditarValidados}/>

                {/* TRANSPORTISTAS */}
                <ProtectedRoute exact path="/transportistas" component={TransportistasPendientesValidarList} />
                <ProtectedRoute exact path="/transportista/:id/" component={TransportistaVerPendiente}/>
                <ProtectedRoute exact path="/transportistas_validados" component={TransportistasListadoValidados}/>
                <ProtectedRoute exact path="/transportista_validado/:id/editar" component={TransportistasEditarValidados}/>
                <ProtectedRoute exact path="/transportista_validado/:id/" component={TransportistaVerValidado}/>



                {/* Tipos de Vehiculo */}
                <ProtectedRoute exact path="/tipos_vehiculo" component={TiposVehiculoListar}/>
                <ProtectedRoute exact path="/tipo_vehiculo/crear" component={TiposVehiculoCrear}/>
                <ProtectedRoute exact path="/tipo_vehiculo/:id/editar" component={TiposVehiculoCrear}/>

                {/* Paises */}
                <ProtectedRoute exact path="/paises" component={PaisListar}/>
                <ProtectedRoute exact path="/pais/crear" component={PaisCrear}/>
                <ProtectedRoute exact path="/pais/:id/editar" component={PaisCrear}/>

                {/* Configuracion Global*/}
                <ProtectedRoute exact path="/config_global" component={ConfigGlobal}/>

                {/* Rango de Precio*/}
                <ProtectedRoute exact path="/rangos_precios" component={RangoPrecioListar}/>
                <ProtectedRoute exact path="/rango_precio/crear" component={RangoPrecioCrear}/>
                <ProtectedRoute exact path="/rango_precio/:id/editar" component={RangoPrecioCrear}/>

                {/* BITACORA */}
                <ProtectedRoute exact path="/bitacora" component={BitacoraListar}/>
                <ProtectedRoute exact path="/bitacora/:id" component={BitacoraVer}/>

                {/* BITACORA */}
                <ProtectedRoute exact path="/centro_notificaciones" component={CentroNotificacionesListar}/>

                {/* REPORTES */}
                <ProtectedRoute exact path="/reportes/proyecto-ofertas" component={ReporteProyectosYOfertas}/>
                <ProtectedRoute exact path="/reportes/ofertas-adjudicados" component={ReporteOfertasYAdjudicados}/>
                <ProtectedRoute exact path="/reportes/financiero" component={ReporteFinanciero}/>
                <ProtectedRoute exact path="/reportes/proyecto/:id/" component={DetalleProyecto}/>
                <ProtectedRoute exact path="/reportes/proyecto/:idProyecto/:idOferta/" component={DetalleOferta}/>

                {/* PUBLICIDADES */}
                <ProtectedRoute exact path="/publicidades" component={PublicidadesPendientesValidarList} />
                <ProtectedRoute exact path="/publicidad/:id/" component={PublicidadVer}/>
                <ProtectedRoute exact path="/publicidades_validados" component={PublicidadesValidadosList} />
                <ProtectedRoute exact path="/publicidad_validados/:id/" component={PublicidadVer}/>

                {/* PANEL FINANCIERO - TRANSPORTISTAS*/}
                <ProtectedRoute exact path="/panel-financiero-transportista/:id/" component={PanelFinancieroListarMovimiento} />
                <ProtectedRoute exact path="/panel-financiero-transportista-retiro/:tipo/" component={PanelFinancieroRealizarRetiro} />

                {/* DEVOLUCIONES */}
                <ProtectedRoute exact path="/devoluciones" component={DevolucionesListar} />
                <ProtectedRoute exact path="/devolucion/:id/" component={DevolucionVer} />

                <Route component={NotFound} />
            </Switch>
        </div>
        <NotificationContainer />
    </div>
);
