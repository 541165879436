import React, { Fragment } from 'react';
import { Field, reduxForm } from 'redux-form';
import { validate, validators, combine } from 'validate-redux-form';
import { renderField, renderNumber } from '../../Utils/renderField';
import { Link } from 'react-router-dom';
const alerta_img = require('assets/img/icons/alert.svg');
import { validationContratista } from '../../../../utility/validationForm'

const ChangeForm = (props) => {
    const { handleSubmit, me, reset_pass } = props;

    return (
        <form name="loginForm" className="form-validate d-flex flex-sm-row align-items-center" onSubmit={handleSubmit}>
            {reset_pass ? (
                <div className="col-12">
                    <div className="d-flex justify-content-center flex-column align-items-center mb-3">
                        <img src={alerta_img} alt="Alerta" width={50} className="img-fluid  blink-danger" />
                        <span className="gris-oscuro bold mt-1">Cambio de contraseña</span>
                    </div>
                    <div className="d-flex flex-wrap justify-content-center align-items-center">
                        <div className="col-md-11 col-12 px-md-3 px-0">
                            <div className="col-md-12 col-sm-12 text-center">
                                <label className="pl-0" htmlFor="password">Nueva contraseña</label>
                            </div>
                            <div className="col-md-12">
                                <Field name="password" component={renderField} type="password" className="form-control" maxLength="151"/>
                            </div>
                            <div className="col-md-12 col-sm-12 text-center mt-3">
                                <label className="pl-0" htmlFor="password_confirmation">Confirmar contraseña</label>
                            </div>
                            <div className="col-md-12 mb-4">
                                <Field name="password_confirmation" component={renderField} type="password" className="form-control" maxLength="151"/>
                            </div>
                        </div>
                    </div>
                    <div className="d-flex flex-wrap justify-content-center align-items-center mt-3">
                        <div className="col-md-5 col-12">
                            <Link className="btn btn-dark btn-block" to="/login">Cerrar</Link>
                        </div>
                        <div className="col-md-5 col-12 mt-md-0 mt-3 login">
                            <button type="submit" className="btn btn-primary btn-block">Recuperar</button>
                        </div>
                    </div>
                </div>
            ) : (
                    <div className="col-12 d-flex flex-column justify-content-center">
                        <div className="row justify-content-center ">
                            <div className="col-xl-5 col-lg-5 col-md-6 col-sm-8 col-9 mb-3 text-center">
                                <img src={require('assets/img/logo_completo.svg')} className="img-fluid" alt="Logo" />
                            </div>

                            <div className="col-12 ">
                                <h5 className="gris-oscuro text-center bold" >PRIMER INGRESO</h5>
                            </div>
                        </div>

                        <div className="row justify-content-center my-5 ">
                            <div className="col-10 col-md-5">
                                <div className="col-md-12 col-sm-12">
                                    <h6 className="m-0 gris-oscuro  bold ">Nombre </h6>
                                </div>
                                <div className="col-md-12 col-sm-12">
                                    <h6 className="m-0 azul bold ">{me && me.nombre ? me.nombre : "--"} </h6>
                                </div>
                            </div>
                            <div className="col-10 col-md-5">
                                <div className="col-md-12 col-sm-12">
                                    <h6 className="m-0 gris-oscuro  bold ">E-mail </h6>
                                </div>
                                <div className="col-md-12 col-sm-12">
                                    <h6 className="m-0 azul bold ">{me && me.email ? me.email : "--"} </h6>
                                </div>
                            </div>
                        </div>

                        <div className="row justify-content-center ">
                            <div className="col-xl-8 col-lg-9 col-md-10 col-sm-10 col-12 d-flex flex-column">
                                <div className="d-flex flex-wrap justify-content-center align-items-center">
                                    <div className="col-md-11 col-12 px-md-3 px-0">
                                        <div className="col-md-12 col-sm-12 text-center">
                                            <label className="pl-0" htmlFor="telefono">Teléfono</label>
                                        </div>
                                        <div className="col-md-12">
                                            <Field name="telefono" isNumericString={true} component={renderNumber} type="text" className="form-control" maxLength={validationContratista.telefono.MaxLength} />
                                        </div>

                                        <div className="col-md-12 col-sm-12 text-center mt-3">
                                            <label className="pl-0" htmlFor="password">Nueva contraseña</label>
                                        </div>
                                        <div className="col-md-12">
                                            <Field name="password" component={renderField} type="password" className="form-control" maxLength="151" />
                                        </div>

                                        <div className="col-md-12 col-sm-12 text-center mt-3">
                                            <label className="pl-0" htmlFor="password_confirmation">Confirmar contraseña</label>
                                        </div>
                                        <div className="col-md-12 mb-4">
                                            <Field name="password_confirmation" component={renderField} type="password" className="form-control" maxLength="151" />
                                        </div>
                                    </div>
                                </div>
                                <div className="d-flex flex-wrap justify-content-center align-items-center my-3">
                                    <div className="col-md-5 col-12">
                                        <Link className="btn btn-dark btn-block" to="/login">Cerrar</Link>
                                    </div>
                                    <div className="col-md-5 col-12 mt-md-0 mt-3 login">
                                        <button type="submit" className="btn btn-primary btn-block">Crear</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
        </form>
    );
};


export default reduxForm({
    form: 'login', // a unique identifier for this form
    validate: (data) => {
        return validate(data, {
            password: combine(
                validators.exists()('Campo requerido.'),
                validators.length({ min: 6 })('Mínimo 6 carácteres'),
                validators.length({ max: 150 })('Máximo 150 carácteres'),
            ),
            telefono: validationContratista.telefono.Combine,
            password_confirmation: combine(
                validators.exists()('Campo requerido.'),
                validators.equals(data.password)('La contraseña no coincide')
            ),
        });
    },
})(ChangeForm);
