import React, { Component } from 'react'
import Grid from "../../../Utils/Grid";
import Card from "Utils/Cards/Card"
import { TableHeaderColumn } from "react-bootstrap-table";
import { standardActions } from "../../../Utils/Grid/StandardActions";
import Link from "react-router-dom/es/Link";
import HeaderReportes from "Utils/Grid/HeaderReportes";
import classNames from 'classnames';
import DatePicker from "react-datepicker";
import moment from 'moment';
import Select, { Async } from "react-select";
import { api } from "api";

export default class List extends Component {
    componentWillMount() {
        const { listar, page } = this.props;
        listar(page);
    }

    render() {
        const { listar: onPageChange, onSortChange } = this.props; //Funciones
        const { data, loader, page } = this.props; //Constantes
        return (
            <div className="mb-4 col-12 mt-4">
                <Card titulo="CENTRO DE NOTIFICACIONES">
                    <HeaderReportes
                        filtro1={
                            <DatePicker
                                showPopperArrow={false}
                                dateFormat="DD/MM/YYYY"
                                placeholderText="Fecha inicial"
                                onChange={(value) => { this.props.filtroFechaInicio(value) }}
                                selected={this.props.filtro_fecha_inicio}
                            />
                        }
                        filtroText1="Fecha Inicio"
                        filtro2={
                            <DatePicker
                                showPopperArrow={false}
                                dateFormat="DD/MM/YYYY"
                                placeholderText="Fecha final"
                                onChange={(value) => { this.props.filtroFechaFin(value) }}
                                selected={this.props.filtro_fecha_fin}
                            />
                        }
                        filtroText2="Fecha Fin"
                    />
                    <Grid trClassName="table-wrap" hover striped data={data} loading={loader} onPageChange={onPageChange} onSortChange={onSortChange}
                        page={page}>
                        <TableHeaderColumn
                            dataField="link"
                            dataAlign="center"
                            isKey
                            width='10%'
                            dataFormat={standardActions({ verLink: true })}
                        >
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="creado"
                            width='18%'
                            dataFormat={(cell, row) => moment(cell).format("DD/MM/YYYY hh:mm A")}
                        >
                            FECHA
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="mensaje"
                            width='72%'
                        >
                            MENSAJE
                        </TableHeaderColumn>
                    </Grid>
                </Card>
            </div>
        )
    }
}
