import React, { Component } from 'react';
import { Route, Redirect, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import NotificationSystem from 'react-notification-system';
import { logOut, getMe, newToken } from "./redux/modules/cuenta/login";
import { messaging } from "./utility/init-fcm";
import { comprobarNotificaciones } from "./redux/modules/centroNotificaciones";

// maquetado base
import SiderBar from './common/components/layout/Sidebar/SideBar';
import Footer from './common/components/layout/Footer/Footer';

import ChangePassword from './common/components/LoginRegister/ChangePassword';

import Navbar from './common/components/layout/Navbar/Navbar';
import { VerifyLogin } from './common/components/layout';

const info = require('../assets/img/icons/check.svg');

class PrivateRouteBase extends Component {
    notificationSystem = React.createRef();

    constructor(props) {
        super(props);

        this.state = {
            toggleOpen: true,
        };
    }

    componentDidMount() {
        // FCM
        if(messaging){
            messaging.requestPermission()
            .then(() => {
                this.pushToken(messaging);
            })
            .catch(function (err) {
                console.log("Error al obtener el token.", err);
            });
            navigator.serviceWorker.addEventListener("message", (message) => {
                this.handleNotificacion(message);
            });
        }
        
    }

    pushToken = async (messaging) => {
        const token = await messaging.getToken();
        this.props.newToken(token);
    };

    clickAction (link) {
        link = link.split('/#').pop()
        this.props.history.push(`/loader`);
        setTimeout(() => {
        this.props.history.push(`${link}`)
        });
    }

    handleNotificacion = (message) => {
        // console.log('message ', message);
        const { data: { 'firebase-messaging-msg-data': { notification } } } = message;
        // event.preventDefault();
        const notificacion = this.notificationSystem.current;
        notificacion.addNotification({
            children: (
                <div
                    className="h-100 w-100 d-flex"
                    onClick={() => notification.click_action ? this.clickAction(notification.click_action): null}
                >
                    <div className="notification-icono pr-2">
                        <img src={info} className="push-icono" />
                    </div>
                    <div className="h-100 noti-min-heigth notification-body">
                        <p className="title mb-2">{notification.title}</p>
                        <p className='m-0'>{notification.body}</p>
                    </div>
                </div>
            ),
            level: 'info',
            position: 'tr',
            dismissible: true,
            autoDismiss: 0,
        });
        this.props.comprobarNotificaciones()
    };

    navToggle = () => {
        this.setState({ toggleOpen: !this.state.toggleOpen });
    };

    isAuthenticated = () => {
        const token = localStorage.getItem('token');
        const {
            getMe,
            login: { me },
        } = this.props;
        if (!!token && !!me.username) {
            return true;
        } else if (token) {
            getMe();
            return 'Verifying';
        }
        return false;
    };

    render() {
        const {
            component: Component,
            logOut,
            login: { me },
            ...rest
        } = this.props;
        const isAuthenticated = this.isAuthenticated();
        if (me.reset_pass) {
            return (
                <ChangePassword />
            )
        }
        return (
            <Route
                {...rest}
                render={(props) =>
                    isAuthenticated ? (
                        isAuthenticated === true ? (
                            <div>
                                <SiderBar
                                    toggleOpen={this.state.toggleOpen}
                                    navToggle={this.navToggle}
                                    logOut={logOut}
                                />
                                <NotificationSystem ref={this.notificationSystem} />
                                <main className="main-content p-0 col-sm-12 col-md-9 offset-md-3 col-lg-10 offset-lg-2">
                                    <div className="main-navbar bg-naranja sticky-top">
                                        <div className="pr-4">
                                            <Navbar
                                                navToggle={this.navToggle}
                                                logOut={logOut}
                                                user={me}
                                            />
                                        </div>
                                    </div>
                                    <div className="main-content-container px-1 px-sm-4 container-fluid">
                                        <Component {...props} />
                                    </div>
                                </main>
                            </div>
                        ) : (
                                <VerifyLogin />
                            )
                    ) : (
                            <Redirect
                                to={{
                                    pathname: '/login',
                                    state: { from: props.location },
                                }}
                            />
                        )
                }
            />
        );
    }
}

const mstp = (state) => ({ ...state });

const mdtp = { logOut, getMe, newToken, comprobarNotificaciones };

const ProtectedRoute = connect(
    mstp,
    mdtp
)(withRouter(PrivateRouteBase));

export default ProtectedRoute;
