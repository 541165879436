import React, { Component, Fragment } from 'react'
import LoadMask from 'Utils/LoadMask/LoadMask';
import { DEVOLUCION_PENDIENTE, DEVOLUCION_REALIZADA } from '../../../../../utility/constants'
import moment from 'moment';
import { standardActions } from "../../../Utils/Grid/StandardActions";
import Grid from "../../../Utils/Grid";

import Header from "Utils/Grid/Header";
import { RenderDollar } from '../../../Utils/renderField/renderReadField';

export default class ListadoDevoluciones extends Component {
    componentWillMount() {
        this.props.listarDevoluciones();
    }

    render() {
        const { data, loader, estado_devolucion, page, listarDevoluciones } = this.props;

        return (
            <Fragment>
                {data && (
                    estado_devolucion == DEVOLUCION_PENDIENTE ||
                    estado_devolucion == DEVOLUCION_REALIZADA)
                    &&
                    <LoadMask loading={loader} blur>

                        <Header searchEnd={true} {...this.props} />
                        <Grid hover striped data={data} loading={loader} onPageChange={listarDevoluciones}
                            page={page}>
                            <TableHeaderColumn
                                dataField="id"
                                dataAlign="center"
                                isKey
                                width='15%'
                                dataFormat={standardActions({ ver: "devolucion" })}
                            />

                            <TableHeaderColumn
                                dataField="creado"
                                dataFormat={(cell) => {
                                    return moment(cell).format('DD-MM-YYYY HH:mm')
                                }}
                            >
                                FECHA
                            </TableHeaderColumn>

                            <TableHeaderColumn
                                dataField="beneficiario"
                                dataFormat={(cell) => {
                                    return cell && cell.nombre ? cell.nombre : ""
                                }}
                            >
                                CONTRATISTA
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="razon"
                            >
                                RAZON
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="monto_a_devolver"
                                dataFormat={(cell) => {
                                    return <RenderDollar value={cell} />
                                }}
                            >
                                MONTO
                            </TableHeaderColumn>

                            <TableHeaderColumn
                                dataAlign="left"
                                dataField="id"
                                width='10%'
                            >
                                CÓDIGO
                            </TableHeaderColumn>
                        </Grid>
                    </LoadMask>

                }
            </Fragment>
        )
    }
}
