import React, { Component } from 'react'
import CardForm from 'Utils/Cards/CardForm';
import LoadMask from 'Utils/LoadMask/LoadMask';
import Form from './RangoPrecio';
import EditarTipoVehiculoForm from './RangoPrecio';

export default class Crear extends Component {
  state = {
      editar: false,
  };
  componentWillMount(){
      if(this.props.match.params.id){
          this.props.leer(this.props.match.params.id);
          this.setState({editar: true});
      }
  }
    estructurarDatos= (data)=>{
        let datos = {...data}
        datos.ciudad_destino = datos.ciudad_destino.id
        datos.ciudad_origen = datos.ciudad_origen.id
        datos.pais_destino = datos.pais_destino.id
        datos.pais_origen = datos.pais_origen.id
        datos.vehiculo = datos.vehiculo.id

        return datos
    }
    actualizar = (data) =>{
        this.props.editar(this.props.match.params.id, this.estructurarDatos(data))
    };

    crearDato = (data) =>{
            this.props.crear(this.estructurarDatos(data))
    };
  render() {
    const { clearSelectPais} = this.props;
    const { loader } = this.props;
    return (
        <div className="mb-4 col-12 mt-4">
          <CardForm
              titulo={this.state.editar? "EDITAR RANGO DE PRECIO" : "NUEVO RANGO DE PRECIO"}>
              <LoadMask loading={loader} blur>
                  {
                      this.state.editar ?
                          <EditarTipoVehiculoForm
                              onSubmit={this.actualizar}
                              editando={true}
                              clearSelectPais={clearSelectPais}
                              />
                          :
                          <Form
                          onSubmit={this.crearDato}
                          clearSelectPais={clearSelectPais}
                          />
                  }
              </LoadMask>
          </CardForm>
      </div>
    )
  }
}
