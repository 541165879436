import { connect } from 'react-redux';
import { actions } from '../../../../../redux/modules/panelFinanciero';
import RealizarRetiro from './RealizarRetiroCrear';

const mstp = state => {
    return {
        ...state.panelFinanciero,
    }
};

const md2p = { ...actions };

export default connect(mstp, md2p)(RealizarRetiro);

