import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import LoginForm from './LoginForm';
import './login.css';
import LoadMask from 'Utils/LoadMask/LoadMask';

class Login extends Component {
    static propTypes = {
        onSubmit: PropTypes.func.isRequired,
    };

    componentDidMount(props) {
        this.state = { prueba: true };
    }

    render() {
        const { onSubmit, loader } = this.props;
        if (localStorage.getItem('token')) {
            return <Redirect to="/" />;
        }
        return (
            <div className="blue-gradient-bg">
                <div className="contenedor-principal">
                    <div className="col-12 h-100 d-flex justify-content-center align-items-center flex-wrap">
                        <div className="col-12 login-wrapper d-flex flex-column">
                            <div className="col-xl-4 col-lg-6 col-md-6 col-11 mb-3 text-center">
                                <img src={require('assets/img/logo_completo.svg')} className="img-fluid" alt="Logo"/>
                            </div>
                            <div className="card card-login col-xl-4 col-lg-6 col-md-6 col-11 mb-5">
                                <LoadMask loading={loader} light>
                                    <LoginForm onSubmit={onSubmit} />
                                </LoadMask>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Login;
