import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { validate, validators } from 'validate-redux-form';
import { renderField } from '../../Utils/renderField';
import { Link, Redirect } from 'react-router-dom';

const LoginForm = (props) => {
    const { handleSubmit, pristine, reset, submitting } = props;
    return (
        <form name="loginForm" className="form-validate d-flex flex-sm-row align-items-center" onSubmit={handleSubmit}>
            <div className="col-12">
                <div className="d-flex flex-wrap justify-content-center align-items-center">
                    <div className="col-md-11 col-12 px-md-3 px-0">
                        <div className="col-md-12 col-sm-12 text-center">
                            <label className="bold pl-0" htmlFor="username">USUARIO</label>
                        </div>
                        <div className="col-md-12 form-group">
                            <Field
                                name="username"
                                label="Usuario"
                                component={renderField}
                                type="text"
                                className="form-control"
                                maxLength="30"
                            />
                        </div>
                    </div>
                </div>
                <div className="d-flex flex-wrap justify-content-center align-items-center">
                    <div className="col-md-11 col-12 px-md-3 px-0">
                        <div className="col-md-12 col-sm-12 text-center">
                            <label className="bold pl-0" htmlFor="password">CONTRASEÑA</label>
                        </div>
                        <div className="col-md-12 form-group">
                            <Field
                                name="password"
                                label="Contraseña"
                                component={renderField}
                                type="password"
                                className="form-control"
                                maxLength="128"
                            />
                        </div>
                    </div>
                </div>
                <div className="d-flex flex-wrap justify-content-center align-items-center">
                    <div className="col-md-10 col-12 mb-3 login">
                        <button type="submit" className="btn btn-primary btn-block">INICIAR SESIÓN</button>
                    </div>
                </div>
                <div className="text-center d-flex flex-column">
                    <span className="text-center">¿Olvidaste tu contraseña?</span>
                    <Link to="/recuperar_pass" className="naranja">Recuperar</Link>
                </div>
            </div>
        </form>
    );
};

export default reduxForm({
    form: 'login', // a unique identifier for this form
    validate: (data) => {
        return validate(data, {
            username: validators.exists()('Campo requerido.'),
            password: validators.exists()('Campo requerido.'),
        });
    },
})(LoginForm);
