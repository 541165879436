import React, { Component } from 'react'
import CardForm from 'Utils/Cards/CardForm';
import LoadMask from 'Utils/LoadMask/LoadMask';
import Form from './RealizarRetiroForm';
import { Link, Redirect } from 'react-router-dom';

export default class RealizarRetiro extends Component {
    constructor(props) {
        super(props);
        this.state = { tipoRetiro: "salir" };
    }

    componentWillMount() {
        const tipo = this.props.match.params.tipo

        if (tipo == "parcial" || tipo == "puntos") {
            this.props.datoMiCuenta()
            this.setState({ tipoRetiro: tipo });
        }
    }

    render() {
        const { realizarRetiroMonetario } = this.props;
        const { totales: { saldo_total = 0, puntos_total_dinero = 0, puntos_total = 0 } = "", loader, id_transportista } = this.props;
        if (id_transportista == 0 || this.state.tipoRetiro == "salir") {
            return <Redirect to="/transportistas_validados" />
        }

        return (

            <div className="mb-4 col-12 mt-4">
                <CardForm
                    titulo={`HACER RETIRO ${this.state.tipoRetiro == "parcial" ? "PARCIAL" : "TOTAL PUNTOS"}`}>
                    <LoadMask loading={loader} blur>
                        <Form onSubmit={realizarRetiroMonetario}
                            tipoRetiro={this.state.tipoRetiro}

                            saldoDisponible={saldo_total}
                            totalPuntosDinero={puntos_total_dinero}
                            totalPuntos={puntos_total}

                            initialValues={{ monto_saldo: saldo_total, tipo_retiro: this.state.tipoRetiro, transportista: id_transportista }}
                            idTransportista={id_transportista}
                        />
                    </LoadMask>
                </CardForm>
            </div>
        )
    }
}
