import React, { Component, Fragment } from 'react'
import Grid from "../../../Utils/Grid";
import Card from "Utils/Cards/Card"
import { TableHeaderColumn } from "react-bootstrap-table";
import { standardActions } from "../../../Utils/Grid/StandardActions";
import Link from "react-router-dom/es/Link";
import LoadMask from 'Utils/LoadMask/LoadMask';
import HeaderReportes from "Utils/Grid/HeaderReportes";
import Select, { Async } from "react-select";
import classNames from 'classnames';
import DatePicker from "react-datepicker";
import { api } from "api";
import chroma from 'chroma-js';
import { ESTADO_PROYECTO } from '../../../../../utility/constants';
import { RenderNumber } from '../../../Utils/renderField/renderReadField';
// Grafica
import Chart from "react-google-charts";
import {
    BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, Cell, Label
} from 'recharts';

export default class ProyectosYOfertas extends Component {
    componentWillMount() {
        this.props.dataGraficaProyectosReporte()
    }

    render() {
        const { data_grafica, loader } = this.props; //Constantes
        const {
            ingresos = [],
            gastos = [],
        } = data_grafica ? data_grafica : {}

        return (
            <div className="mb-4 col-12 mt-4">
                <Card titulo="REPORTE FINANCIERO">
                    <HeaderReportes
                        filtro2={
                            <DatePicker
                                showPopperArrow={false}
                                dateFormat="DD/MM/YYYY"
                                placeholderText="Fecha inicial"
                                onChange={(value) => { this.props.changeFechaInicio(value) }}
                                selected={this.props.filtro_fecha_inicio}
                            />
                        }
                        filtroText2="Fecha Inicio"
                        filtro3={
                            <DatePicker
                                showPopperArrow={false}
                                dateFormat="DD/MM/YYYY"
                                placeholderText="Fecha final"
                                onChange={(value) => { this.props.changeFechaFinal(value) }}
                                selected={this.props.filtro_fecha_fin}
                            />
                        }
                        filtroText3="Fecha Fin"
                    />
                    <div className="col-12 p-0">
                        <div className="row p-0">
                            <div className="col-lg-6 col-12 mb-3 px-0 px-lg-3">
                                <div className="card-proyecto h-100">
                                    <span className="gris-oscuro bold mx-3 my-3">Total de ingresos</span>
                                    <div className="d-flex h-100 mb-3 align-items-center px-3 gris">
                                        <LoadMask loading={loader} blur>
                                            <ResponsiveContainer width="100%" height={250} minWidth={150} minHeight={175}>
                                                <BarChart width={730} height={250} data={ingresos} layout="vertical" barCategoryGap={1} barSize={30}>
                                                    <XAxis type="number" />
                                                    <Tooltip />
                                                    <CartesianGrid strokeDasharray="3 3" />
                                                    <YAxis type="category" width={150} tick={{ dx: -7 }} padding={{ left: 20 }} dataKey="name" />
                                                    <Bar dataKey="value" fill="#2d8de6" background={{ fill: '#eee' }} >
                                                        {ingresos && ingresos.map((entry, index) => (
                                                            <Cell key={`cell-${index}`} fill={entry.color} />
                                                        ))}
                                                    </Bar>
                                                </BarChart>
                                            </ResponsiveContainer>
                                        </LoadMask>
                                    </div>
                                    <span className="px-4 gris-oscuro bold">Total Ingresos:
                                        <span className="mb-1 gris-letras bold"> Total ingresos transferidos por Cyber Source</span>
                                    </span>
                                    <span className="px-4 gris-oscuro bold">Total Ingresos Neto:
                                        <span className="mb-1 gris-letras bold"> Total ingresos transferidos restando los retiros y devoluciones ya realizadas </span>
                                    </span>
                                    <span className="px-4 gris-oscuro bold">Ingresos en reserva:
                                        <span className="mb-1 gris-letras bold"> Total ingresos que se encuentran como saldo a favor de proyectos EN RECEPCIÓN OFERTAS, BORRADOR y VENCIDOS </span>
                                    </span>
                                    <span className="px-4 gris-oscuro bold mb-2">Ingresos en retención:
                                        <span className="mb-1 gris-letras bold"> Total ingresos que se encuentran en proyectos ADJUDICADOS, EN CURSO </span>
                                    </span>

                                </div>
                            </div>
                            <div className="col-lg-6 col-12 mb-3 px-0 px-lg-3">
                                <div className="card-proyecto h-100">
                                    <span className="gris-oscuro bold mx-3 my-3">Total de gastos (por realizar)</span>
                                    <div className="d-flex h-100 align-items-center px-3 gris">
                                        <LoadMask loading={loader} blur>
                                            <ResponsiveContainer width="100%" height={250} minWidth={150} minHeight={175}>
                                                <BarChart width={730} height={250} data={gastos} layout="vertical" barCategoryGap={1} barSize={30}>
                                                    <XAxis type="number" />
                                                    <Tooltip />
                                                    <CartesianGrid strokeDasharray="3 3" />
                                                    <YAxis type="category" width={150} tick={{ dx: -7 }} padding={{ left: 20 }} dataKey="name" />
                                                    <Bar dataKey="value" fill="#2d8de6" background={{ fill: '#eee' }} >
                                                        {gastos && gastos.map((entry, index) => (
                                                            <Cell key={`cell-${index}`} fill={entry.color} />
                                                        ))}
                                                    </Bar>
                                                </BarChart>
                                            </ResponsiveContainer>
                                        </LoadMask>
                                    </div>
                                    <span className="px-4 gris-oscuro bold">Pagos a transportistas:
                                        <span className="mb-1 gris-letras bold"> La cantidad total que se debe de pagar a los transportistas</span>
                                    </span>
                                    <span className="px-4 gris-oscuro bold mb-5">Devoluciones a contratistas:
                                        <span className="mb-1 gris-letras bold"> La cantidad total que se le debe de pagar a los contratistas</span>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className="d-flex flex-wrap justify-content-center align-items-center my-4">
                            <div className="col-md-4 col-12">
                                <Link className="btn btn-primary btn-block" to="/devoluciones">Panel devoluciones</Link>
                            </div>
                        </div>
                    </div>
                </Card>
            </div>
        )
    }
}
