// * * Saber si el dato es una imagen * * * *
export const esImagen = (filename) => {
    const img = ['jpg', 'png', 'gif', 'jpeg'];

    const inicioNombre = filename.substr(0, 30)
    const finalNombre = filename.substr(-20)

    for (let index = 0; index < img.length; index++) {
        if (inicioNombre.includes(img[index]) || finalNombre.includes(img[index]))
            return true
    };
    return false


}

// - - Color borde de los cards
import { ESTADO_PROYECTO } from "./constants"
export const COLOR_BORDE_LEFT = (estado_proyecto = 0) => {
    const estado = ESTADO_PROYECTO.find(element => element.value == estado_proyecto)
    let style = { borderLeft: `20px solid #32a836` }
    if (estado) {
        style = { borderLeft: `20px solid ${estado.color}` }
    }
    return style
}

// - - Devuelve el color del estado
export const COLOR_ESTADO = (estado_proyecto = 0) => {
    const estado = ESTADO_PROYECTO.find(element => element.value == estado_proyecto)
    let color = "#32a836"
    if (estado) {
        color = estado.color
    }
    return color
}

// - - Color boton de > ver proyecto
export const COLOR_BOTON = (estado_proyecto = 0) => {
    const estado = ESTADO_PROYECTO.find(element => element.value == estado_proyecto)
    let style = {
        color: "#fff",
        backgroundColor: "#32a836",
        borderColor: "#32a836",
        boxShadow: "1px 1px 2px #7EA04D99",
    }
    if (estado) {
        style = {
            ...style,
            backgroundColor: estado.color,
            borderColor: estado.color
        }
    }
    return style
}


// - - Devuelve el color del estado
export const TITULO_ESTADO = (estado_proyecto = 0) => {
    const estado = ESTADO_PROYECTO.find(element => element.value == estado_proyecto)
    let text = ""
    if (estado) {
        text = estado.title
    }
    return text
}
