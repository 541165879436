import { connect } from 'react-redux';
import { actions } from '../../../../../redux/modules/config_global/config_global';
import Config from './ConfigGlobalCrear';

const mstp = state => {
    return {...state.config_globales}
};

const mdtp = {
    ...actions
};

export default connect(mstp, mdtp)(Config)
