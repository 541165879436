import React, { Component } from 'react'
import CardForm from 'Utils/Cards/CardForm';
import LoadMask from 'Utils/LoadMask/LoadMask';
import Devolucion from './Devolucion';
import { SWALMOD } from '../../../../../utility/constants'
const alerta_img = require('assets/img/icons/alert.svg');

export default class Crear extends Component {
    componentWillMount() {
        if (this.props.match.params.id) {
            this.props.leer(this.props.match.params.id);
        }
    }
    realizarDevolucion = (data) => {
        SWALMOD.fire({
            title: '¿Realizar devolución?',
            text: '¡Confirmar si ya se realizó la devolución manualmente!',
            showCancelButton: true,
            confirmButtonText: '¡Sí, realizado!',
            cancelButtonText: 'No, cancelar',
            reverseButtons: true,
            imageUrl: alerta_img,
            imageAlt: 'Alerta',
            imageWidth: 90,
        }).then((result) => {
            if (result.value) {
                this.props.realizarDevolucion(this.props.match.params.id, data)
            }
        });

    };

    render() {
        const { loader, item } = this.props;
        return (
            <div className="mb-4 col-12 mt-4">
                <CardForm
                    titulo="DETALLE DEVOLUCIÓN">
                    <LoadMask loading={loader} blur>

                        <Devolucion
                            realizarDevolucion={this.realizarDevolucion}
                            item={item}
                        />
                    </LoadMask>
                </CardForm>
            </div>
        )
    }
}
