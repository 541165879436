import React, { Component } from 'react'
import CardForm from 'Utils/Cards/CardForm';
import LoadMask from 'Utils/LoadMask/LoadMask';
import Form from './Form';
import EditarPais from './Form';

export default class Crear extends Component {
    state = {
        editar: false,
    };
    componentWillMount() {
        if (this.props.match.params.id) {
            this.props.leer(this.props.match.params.id);
            this.setState({ editar: true });
        }
    }
    actualizar = (data) => {
        this.props.editar(this.props.match.params.id, data)
    };
    render() {
        const { crear, loader } = this.props;
        return (
            <div className="mb-4 col-12 mt-4">
                <CardForm
                    titulo={this.state.editar ? "EDITAR PAÍS" : "NUEVO PAÍS"}>
                    <LoadMask loading={loader} blur>
                        {this.state.editar ?
                            <EditarPais
                                onSubmit={this.actualizar}
                                editando={true} />
                            :
                            <Form onSubmit={crear} />
                        }
                    </LoadMask>
                </CardForm>
            </div>
        )
    }
}
