import React, { Fragment } from 'react';
import { connect } from 'react-redux'
import { Link } from 'react-router-dom';
import { validate, validators, combine } from 'validate-redux-form';
import { Field, reduxForm, formValueSelector, } from 'redux-form';
import { renderField, renderSwitch, renderPasswordField, renderNumber } from '../../../Utils/renderField/renderField';
import { validationAdministrador } from '../../../../../utility/validationForm'
let AdminForm = props => {
    const { handleSubmit, editando } = props;
    return (
        <form onSubmit={handleSubmit}>
            <div className="form-group grid-container col-12 px-0 px-sm-4">
                <div className="row  col-md-12 p-0">
                    <div className="col-md-6 p-0">
                        <div className="col-md-12 col-sm-12">
                            <label htmlFor="nombre" className="m-0">Nombre completo*</label>
                        </div>
                        <div className="col-md-12  form-group">
                            <Field name="nombre" component={renderField} type="text" className="form-control" maxLength={validationAdministrador.nombre.MaxLength} />
                        </div>
                    </div>
                    <div className="col-md-6 p-0">
                        <div className="col-md-12 col-sm-12">
                            <label htmlFor="username" className="m-0">Usuario*</label>
                        </div>
                        <div className="col-md-12  form-group">
                            <Field name="username" component={renderField} type="text" className="form-control" maxLength={validationAdministrador.username.MaxLength} />
                        </div>
                    </div>
                </div>
                <div className="row  col-md-12 p-0">
                    <div className="col-md-6 p-0">
                        <div className="col-md-12 col-sm-12">
                            <label htmlFor="email" className="m-0">Email*</label>
                        </div>
                        <div className="col-md-12  form-group">
                            <Field name="email" component={renderField} type="email" className="form-control" maxLength={validationAdministrador.email.MaxLength} />
                        </div>
                    </div>
                    <div className="col-md-6 p-0">
                        <div className="col-md-12 col-sm-12">
                            <label htmlFor="telefono" className="m-0">Teléfono*</label>
                        </div>
                        <div className="col-md-12  form-group">
                            <Field name="telefono" component={renderNumber} type="text" className="form-control" isNumericString={true} maxLength={validationAdministrador.telefono.MaxLength} />
                        </div>
                    </div>
                </div>
                {(editando) && (
                    <div className="row  col-md-12 p-0">
                        <div className="col-md-6 p-0">
                            <div className="col-md-12 col-sm-12">
                                <label htmlFor="metodo_pago" className="m-0">Usuario activo</label>
                            </div>
                            <div className="col-md-12  form-group">
                                <Field
                                    name="is_active"
                                    label=""
                                    component={renderSwitch}
                                />
                            </div>
                        </div>
                        <div className="col-md-6 p-0 d-flex">
                            <div className="col-md-12 col-sm-12 align-self-center">
                                <Field name="new_password" placeholder="Nueva contraseña" component={renderPasswordField} minLength={6} className="form-control" />
                            </div>
                        </div>
                    </div>
                )}
                <div className="col-12 text-right">
                    <span className="azul bold text-xsm">* Campos requeridos</span>
                </div>
                <div className="d-flex flex-wrap justify-content-center align-items-center mt-5">
                    <div className="col-md-5 col-lg-5 col-xl-4 col-12">
                        <Link className="btn btn-dark btn-block" to="/administradores">Cancelar</Link>
                    </div>
                    <div className="col-md-5 col-lg-5 col-xl-4 col-12 mt-md-0 mt-3">
                        <button type="submit" className="btn btn-primary btn-block">Guardar</button>
                    </div>
                </div>
            </div>
        </form>
    )
};
export const AdminFormCrear = reduxForm({
    form: 'EditarAdminForm',
    validate: data => {
        return validate(data, {
            nombre: validationAdministrador.nombre.Combine,
            telefono: validationAdministrador.telefono.Combine,
            username: validationAdministrador.username.Combine,
            email: validationAdministrador.email.Combine,
        })
    }
})(AdminForm);

export const AdminUpdateForm = reduxForm({
    form: 'EditarAdminForm',
    validate: data => {
        let errors = {};
        if (data && data.new_password && data.new_password.length < 6) {
            errors.new_password = "Mínimo 6 carácteres"
        }
        if (data && data.new_password && data.new_password.length > 150) {
            errors.new_password = "Máximo 150 carácteres"
        }
        return {
            ...errors, ...validate(data, {
                nombre: validationAdministrador.nombre.Combine,
                telefono: validationAdministrador.telefono.Combine,
                username: validationAdministrador.username.Combine,
                email: validationAdministrador.email.Combine,
            })
        }
    }
})(AdminForm);
