import React, {Component} from 'react'
import Grid from "../../../Utils/Grid";
import {TableHeaderColumn} from "react-bootstrap-table";
import {standardActions} from "../../../Utils/Grid/StandardActions";
import Link from "react-router-dom/es/Link";
import Card from "Utils/Cards/Card"
import Header from "Utils/Grid/Header";
import { tipo_vehiculo } from '../../../../../utility/constants';

export default class List extends Component{
    componentWillMount() {
        const { listar, page } = this.props;
        listar(page);
    }

    render() {
        const { listar: onPageChange, onSortChange, eliminar } = this.props;
        const { data, loader, page } = this.props;
        return(
            <div className="mb-4 col-12 mt-4">
                <Card
                    titulo="TIPOS DE VEHÍCULOS">
                    <Header to="tipo_vehiculo/crear" textBtn="Crear tipo vehiculo" {...this.props} />
                    <Grid hover striped data={data} loading={loader} onPageChange={onPageChange}
                          onSortChange={onSortChange} page={page} >
                        <TableHeaderColumn
                            dataField="id"
                            dataAlign="center"
                            dataSort
                            width='20%'
                            dataFormat={standardActions({ editar: "tipo_vehiculo", eliminar})}
                        />
                        <TableHeaderColumn
                            dataAlign="left"
                            dataField="tipo"
                            width='20%'
                            dataSort
                            dataFormat={cell => {
                                if (cell) {
                                    const nombre = tipo_vehiculo.filter((item) => item.value === cell)
                                    return nombre[0].label
                                }
                                else{
                                    return "--"
                                }
                            }}
                        >
                            TIPO
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            isKey
                            dataField="nombre"
                            dataSort
                            width='20%'
                        >
                            NOMBRE
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataAlign="left"
                            dataField="id"
                            width='10%'
                            dataSort
                        >
                            CÓDIGO
                        </TableHeaderColumn>

                    </Grid>
                </Card>
            </div>
        )
    }
}
