import React, { Component } from 'react'
import Grid from "../../../Utils/Grid";
import Card from "Utils/Cards/Card"
import { TableHeaderColumn } from "react-bootstrap-table";
import Link from "react-router-dom/es/Link";
import LoadMask from 'Utils/LoadMask/LoadMask';
import HeaderPanelFinanciero from "Utils/Grid/HeaderPanelFinanciero";
import DatePicker from "react-datepicker";
import { RenderNumber, RenderDollar } from '../../../Utils/renderField/renderReadField';
import moment from 'moment';
import { INGRESO, EGRESO } from '../../../../../utility/constants'

export default class ListarMovimiento extends Component {
    componentWillMount() {
        if (this.props.match.params.id) {
            this.props.setIdTransportista(this.props.match.params.id);
        }
    }

    render() {
        const { listarMovimientosReporte: onPageChange, onSortChange } = this.props; //Funciones
        const { totales = "", data, loader, page } = this.props; //Constantes
        const { puntos_total = 0, puntos_total_dinero = 0, saldo_total = 0, saldo_pendiente = 0 } = totales;

        return (
            <div className="mb-4 col-12 mt-4">
                <Card titulo={`Panel financiero - ${totales.nombre ? totales.nombre : ""}`}>
                    <div className="col-12 p-0">
                        <div className="row p-0">

                            <div className="col-lg-6 mb-3 col-12 px-0 pl-lg-3  pr-lg-2">
                                <HeaderPanelFinanciero
                                    filtro1={
                                        <DatePicker
                                            showPopperArrow={false}
                                            dateFormat="DD/MM/YYYY"
                                            placeholderText="Fecha inicial"
                                            onChange={(value) => { this.props.changeFechaInicio(value) }}
                                            selected={this.props.filtro_fecha_inicio}
                                        />
                                    }
                                    filtroText1="Fecha Inicio"
                                    filtro2={
                                        <DatePicker
                                            showPopperArrow={false}
                                            dateFormat="DD/MM/YYYY"
                                            placeholderText="Fecha final"
                                            onChange={(value) => { this.props.changeFechaFinal(value) }}
                                            selected={this.props.filtro_fecha_fin}
                                        />
                                    }
                                    filtroText2="Fecha Fin"
                                />
                            </div>
                            <div className="col-lg-3 col-sm-6 col-12  px-0 pr-sm-2  mb-3 pr-lg-2">
                                <LoadMask loading={loader} blur>
                                    <div className="card-proyecto">
                                        <div className="col-12 mt-3">
                                            <span className="gris-oscuro bold total-pagar">SALDO PENDIENTE</span>
                                        </div>
                                        <div className="col-12">
                                            <RenderDollar value={saldo_pendiente} className="azul text-35 ml-lg-3 ml-0" />
                                        </div>
                                    </div>
                                </LoadMask>
                            </div>
                            <div className="col-lg-3 col-sm-6 col-12  px-0 pr-lg-3 mb-3">
                                <LoadMask loading={loader} blur>
                                    <div className="card-proyecto">
                                        <div className="col-12 mt-3">
                                            <span className="gris-oscuro bold total-pagar">SALDO DISPONIBLE</span>
                                        </div>
                                        <div className="col-12">
                                            <RenderDollar value={saldo_total} className="naranja text-35 ml-lg-3 ml-0" />
                                        </div>
                                    </div>
                                </LoadMask>
                            </div>

                        </div>
                    </div>
                    <div className="col-12 p-0 ">
                        <div className="row p-0 d-flex justify-content-start px-0 px-md-3">
                            {saldo_total > 0 ?
                                <Link to="/panel-financiero-transportista-retiro/parcial" className="btn btn-primary mb-3 mr-auto">Hacer retiro</Link>
                                :
                                <span className="btn btn-primary mb-3 mr-auto cursor-desactivado">Hacer retiro</span>
                            }
                            {puntos_total > 0 &&
                                <Link to="/panel-financiero-transportista-retiro/puntos" className="btn btn-secondary mb-3 ml-4 mr-0 mr-lg-2">Retirar Puntos</Link>
                            }
                            <div className="col-12 col-md-auto px-0">
                                <LoadMask loading={loader} blur>
                                    <div className="col-12 d-flex align-content-center px-0 flex-sm-row flex-column mb-3">
                                        <div className="card-proyecto flex-1 text-center px-auto px-sm-4">
                                            <span className="gris-oscuro bold text-sm">
                                                Total puntos:
                                                <span className="azul bold text-md"> {puntos_total}</span>
                                                &nbsp;equivalente a:&nbsp;
                                                <RenderDollar value={puntos_total_dinero} className="naranja bold text-md" />
                                            </span>
                                        </div>
                                    </div>
                                </LoadMask>
                            </div>
                        </div>
                    </div>
                    <div className="w-100">
                        <Grid trClassName="table-wrap" hover striped data={data} loading={loader} onPageChange={onPageChange} onSortChange={onSortChange} page={page}>
                            <TableHeaderColumn
                                dataField="creado"
                                isKey
                                dataAlign="center"
                                dataFormat={(cell) => cell ? moment(cell).format('DD-MM-YYYY') : ""}

                            >
                                FECHA
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="razon"
                                dataAlign='left'
                            >
                                RAZóN
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="descripcion"
                                dataAlign='left'
                            >
                                DESCRIPCIÓN
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="total"
                                dataAlign='left'
                                dataFormat={(cell, { tipo_movimiento = 0 }) => {
                                    return tipo_movimiento == EGRESO ? < RenderDollar value={cell ? cell : 0} /> : "--"
                                }}
                            >
                                EGRESO (-)
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="total"
                                dataAlign='left'
                                dataFormat={(cell, { tipo_movimiento = 0 }) => {
                                    return tipo_movimiento == INGRESO ? < RenderDollar value={cell ? cell : 0} /> : "--"
                                }}
                            >
                                INGRESO (+)
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="saldo"
                                dataAlign='left'
                                dataFormat={(cell) => <RenderDollar value={cell ? cell : 0} />}
                            >
                                SALDO
                            </TableHeaderColumn>
                        </Grid>
                    </div>
                </Card>
            </div>
        )
    }
}
