import { createAction, handleActions } from 'redux-actions';
import { api } from "api";
import {initialize as initializeForm} from "redux-form";
import {NotificationManager} from "react-notifications";
import {push} from "react-router-redux";
import _ from "lodash";

const LOADER = 'LOADER_CONFIG_PLATAFORMA';
const ITEM_DATA = 'ITEM_CONFIG_PLATAFORMA';

// -----------------------------------
// Pure Actions
// -----------------------------------

const setLoader = loader => ({
    type: LOADER,
    loader,
});

const setItem = item => ({
    type: ITEM_DATA,
    item,
});


// ------------------------------------
// Actions
// ------------------------------------


const leer = () => (dispatch) => {
    dispatch(setLoader(true));
    api.get('config_global').then((response) => {
        dispatch(setItem(response));
        dispatch(initializeForm('ConfigGlobalForm', response));
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const crear = data => (dispatch) => {
    dispatch(setLoader(true));
    api.post('config_global', data).then(() => {
        NotificationManager.success('Registro actualizado', 'Éxito', 3000);
        dispatch(leer());
    }).catch((error) => {
        let mensaje = "Error en la creación";
        NotificationManager.error(mensaje, 'ERROR');
    }).finally(() => {
        dispatch(setLoader(false));
    });
} 


export const actions = {
    leer,
    crear,
};

// ------------------------------------
// Reducers
// ------------------------------------

export const reducers = {
    [LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    [ITEM_DATA]: (state, { item }) => {
        return {
            ...state,
            item,
        };
    },
};

export const initialState = {
    loader: false,
    item: {},
};

export default handleActions(reducers, initialState);
