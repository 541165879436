import React, { Component } from 'react'
import Card from "Utils/Cards/Card"
import CardForm from 'Utils/Cards/CardForm';
import Header from "Utils/Grid/Header";
import { Link } from 'react-router-dom';
import LoadMask from 'Utils/LoadMask/LoadMask';
import Actual from './Actual';
import Anterior from './Anterior';

import moment from 'moment';
moment.locale('es');

export default class Bitacora extends Component{

    componentWillMount() {
        this.props.leer(this.props.match.params.id);
    }

    render() {
        const { item, loader } = this.props; //Constantes

        return(
            <div className="mb-4 col-12 mt-4">
                <LoadMask loading={loader} dark blur>
                    <CardForm titulo="DETALLE BITÁCORA" col="11">
                        <div className="d-flex flex-lg-row flex-column mb-3">
                            <div className="d-flex flex-column flex-1">
                                <span className="gris-oscuro">Acción</span>
                                <span className="text-qts bold azul">{item ? item.accion : ''}</span>
                            </div>
                        </div>
                        <div className="d-flex flex-lg-row flex-column mb-5">
                            <div className="d-flex flex-column flex-1">
                                <span className="gris-oscuro">Fecha</span>
                                <span className="text-sm bold azul">{item ? `${moment(item.creado).format("DD [de] MMMM YYYY")} / ${moment(item.creado).format("HH:mm")} hrs.` : ''}</span>
                            </div>
                            <div className="d-flex flex-column flex-1">
                                <span className="gris-oscuro">Usuario</span>
                                <span className="text-sm bold azul">{item ? item.usuario ? item.usuario.nombre : '' : ''}</span>
                            </div>
                        </div>
                        <div className="d-flex flex-column flex-1 flex-lg-row">
                            {!!item.detalle_anterior && (
                                <div className="flex-1 pr-4" style={{overflowX: "auto"}}>
                                    <Anterior detalle={JSON.parse(item.detalle_anterior)}/>
                                    {/* dfd */}
                                </div>
                            )}
                            {!!item.detalle && (
                                <div className="mt-3 mt-lg-0 flex-1 pr-4" style={{overflowX: "auto"}}>
                                    <Actual detalle={JSON.parse(item.detalle)} anterior={JSON.parse(item.detalle_anterior)} />
                                    {/* df */}
                                </div>
                            )}
                        </div>
                        <div className="d-flex flex-row flex-1 justify-content-center mt-5 mb-3">
                            <Link className="btn btn-secondary" to="/bitacora">Regresar</Link>
                        </div>
                    </CardForm>
                </LoadMask>
            </div>
        )
    }
}
