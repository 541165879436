import { createAction, handleActions } from 'redux-actions';
import { api } from "api";
import { initialize as initializeForm } from "redux-form";
import { NotificationManager } from "react-notifications";
import { push } from "react-router-redux";
import _ from "lodash";
import moment from "moment";
import { reset } from 'redux-form';
import { USER_ADMIN } from "../../../utility/constants";

const LOADER = 'LOADER_DASHBOARD';
const DATA = 'DATA_DASHBOARD';
const ITEM_DATA = 'ITEM_DASHBOARD';
const PAGE = 'PAGE_DASHBOARD';
const ORDERING = 'ORDERING_DASHBOARD';
const SEARCH = 'SEARCH_DASHBOARD';
const FILTRO_FECHA_INICIO = 'FILTRO_FECHA_INICIO_DASHBOARD';
const FILTRO_FECHA_FIN = 'FILTRO_FECHA_FIN_DASHBOARD';
const FILTRO_USUARIO = 'FILTRO_USUARIO_DASHBOARD';

// -----------------------------------
// Pure Actions
// -----------------------------------

const setLoader = loader => ({
    type: LOADER,
    loader,
});

const setData = data => ({
    type: DATA,
    data,
});

const setItem = item => ({
    type: ITEM_DATA,
    item,
});

const setPage = page => ({
    type: PAGE,
    page,
});

const setOrdering = ordering => ({
    type: ORDERING,
    ordering,
});

const setSearch = search => ({
    type: SEARCH,
    search,
});

// ------------------------------------
// Actions
// ------------------------------------

const getDataDashboard = () => (dispatch, getStore) => {
    const resource = getStore().dashboard;
    const params = {};
    if (resource.filtro_fecha_inicio) {
        params.fecha_inicio = moment(resource.filtro_fecha_inicio).format('YYYY-MM-DD');
    }
    if (resource.filtro_fecha_fin) {
        params.fecha_fin = moment(resource.filtro_fecha_fin).format('YYYY-MM-DD');
    }

    dispatch(setLoader(true));
    api.get('dashboard/dashboard', params).then((response) => {
        dispatch(setData(response));
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const filtroFechaInicio = filtro_fecha_inicio => (dispatch) => {
    dispatch({ type: FILTRO_FECHA_INICIO, filtro_fecha_inicio })
    dispatch(getDataDashboard());
};

const filtroFechaFin = filtro_fecha_fin => (dispatch) => {
    dispatch({ type: FILTRO_FECHA_FIN, filtro_fecha_fin })
    dispatch(getDataDashboard());
};

export const actions = {
    getDataDashboard,
    filtroFechaInicio,
    filtroFechaFin,
};

// ------------------------------------
// Reducers
// ------------------------------------

export const reducers = {
    [LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    [DATA]: (state, { data }) => {
        return {
            ...state,
            data,
        };
    },
    [ITEM_DATA]: (state, { item }) => {
        return {
            ...state,
            item,
        };
    },
    [PAGE]: (state, { page }) => {
        return {
            ...state,
            page,
        };
    },
    [ORDERING]: (state, { ordering }) => {
        return {
            ...state,
            ordering,
        };
    },
    [SEARCH]: (state, { search }) => {
        return {
            ...state,
            search,
        };
    },
    [SEARCH]: (state, { search }) => {
        return {
            ...state,
            search,
        };
    },
    [FILTRO_FECHA_INICIO]: (state, { filtro_fecha_inicio }) => {
        return {
            ...state,
            filtro_fecha_inicio,
        };
    },
    [FILTRO_FECHA_FIN]: (state, { filtro_fecha_fin }) => {
        return {
            ...state,
            filtro_fecha_fin,
        };
    },
};

export const initialState = {
    loader: false,
    data: {
        total_ingresos: 0,
        total_ingresos_iva: 0,
        total_pago_pendiente: 0,
        total_devoluciones: 0,
        total_publicados: 0,
        total_esperando_ofertas: 0,
        total_adjudicaciones: 0,
        total_en_curso: 0,
        total_finalizados: 0,
    },
    item: {},
    page: 1,
    ordering: '',
    search: '',
    filtro_fecha_inicio: null,
    filtro_fecha_fin: moment().endOf('month'),
};

export default handleActions(reducers, initialState);
