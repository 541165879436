import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { validate, validators, combine } from 'validate-redux-form';
import { Field, reduxForm, formValueSelector, } from 'redux-form';
import { renderField, renderSwitch, SelectField } from '../../../Utils/renderField/renderField';
import { tipo_vehiculo } from '../../../../../utility/constants';
import { validationOtros } from '../../../../../utility/validationForm'
let TipoVehiculoForm = props => {
    const { handleSubmit } = props;
    return (
        <form onSubmit={handleSubmit}>
            <div className="form-group grid-container col-12 px-0 px-sm-4">
                <div className="row  col-md-12 p-0">
                    <div className="col-md-6 p-0">
                        <div className="col-md-12 col-sm-12">
                            <label htmlFor="tipo" className="m-0">Tipo*</label>
                        </div>
                        <div className="col-md-12  form-group">
                            <Field name="tipo" component={SelectField} options={tipo_vehiculo} className="form-control" />
                        </div>
                    </div>
                    <div className="col-md-6 p-0">
                        <div className="col-md-12 col-sm-12">
                            <label htmlFor="nombre" className="m-0">Nombre*</label>
                        </div>
                        <div className="col-md-12  form-group">
                            <Field name="nombre" component={renderField} type="text" className="form-control" maxLength={validationOtros.numeroLetro50.MaxLength} />
                        </div>
                    </div>
                </div>
                <div className="col-12 text-right">
                    <span className="azul bold text-xsm mr-4">* Campos requeridos</span>
                </div>
                <div className="d-flex flex-wrap justify-content-center align-items-center mt-5">
                    <div className="col-md-4 col-12">
                        <Link className="btn btn-dark btn-block" to="/tipos_vehiculo">Cancelar</Link>
                    </div>
                    <div className="col-md-4 col-12 mt-md-0 mt-3">
                        <button type="submit" className="btn btn-primary btn-block">Guardar</button>
                    </div>
                </div>
            </div>
        </form>
    )
};
TipoVehiculoForm = reduxForm({
    form: 'TipoVehiculoForm',
    validate: data => {
        return validate(data, {
            'nombre': validationOtros.numeroLetro50.Combine,
            'tipo': validationOtros.requerido.Combine,
        })
    }
})(TipoVehiculoForm);

export default TipoVehiculoForm
