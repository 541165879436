import { connect } from 'react-redux';
import { actions } from '../../../../../redux/modules/publicidades';
import PendientesValidarList from './PendientesValidar';


const ms2p = (state) => {
    return {
        ...state.publicidades,
    };
};

const md2p = { ...actions };

export default connect(ms2p, md2p)(PendientesValidarList);
