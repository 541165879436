import { createAction, handleActions } from 'redux-actions';
import { api } from "api";
import _ from "lodash";
import moment from 'moment';

const LOADER = 'LOADER_REPORTES_GESTION_FINANCIERA';
const GRAFICA = 'GRAFICA_REPORTES_GESTION_FINANCIERA';

// FILTROS DE REPORTES PROYECTOS Y OFERTAS
const FILTRO_FECHA_INICIO_GESTION_FINANCIERA = 'FILTRO_FECHA_INICIO_GESTION_FINANCIERA';
const FILTRO_FECHA_FIN_GESTION_FINANCIERA = 'FILTRO_FECHA_FIN_GESTION_FINANCIERA';

// -----------------------------------
// Pure Actions
// -----------------------------------

const setLoader = loader => ({
    type: LOADER,
    loader,
});

const setGrafica = data_grafica => ({
    type: GRAFICA,
    data_grafica,
});

// ------------------------------------
// Actions
// ------------------------------------


const dataGraficaProyectosReporte = () => (dispatch, getStore) => {
    const resource = getStore().financiero;
    const params = {};
    if (resource.filtro_fecha_inicio && resource.filtro_fecha_fin) {
        params.fecha_inicio = moment(resource.filtro_fecha_inicio).format('YYYY-MM-DD');
        params.fecha_fin = moment(resource.filtro_fecha_fin).format('YYYY-MM-DD');
    }

    dispatch(setLoader(true));
    dispatch(setGrafica([]));
    api.get('reportes/reporte_financiero', params).then((response) => {
        dispatch(setGrafica(response));
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const changeFechaInicio = filtro_fecha_inicio => (dispatch) => {
    dispatch({ type: FILTRO_FECHA_INICIO_GESTION_FINANCIERA, filtro_fecha_inicio });
    dispatch(dataGraficaProyectosReporte())
};

const changeFechaFinal = filtro_fecha_fin => (dispatch) => {
    dispatch({ type: FILTRO_FECHA_FIN_GESTION_FINANCIERA, filtro_fecha_fin });
    dispatch(dataGraficaProyectosReporte())
};

export const actions = {
    dataGraficaProyectosReporte,
    changeFechaInicio,
    changeFechaFinal,
};

// ------------------------------------
// Reducers
// ------------------------------------

export const reducers = {
    [LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    [GRAFICA]: (state, { data_grafica }) => {
        return {
            ...state,
            data_grafica,
        };
    },
    // FILTROS DE REPORTES PROYECTOS Y OFERTAS
    [FILTRO_FECHA_INICIO_GESTION_FINANCIERA]: (state, { filtro_fecha_inicio }) => {
        return {
            ...state,
            filtro_fecha_inicio,
        };
    },
    [FILTRO_FECHA_FIN_GESTION_FINANCIERA]: (state, { filtro_fecha_fin }) => {
        return {
            ...state,
            filtro_fecha_fin,
        };
    },
};

export const initialState = {
    loader: false,
    data_grafica: [],
    filtro_fecha_inicio: null,
    filtro_fecha_fin: moment().endOf('month'),
};

export default handleActions(reducers, initialState);
