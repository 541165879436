import { connect } from 'react-redux';
import { actions } from '../../../../../redux/modules/devoluciones';
import Lista from './Listar';


const ms2p = (state) => {
    return {
        ...state.devoluciones,
    };
};

const md2p = { ...actions };

export default connect(ms2p, md2p)(Lista);
