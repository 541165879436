import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import {
    renderField,
    renderFilePicker,
} from '../../Utils/renderField/renderField';
import ProfileForm from './ProfileForm';
import CardForm from "Utils/Cards/CardForm";

class Profile extends Component {
    constructor(props) {
        super(props);
        this.state = { avatar: null };
    }

    componentDidMount() {
        this.props.reInitForm();
    }


    setAvatar = (avatar) => {
        this.setState({ avatar });
    };

    update = (data) => {
        const { update } = this.props;
        update({ ...data, avatar: null }, [
            { file: this.state.avatar, name: 'avatar' },
        ]);
    };

    render() {
        const { me } = this.props;

        return (
        <div className="mb-4 col-12 mt-4">
            <CardForm
            titulo="MI PERFIL">
                 <ProfileForm
                onSubmit={this.update}
                me={me}
                setAvatar={this.setAvatar}
                />
            </CardForm>
        </div>         
        );
    }
}

export default reduxForm({
    form: 'profile', // a unique identifier for this form
})(Profile);
