import { connect } from 'react-redux';
import { actions } from '../../../../../redux/modules/devoluciones';

import React, { Component } from 'react';
import Tabs, { TabPane } from 'rc-tabs';
import TabContent from 'rc-tabs/lib/TabContent';
import ScrollableInkTabBar from 'rc-tabs/lib/ScrollableInkTabBar';
import Devoluciones from './index'
import Card from "Utils/Cards/Card";
import { DEVOLUCION_PENDIENTE, DEVOLUCION_REALIZADA } from '../../../../../utility/constants'


class DevolucionesTabs extends Component {
    render() {
        return (
            <div className="mb-4 col-12 mt-4">
                <Card titulo="PANEL DE DEVOLUCIONES">
                    <Tabs
                        defaultActiveKey={String(DEVOLUCION_PENDIENTE)}
                        tabBarPosition="top"
                        onChange={(tab) => this.props.estadoProyectoTab(tab)}
                        renderTabBar={() => <ScrollableInkTabBar />}
                        renderTabContent={() => <TabContent />}
                        activeKey={String(this.props.estado_devolucion_tab)}
                    >
                        <TabPane tab="PENDIENTES" key={DEVOLUCION_PENDIENTE}>
                            <div className="py-4 px-3">
                                <Devoluciones estado_devolucion={DEVOLUCION_PENDIENTE} />
                            </div>
                        </TabPane>
                        <TabPane tab="REALIZADAS" key={DEVOLUCION_REALIZADA}>
                            <div className="py-4 px-3">
                                <Devoluciones estado_devolucion={DEVOLUCION_REALIZADA} />
                            </div>
                        </TabPane>
                    </Tabs>

                </Card>
            </div>
        );
    }
}
const ms2p = (state) => {
    return {
        ...state.devoluciones,
    };
};
const md2p = { ...actions };
export default connect(ms2p, md2p)(DevolucionesTabs);
