import React, { Component } from 'react'
import LoadMask from 'Utils/LoadMask/LoadMask';
import CardForm from 'Utils/Cards/CardForm';
import TransportistaForm from './Form';
import { Redirect } from 'react-router-dom';

export default class Editar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            editar: false,
            rtu_img: null,
            patente_img: null,
            fianza: null,

            img_poliza_file_img: [],
            img_poliza_base64: []
        };
    }

    setRtuImg = (rtu_img) => {
        this.setState({ rtu_img });
    };
    setPatenteImg = (patente_img) => {
        this.setState({ patente_img });
    };
    setFianzaImg = (fianza) => {
        this.setState({ fianza });
    };

    setPoilizaImgs = (key, poliza_img, toBase64) => {
        const img_poliza_file_img = this.state.img_poliza_file_img;
        const img_poliza_base64 = this.state.img_poliza_base64;

        for (let index = 0; index < key + 1; index++) {
            if (key == index) {
                img_poliza_file_img[index] = poliza_img
                img_poliza_base64[index] = toBase64
            } else {
                if (!img_poliza_file_img[index]) {
                    img_poliza_file_img[index] = "x"
                    img_poliza_base64[index] = "x"
                }
            }
        }
        this.setState({ img_poliza_file_img });
        this.props.setArchivos(img_poliza_base64);

    }


    eliminarImgsPoliza = (index) => {
        let img_poliza_file_img = this.state.img_poliza_file_img;
        img_poliza_file_img = _.filter(img_poliza_file_img, (item, i) => i != index);
        // Seteamos el state
        this.setState({ img_poliza_file_img });
    }

    componentWillMount() {
        if (this.props.match.params.id) {
            this.props.leer(this.props.match.params.id);
            this.setState({
                editar: true,
            });
        }
    }

    actualizar = (data) => {

        let datos = { ...data }
        datos.ciudad_residencia = data.ciudad_residencia.id
        datos.pais_residencia = data.pais_residencia.id

        const docs = [
            { "file": this.state.rtu_img, "name": "rtu_img" },
            { "file": this.state.patente_img, "name": "patente_img" },
            { "file": this.state.fianza, "name": "fianza" },
        ]

        for (let i = 0; i < datos.polizas.length; i++) {
            datos.polizas[i].imagen = ""
            if (this.state.img_poliza_file_img[i] != "x") {
                docs.push({ "file": this.state.img_poliza_file_img[i], "name": `poliza_${i}` })
            }

        }
        this.props.editar(this.props.match.params.id, { ...datos, rtu_img: null, patente_img: null, fianza: null }, docs)
    };

    render() {
        const { clearSelectPais } = this.props;
        const { crear } = this.props;
        const { loader, updateData, item } = this.props;
        return (
            <div className="mb-4 col-12 mt-4">
                <CardForm titulo="EDITANDO TRANSPORTISTA">
                    <LoadMask loading={loader} blur>
                        {
                            this.state.editar ?
                                <TransportistaForm
                                    onSubmit={this.actualizar}
                                    editando={true}
                                    updateData={updateData}
                                    item={item}

                                    setRtuImg={this.setRtuImg}
                                    setPatenteImg={this.setPatenteImg}
                                    setFianzaImg={this.setFianzaImg}
                                    setPoilizaImgs={this.setPoilizaImgs}
                                    eliminarImgsPoliza={this.eliminarImgsPoliza}
                                    clearSelectPais={clearSelectPais}
                                />
                                : <Redirect to="/transportistas_validados" />
                        }
                    </LoadMask>
                </CardForm>
            </div>
        )
    }
}
