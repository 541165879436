import { createAction, handleActions } from 'redux-actions';
import { api } from "api";
import { initialize as initializeForm, change } from "redux-form";
import { NotificationManager } from "react-notifications";
import { push } from "react-router-redux";
import _ from "lodash";
import { reset } from 'redux-form';
import { USER_ADMIN } from "../../../utility/constants";

const LOADER = 'LOADER_TRANSPORTISTAS';
const DATA = 'DATA_TRANSPORTISTAS';
const ITEM_DATA = 'ITEM_TRANSPORTISTAS';
const PAGE = 'PAGE_TRANSPORTISTAS';
const ORDERING = 'ORDERING_TRANSPORTISTAS';
const SEARCH = 'SEARCH_TRANSPORTISTAS';
const SET_COMENTARIOS = 'SET_COMENTARIOS';

// -----------------------------------
// Pure Actions
// -----------------------------------

const setLoader = loader => ({
    type: LOADER,
    loader,
});

const setData = data => ({
    type: DATA,
    data,
});

const setItem = item => ({
    type: ITEM_DATA,
    item,
});

const setPage = page => ({
    type: PAGE,
    page,
});

const setOrdering = ordering => ({
    type: ORDERING,
    ordering,
});

const setSearch = search => ({
    type: SEARCH,
    search,
});
const setComentarios = comentarios => ({
    type: SET_COMENTARIOS,
    comentarios,
});

// ------------------------------------
// Actions
// ------------------------------------
const listarComentariosTransportista = id => (dispatch) => {
    dispatch(setComentarios([]))
    dispatch(setLoader(true));
    const params = { calificado: id }
    api.get("calificacion", params).then((response) => {
        dispatch(setComentarios(response))
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const listarValidados = (page = 1) => (dispatch, getStore) => {
    const resource = getStore().transportistas;
    const params = { page };
    params.ordering = resource.ordering;
    params.search = resource.search;
    dispatch(setLoader(true));
    api.get('user/transportistas_validados', params).then((response) => {
        dispatch(setData(response));
        dispatch(setPage(page));
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const listarPendientesValidar = (page = 1) => (dispatch, getStore) => {
    // const resource = getStore().transportistas;
    const params = { page };
    // params.ordering = resource.ordering;
    // params.search = resource.search;
    dispatch(setLoader(true));
    api.get('user/transportistas_pendientes_validar', params).then((response) => {
        dispatch(setData(response));
        dispatch(setPage(page));
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const listarPilotos = (page = 1, transportista) => (dispatch, getStore) => {
    const params = { page };
    if (transportista) {
        params.paginacion = false
        params.transportista_user_id = transportista;
        dispatch(setLoader(true));
        api.get('user', params).then((response) => {
            dispatch(setData(response));
            dispatch(setPage(page));
        }).catch(() => {
        }).finally(() => {
            dispatch(setLoader(false));
        });
    }

};

const leer = id => (dispatch) => {
    dispatch(setLoader(true));
    api.get(`${'user'}/${id}`).then((response) => {
        dispatch(setItem(response));
        dispatch(initializeForm('EditarTransportistaForm', response));
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const crear = data => (dispatch) => {
    dispatch(setLoader(true));
    api.post('user', data).then(() => {
        NotificationManager.success('Registro creado', 'Éxito', 3000);
        dispatch(cleanForm()); // Limpiar el formulario
        dispatch(push('/transportistas'));
    }).catch(() => {
        NotificationManager.error('Error en la creación', 'ERROR');
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

const editar = (id, data = {}, attachments = []) => (dispatch) => {
    dispatch(setLoader(true));
    api.putAttachments(`${'user'}/${id}`, data, attachments).then(() => {
        NotificationManager.success('Registro actualizado', 'Éxito', 3000);
        dispatch(push('/transportistas_validados'));
    }).catch(() => {
        NotificationManager.error('Error en la edición', 'ERROR', 3000);
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

const eliminar = id => (dispatch) => {
    dispatch(setLoader(true));
    api.eliminar(`${'user'}/${id}`).then(() => {
        dispatch(listarValidados());
        NotificationManager.success('Registro eliminado', 'Éxito', 3000);
    }).catch(() => {
        NotificationManager.error('Error en la transacción', 'ERROR', 3000);
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const cleanForm = () => (dispatch) => {
    dispatch(initializeForm('EditarTransportistaForm', {}));
};

const searchChange = search => (dispatch) => {
    dispatch(setSearch(search));
    dispatch(listarValidados());
};

const onSortChange = ordering => (dispatch, getStore) => {
    const sort = getStore().transportistas.ordering;
    if (ordering === sort) {
        dispatch(setOrdering(`-${ordering}`));
    } else {
        dispatch(setOrdering(ordering));
    }
    // dispatch(listar());
};

const aprobarPendiente = id => (dispatch) => {
    dispatch(setLoader(true));
    api.put(`${'user'}/${id}/validar_transportista`).then(() => {
        // dispatch(listar());
        dispatch(listarPendientesValidar());
        dispatch(push('/transportistas'));
        NotificationManager.success('Transportista aprobado', 'Éxito', 3000);
    }).catch(() => {
        NotificationManager.error('Error en la transacción', 'ERROR', 3000);
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

const rechazarPendiente = (id, justificacion = null) => (dispatch) => {
    const data = {}
    if (justificacion) {
        data.justificacion = justificacion;
    }
    dispatch(setLoader(true));
    api.put(`${'user'}/${id}/rechazar_transportista`, data).then(() => {
        // dispatch(listar());
        dispatch(listarPendientesValidar());
        dispatch(push('/transportistas'));
        NotificationManager.success('Transportista rechazado y eliminado', 'Éxito', 3000);
    }).catch(() => {
        NotificationManager.error('Error en la transacción', 'ERROR', 3000);
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

const clearSelectPais = () => (dispatch) => {
    dispatch(change("EditarTransportistaForm", "ciudad_residencia", null));
}
const setArchivos = (polizas_nuevos) => (dispatch, getStore) => {
    const FORM_PRINCIPAL = getStore().form.EditarTransportistaForm.values;
    let form_principal = FORM_PRINCIPAL ? _.cloneDeep(FORM_PRINCIPAL) : {};

    let form_polizas = form_principal.polizas ? _.cloneDeep(form_principal.polizas) : [];
    for (let index = 0; index < form_polizas.length; index++) {
        if (polizas_nuevos[index] != "x" && polizas_nuevos[index]) {
            form_polizas[index].imagen = polizas_nuevos[index]
        }

    }
    form_principal.polizas = form_polizas
    dispatch(initializeForm('EditarTransportistaForm', form_principal));
}


export const actions = {
    listarPilotos,
    leer,
    crear,
    editar,
    eliminar,
    searchChange,
    onSortChange,
    // desactivar,
    // activar,
    cleanForm,
    listarPendientesValidar,
    aprobarPendiente,
    rechazarPendiente,
    listarValidados,
    clearSelectPais,
    setArchivos,
    listarComentariosTransportista,
};

// ------------------------------------
// Reducers
// ------------------------------------

export const reducers = {
    [LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    [DATA]: (state, { data }) => {
        return {
            ...state,
            data,
        };
    },
    [ITEM_DATA]: (state, { item }) => {
        return {
            ...state,
            item,
        };
    },
    [PAGE]: (state, { page }) => {
        return {
            ...state,
            page,
        };
    },
    [ORDERING]: (state, { ordering }) => {
        return {
            ...state,
            ordering,
        };
    },
    [SEARCH]: (state, { search }) => {
        return {
            ...state,
            search,
        };
    },
    [SEARCH]: (state, { search }) => {
        return {
            ...state,
            search,
        };
    },
    [SET_COMENTARIOS]: (state, { comentarios }) => {
        return {
            ...state,
            comentarios,
        };
    },
};

export const initialState = {
    loader: false,
    data: {
        results: [],
        count: 0,
    },
    item: {},
    page: 1,
    ordering: '',
    search: '',
    comentarios: []
};

export default handleActions(reducers, initialState);
