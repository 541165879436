import { createAction, handleActions } from 'redux-actions';
import { api } from "api";
import { initialize as initializeForm } from "redux-form";
import { NotificationManager } from "react-notifications";
import { push } from "react-router-redux";
import _ from "lodash";
import { reset } from 'redux-form';
import { USER_ADMIN } from "../../../utility/constants";
import moment from 'moment';

const LOADER = 'LOADER_PANEL_FINANCIERO';
const DATA = 'DATA_PANEL_FINANCIERO';
const ITEM_DATA = 'ITEM_PANEL_FINANCIERO';
const PAGE = 'PAGE_PANEL_FINANCIERO';
const TOTALES = 'TOTALES_PANEL_FINANCIERO';
const ID_TRANSPORTISTA = 'ID_TRANSPORTISTA_PANEL_FINANCIERO';
// FILTROS
const FILTRO_FECHA_INICIO_MOVIMIENTOS_CUENTA = 'FILTRO_FECHA_INICIO_MOVIMIENTOS_CUENTA';
const FILTRO_FECHA_FIN_MOVIMIENTO_CUENTA = 'FILTRO_FECHA_FIN_MOVIMIENTO_CUENTA';

// -----------------------------------
// Pure Actions
// -----------------------------------

const setLoader = loader => ({
    type: LOADER,
    loader,
});

const setData = data => ({
    type: DATA,
    data,
});

const setItem = item => ({
    type: ITEM_DATA,
    item,
});

const setPage = page => ({
    type: PAGE,
    page,
});
const setTotales = totales => ({
    type: TOTALES,
    totales,
});

// ------------------------------------
// Actions
// ------------------------------------
const listarMovimientosReporte = (page = 1) => (dispatch, getStore) => {
    const resource = getStore().panelFinanciero;
    const params = { page, transportista: resource.id_transportista };
    if (resource.filtro_fecha_inicio && resource.filtro_fecha_fin) {
        params.fecha_inicio = moment(resource.filtro_fecha_inicio).format('YYYY-MM-DD');
        params.fecha_fin = moment(resource.filtro_fecha_fin).format('YYYY-MM-DD');
    }

    dispatch(setLoader(true));
    api.get('cuenta_pago/mis_movimientos', params).then((response) => {
        dispatch(setData(response));
        dispatch(setPage(page));
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};
const realizarRetiroMonetario = data => (dispatch, getStore) => {
    const resource = getStore().panelFinanciero;
    dispatch(setLoader(true));

    api.post('cuenta_pago/retiro_monetario_transportista', data).then(() => {
        NotificationManager.success('Retiro realizado con éxito', 'Éxito', 3000);
    }).catch((error) => {
        let mensaje = 'Error al realizar el retiro';
        if (error) {
            if (error.detail)
                mensaje = error.detail;
        }
        NotificationManager.error(mensaje, 'ERROR', 4000);
    }).finally(() => {
        dispatch(setLoader(false));
        dispatch(push(`/panel-financiero-transportista/${resource.id_transportista}`));
    });

};

const datoMiCuenta = () => (dispatch, getStore) => {
    const resource = getStore().panelFinanciero;
    const params = { transportista: resource.id_transportista };
    if (resource.filtro_fecha_inicio && resource.filtro_fecha_fin) {
        params.fecha_inicio = moment(resource.filtro_fecha_inicio).format('YYYY-MM-DD');
        params.fecha_fin = moment(resource.filtro_fecha_fin).format('YYYY-MM-DD');
    }
    if (resource.filtro_contratista) {
        params.contratista = resource.filtro_contratista.id;
    }
    if (resource.filtro_estado && resource.filtro_estado.value > 0) {
        params.estado = resource.filtro_estado.value;
    }

    dispatch(setLoader(true));
    api.get('cuenta_pago/mi_cuenta', params).then((response) => {
        dispatch(setTotales(response));
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const changeFechaInicio = filtro_fecha_inicio => (dispatch) => {
    dispatch({ type: FILTRO_FECHA_INICIO_MOVIMIENTOS_CUENTA, filtro_fecha_inicio });
    dispatch(listarMovimientosReporte())
};

const changeFechaFinal = filtro_fecha_fin => (dispatch) => {
    dispatch({ type: FILTRO_FECHA_FIN_MOVIMIENTO_CUENTA, filtro_fecha_fin });
    dispatch(listarMovimientosReporte())
};


const setIdTransportista = id_transportista => (dispatch) => {
    dispatch({ type: ID_TRANSPORTISTA, id_transportista });
    dispatch(datoMiCuenta())
    dispatch(listarMovimientosReporte())
};


export const actions = {
    changeFechaInicio,
    changeFechaFinal,
    realizarRetiroMonetario,
    listarMovimientosReporte,
    datoMiCuenta,
    setIdTransportista,
};

// ------------------------------------
// Reducers
// ------------------------------------

export const reducers = {
    [LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    [DATA]: (state, { data }) => {
        return {
            ...state,
            data,
        };
    },
    [ITEM_DATA]: (state, { item }) => {
        return {
            ...state,
            item,
        };
    },
    [PAGE]: (state, { page }) => {
        return {
            ...state,
            page,
        };
    },
    [TOTALES]: (state, { totales }) => {
        return {
            ...state,
            totales,
        };
    },
    // FILTROS
    [FILTRO_FECHA_INICIO_MOVIMIENTOS_CUENTA]: (state, { filtro_fecha_inicio }) => {
        return {
            ...state,
            filtro_fecha_inicio,
        };
    },
    [FILTRO_FECHA_FIN_MOVIMIENTO_CUENTA]: (state, { filtro_fecha_fin }) => {
        return {
            ...state,
            filtro_fecha_fin,
        };
    },
    [ID_TRANSPORTISTA]: (state, { id_transportista }) => {
        return {
            ...state,
            id_transportista,
        };
    },
};

export const initialState = {
    loader: false,
    data: {
        results: [],
        count: 0,
    },
    item: {},
    totales: {},
    page: 1,
    filtro_fecha_inicio: null,
    filtro_fecha_fin: moment().endOf('month'),
    id_transportista: 0
};

export default handleActions(reducers, initialState);
