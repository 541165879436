import { validators, combine, validatorFromFunction } from 'validate-redux-form';
const noSpace = validatorFromFunction(value => value && value.trim())

const username = {
    Combine: combine(
        validators.exists()('Campo requerido.'),
        validators.regex(/^\S+$/)('No puede contener espacios'),
        validators.length({ min: 3 })('Mínimo 3 carácteres'),
        validators.length({ max: 100 })('Máximo 100 carácteres'),
    ),
    MaxLength: 101,
}

const nombre = {
    Combine: combine(
        noSpace()('Campo requerido.'),
        validators.exists()('Campo requerido.'),
        validators.regex(/^[a-zA-ZÀ-ž\s]+$/)('Sólo se permiten letras'),
        validators.length({ min: 3 })('Mínimo 3 carácteres'),
        validators.length({ max: 250 })('Máximo 250 carácteres'),
    ),
    MaxLength: 251,
}

const email = {
    Combine: combine(
        validators.exists()('Campo requerido.'),
        validators.regex(/^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@+[a-zA-Z0-9-]{1,}\.{1}[a-zA-Z0-9-]{2,}$/)('El correo electrónico no es valido'),
        validators.length({ max: 100 })('Máximo 100 carácteres'),
    ),
    MaxLength: 101,
}

const telefono = {
    Combine: combine(
        validators.exists()('Campo requerido.'),
        validators.regex(/^[+]?([0-9]+)$/)('Número de teléfono no válido'),
        validators.length({ min: 8 })('Mínimo 8 dígitos'),
        validators.length({ max: 30 })('Máximo 30 dígitos'),
    ),
    MaxLength: 31,
}

const no_licencia = {
    Combine: combine(
        noSpace()('Campo requerido.'),
        validators.exists()('Campo requerido.'),
        validators.length({ min: 3 })('Mínimo 3 carácteres'),
        validators.length({ max: 30 })('Máximo 30 carácteres'),
    ),
    MaxLength: 31,
}

const nit = {
    Combine: combine(
        noSpace()('Campo requerido.'),
        validators.exists()('Campo requerido.'),
        validators.regex(/^(CF)$|^(C\.F\.)$|^(C\. F\.)$|^(C\/F)$|^(CONSUMIDOR FINAL)$|(^[+]?([0-9]+))$/gi)('Número de NIT no válido'),
        validators.length({ max: 30 })('Máximo 30 dígitos')
    ),
    MaxLength: 31
}

const numeroLetro50 = {
    Combine: combine(
        noSpace()('Campo requerido.'),
        validators.exists()('Campo requerido.'),
        validators.regex(/^[a-zA-ZÀ-ž0-9\s]+$/)('Sólo se permiten letras y números'),
        validators.length({ max: 50 })('Máximo 50 carácteres')
    ),
    MaxLength: 51,
}

const requerido = {
    Combine: combine(
        validators.exists()('Campo requerido.'),
    ),
    MaxLength: undefined,
}

const patente_img = requerido;
const rtu = numeroLetro50;
const descripcion = requerido;
const pais_residencia = requerido;
const ciudad_residencia = requerido;

// Polizas - transportistas
const polizaNombre = numeroLetro50;
const polizaNumero = numeroLetro50;
// vehiculos - transportistas
const vehiculosCapacidad = numeroLetro50;
const vehiculosVehiculo = requerido;
const vehiculosRefrigeracion = requerido;

export const validationTransportista = {
    nombre,
    email,
    telefono,
    descripcion,
    username,
    pais_residencia,
    ciudad_residencia,
    nit,
    patente_img,
    rtu,
    // polizas
    polizaNombre,
    polizaNumero,
    // vehiculos
    vehiculosCapacidad,
    vehiculosVehiculo,
    vehiculosRefrigeracion,
}

export const validationPiloto = {
    nombre,
    username,
    nit,
    email,
    no_licencia,
    telefono,
}

export const validationContratista = {
    nombre,
    username,
    nit,
    email,
    rtu, //contratista empresas tiene Rtu
    telefono,
}

export const validationAdministrador = {
    nombre,
    username,
    email,
    telefono,
}

export const validationOtros = {
    requerido,
    numeroLetro50,
}
