import { connect } from 'react-redux';
import { actions } from '../../../../../redux/modules/contratistas';
import ListadoValidados from './ListadoValidados';


const ms2p = (state) => {
    return {
        ...state.contratistas,
    };
};

const md2p = { ...actions };

export default connect(ms2p, md2p)(ListadoValidados);
