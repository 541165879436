import React, {Fragment} from 'react';
import { Link } from 'react-router-dom';
import { validators } from 'validate-redux-form';
import { Field, reduxForm, formValueSelector, } from 'redux-form';
import { renderField, renderSwitch, SelectField ,renderNumber,renderCurrency} from '../../../Utils/renderField/renderField';

let ConfigGlobalForm = props => {
    const { handleSubmit } = props;
    return(
        <form onSubmit={handleSubmit}>
            <div className="form-group grid-container col-12 px-0 px-sm-4">
                <div className="row  col-md-12 p-0">
                    <div className="col-12 p-0">
                        <div className="col-md-12 col-sm-12">
                            <label className="h5 m-0 mb-2">Porcentajes</label>
                        </div>
                    </div>
                    <div className="col-md-6 p-0">
                        <div className="col-md-12 col-sm-12">
                            <label htmlFor="porcentaje_viajes_cortos" className="m-0">Porcentaje viajes cortos</label>
                        </div>
                        <div className="col-md-12  form-group">
                            <Field name="porcentaje_viajes_cortos" component={renderNumber} suffix=" %" decimalScale={2} type="text" className="form-control" />
                        </div>
                    </div>
                    <div className="col-md-6 p-0">
                        <div className="col-md-12 col-sm-12">
                            <label htmlFor="porcentaje_viajes_largos" className="m-0">Porcentaje viajes largos</label>
                        </div>
                        <div className="col-md-12  form-group">
                            <Field name="porcentaje_viajes_largos" component={renderNumber} suffix=" %" decimalScale={2} type="text" className="form-control" />
                        </div>
                    </div>
                    <div className="col-md-6 p-0">
                        <div className="col-md-12 col-sm-12">
                            <label htmlFor="porcentaje_comision_transportista" className="m-0">Porcentaje comision trasportista</label>
                        </div>
                        <div className="col-md-12  form-group">
                            <Field name="porcentaje_comision_transportista" component={renderNumber} suffix=" %" decimalScale={2} type="text" className="form-control" />
                        </div>
                    </div>
                </div>
                <div className="row  col-md-12 p-0">
                    <div className="col-12 p-0">
                        <div className="col-md-12 col-sm-12">
                            <label className="h5 m-0 mb-2 mt-3">Precio de la suscripcion del contratista </label>
                        </div>
                    </div>
                    <div className="col-md-6 p-0">
                        <div className="col-md-12 col-sm-12">
                            <label htmlFor="gratis" className="m-0">Gratis:</label>
                        </div>
                        <div className="col-md-12  form-group">
                            <label htmlFor="gratis" className="m-0">$ 0.00</label>
                        </div>
                    </div>
                    <div className="col-md-6 p-0">
                        <div className="col-md-12 col-sm-12">
                            <label htmlFor="precio_mensual_contratista" className="m-0">Mensual</label>
                        </div>
                        <div className="col-md-12  form-group">
                            <Field
                                    name="precio_mensual_contratista"
                                    prefix="$ "
                                    component={renderCurrency}
                                />
                        </div>
                    </div>
                    <div className="col-md-6 p-0">
                        <div className="col-md-12 col-sm-12">
                            <label htmlFor="precio_semestral_contratista" className="m-0">Semestral</label>
                        </div>
                        <div className="col-md-12  form-group">
                            <Field
                                    name="precio_semestral_contratista"
                                    prefix="$ "
                                    component={renderCurrency}
                                />
                        </div>
                    </div>
                    <div className="col-md-6 p-0">
                        <div className="col-md-12 col-sm-12">
                            <label htmlFor="precio_anual_contratista" className="m-0">Anual</label>
                        </div>
                        <div className="col-md-12  form-group">
                            <Field
                                    name="precio_anual_contratista"
                                    prefix="$ "
                                    component={renderCurrency}
                                />
                        </div>
                    </div>
                </div>
                <div className="row  col-md-12 p-0">
                    <div className="col-12 p-0">
                        <div className="col-md-12 col-sm-12">
                            <label className="h5 m-0 mb-2 mt-3">Precio de la suscripcion del transportista </label>
                        </div>
                    </div>
                    <div className="col-md-6 p-0">
                        <div className="col-md-12 col-sm-12">
                            <label htmlFor="gratis" className="m-0">Gratis:</label>
                        </div>
                        <div className="col-md-12  form-group">
                            <label htmlFor="gratis" className="m-0">$ 0.00</label>
                        </div>
                    </div>
                    <div className="col-md-6 p-0">
                        <div className="col-md-12 col-sm-12">
                            <label htmlFor="precio_mensual_transportista" className="m-0">Mensual</label>
                        </div>
                        <div className="col-md-12  form-group">
                            <Field
                                    name="precio_mensual_transportista"
                                    prefix="$ "
                                    component={renderCurrency}
                                />
                        </div>
                    </div>
                    <div className="col-md-6 p-0">
                        <div className="col-md-12 col-sm-12">
                            <label htmlFor="precio_semestral_transportista" className="m-0">Semestral</label>
                        </div>
                        <div className="col-md-12  form-group">
                            <Field
                                    name="precio_semestral_transportista"
                                    prefix="$ "
                                    component={renderCurrency}
                                />
                        </div>
                    </div>
                    <div className="col-md-6 p-0">
                        <div className="col-md-12 col-sm-12">
                            <label htmlFor="precio_anual_transportista" className="m-0">Anual</label>
                        </div>
                        <div className="col-md-12  form-group">
                            <Field
                                    name="precio_anual_transportista"
                                    prefix="$ "
                                    component={renderCurrency}
                                />
                        </div>
                    </div>
                </div>
                <div className="row  col-md-12 p-0">
                    <div className="col-12 p-0">
                        <div className="col-md-12 col-sm-12">
                            <label className="h5 m-0 mb-2 mt-3">Tasa de cambio</label>
                        </div>
                    </div>
                    <div className="col-md-6 p-0">
                        <div className="col-md-12 col-sm-12">
                            <label htmlFor="precio_anual_transportista" className="m-0">Precio del dolar ($)</label>
                        </div>
                        <div className="col-md-12  form-group">
                            <Field
                                    name="tasa_cambio"
                                    prefix="$ "
                                    component={renderCurrency}
                                />
                        </div>
                    </div>
                </div>
                <div className="d-flex flex-wrap justify-content-center align-items-center mt-5">
                    <div className="col-md-4 col-12">
                        <Link className="btn btn-dark btn-block" to="/">Cancelar</Link>
                    </div>
                    <div className="col-md-4 col-12 mt-md-0 mt-3">
                        <button type="submit" className="btn btn-primary btn-block">Guardar</button>
                    </div>
                </div>
            </div>
        </form>
    )
};


const validate = (values) => {
    const errors = {};
    if(!values.precio_mensual_contratista){
        errors.precio_mensual_contratista = "Campo requerido";
    }
    if (values.precio_mensual_contratista < 1) {
        errors.precio_mensual_contratista = "El monto debe ser mayor a 0 ";
    }
    if(!values.precio_semestral_contratista){
        errors.precio_semestral_contratista = "Campo requerido";
    }
    if (values.precio_semestral_contratista < 1) {
        errors.precio_semestral_contratista = "El monto debe ser mayor a 0 ";
    }
    if(!values.precio_anual_contratista){
        errors.precio_anual_contratista = "Campo requerido";
    }
    if (values.precio_anual_contratista < 1) {
        errors.precio_anual_contratista = "El monto debe ser mayor a 0 ";
    }
    if(!values.precio_mensual_transportista){
        errors.precio_mensual_transportista = "Campo requerido";
    }
    if (values.precio_mensual_transportista < 1) {
        errors.precio_mensual_transportista = "El monto debe ser mayor a 0 ";
    }
    if(!values.precio_semestral_transportista){
        errors.precio_semestral_transportista = "Campo requerido";
    }
    if (values.precio_semestral_transportista < 1) {
        errors.precio_semestral_transportista = "El monto debe ser mayor a 0 ";
    }
    if(!values.precio_anual_transportista){
        errors.precio_anual_transportista = "Campo requerido";
    }
    if (values.precio_anual_transportista < 1) {
        errors.precio_anual_transportista = "El monto debe ser mayor a 0 ";
    }
    if(!values.porcentaje_viajes_cortos){
        errors.porcentaje_viajes_cortos = "Campo requerido";
    }

    if (values.porcentaje_viajes_cortos < 0) {
        errors.porcentaje_viajes_cortos = "El porcentaje debe ser un número positivo ";
    }
    if(!values.porcentaje_viajes_largos){
        errors.porcentaje_viajes_largos = "Campo requerido";
    }

    if (values.porcentaje_viajes_largos < 0) {
        errors.porcentaje_viajes_largos = "El porcentaje debe ser un número positivo ";
    }
    if(!values.porcentaje_comision_transportista){
        errors.porcentaje_comision_transportista = "Campo requerido";
    }
    if (values.porcentaje_comision_transportista < 0) {
        errors.porcentaje_comision_transportista = "El porcentaje debe ser un número positivo ";
    }
    if(!values.tasa_cambio){
        errors.tasa_cambio = "Campo requerido";
    }

    if (values.tasa_cambio < 0) {
        errors.tasa_cambio = "El porcentaje debe ser un número positivo ";
    }

    return errors;
};

ConfigGlobalForm = reduxForm({
    form: 'ConfigGlobalForm',
    validate
})(ConfigGlobalForm);

export default ConfigGlobalForm
