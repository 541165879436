import React, { Component } from 'react';
import { withRouter } from "react-router-dom";
import { Link, NavLink } from "react-router-dom";
import { connect } from 'react-redux';
import { actions } from '../../../../redux/modules/centroNotificaciones'


import { Badge } from 'antd';

import { api } from "api";

import { Menu } from 'antd';
const { SubMenu } = Menu;

class SideBar extends Component {
    constructor(props) {
        super(props);
    }

    rootSubmenuKeys = ['contratista', 'transportista', 'reportes'];

    state = {
        openKeys: [],
    };


    componentDidMount() {
        this.props.comprobarNotificaciones();
    }
    onOpenChange = openKeys => {
        const latestOpenKey = openKeys.find(key => this.state.openKeys.indexOf(key) === -1);
        if (this.rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
            this.setState({ openKeys });
        } else {
            this.setState({
                openKeys: latestOpenKey ? [latestOpenKey] : [],
            });
        }
    };

    navigate = (path) => {
        this.props.navToggle();
        this.props.history.push(path);
    }

    render() {
        const { toggleOpen, navToggle, logOut, comprobarNotificaciones } = this.props;
        const { notificaciones_nuevas, resetNotificacionesNuevas } = this.props;
        return (
            <aside
                className={`main-sidebar px-0 col-12 col-md-3 col-lg-2 ${toggleOpen ? '' : 'open'
                    }`}
            >
                <div className="main-navbar">
                    <a href="/" className="w-100 mr-0 navbar-brand mt-4">
                        <div className="d-table m-auto">
                            <img
                                id="main-logo"
                                className="main-logo img-fluid"
                                height={50}
                                src={require('assets/img/logo.svg')}
                                alt="Logo"
                            />
                        </div>
                    </a>
                    <a
                        className="toggle-sidebar d-sm-inline d-md-none d-lg-none"
                        onClick={navToggle}
                    >
                        <i className="material-icons"></i>
                    </a>
                </div>
                <div className="nav-wrapper w-100 ">
                    <Menu
                        mode="inline"
                        openKeys={this.state.openKeys}
                        onOpenChange={this.onOpenChange}
                        style={{ width: 256 }}
                        subMenuCloseDelay={1}
                    >
                        <Menu.Item key="home" icon={<i className="icon-menu fa fa-home"></i>} onClick={key => this.navigate('/')}>
                            Home
                        </Menu.Item>
                        <Menu.Item key="administradores" icon={<i className="icon-menu fa fa-users"></i>} onClick={key => this.navigate('/administradores')}>
                            Administradores
                        </Menu.Item>
                        <SubMenu key="contratista" icon={<i className="icon-menu fa fa-id-badge"></i>} title="Contratista">
                            <Menu.Item className="" key="contratistas_pendientes" onClick={key => this.navigate('/contratistas')}>Pendientes</Menu.Item>
                            <Menu.Item className="" key="contratistas_validados" onClick={key => this.navigate('/contratistas_validados')}>Validados</Menu.Item>
                        </SubMenu>
                        <SubMenu key="transportista" icon={<i className="icon-menu fa fa-truck"></i>} title="Transportista">
                            <Menu.Item className="" key="transportista_pendientes" onClick={key => this.navigate('/transportistas')}>Pendientes</Menu.Item>
                            <Menu.Item className="" key="transportista_validados" onClick={key => this.navigate('/transportistas_validados')}>Validados</Menu.Item>
                        </SubMenu>
                        <SubMenu key="publicidad" icon={<i className="icon-menu fa fa-tag"></i>} title="Publicidad">
                            <Menu.Item className="" key="publicidades_pendientes" onClick={key => this.navigate('/publicidades')}>Pendientes</Menu.Item>
                            <Menu.Item className="" key="publicidades_validados" onClick={key => this.navigate('/publicidades_validados')}>Validados</Menu.Item>
                        </SubMenu>
                        <Menu.Item key="devoluciones" icon={<i className="icon-menu fa fa-shopping-basket"></i>} onClick={key => this.navigate('/devoluciones')}>
                            Devoluciones
                        </Menu.Item>

                        <Menu.Item key="centro_notificaciones" icon={<i className="icon-menu fa fa-bell" style={{ color: notificaciones_nuevas > 0 && "#7EA04D" }}></i>}
                            onClick={key => {
                                resetNotificacionesNuevas()
                                this.navigate('/centro_notificaciones')
                            }}>
                            {notificaciones_nuevas > 0 ?
                                <span style={{ color: "#7EA04D" }}>
                                    Notificaciones {notificaciones_nuevas}
                                </span> :
                                <span>
                                    Notificaciones
                                </span>
                            }

                        </Menu.Item>
                        <Menu.Item key="tipos-vehiculo" icon={<i className="icon-menu fa fa-car"></i>} onClick={key => this.navigate('/tipos_vehiculo')}>
                            Tipos de vehiculo
                        </Menu.Item>
                        <Menu.Item key="paises" icon={<i className="icon-menu fa fa-flag"></i>} onClick={key => this.navigate('/paises')}>
                            Paises
                        </Menu.Item>
                        <Menu.Item key="rangos-precios" icon={<i className="icon-menu fa fa-money"></i>} onClick={key => this.navigate('/rangos_precios')}>
                            Rango de Precio
                        </Menu.Item>
                        <SubMenu key="reportes" icon={<i className="icon-menu fa fa-signal"></i>} title="Reportes">
                            <Menu.Item className="" key="bitacora" onClick={key => this.navigate('/bitacora')}>Bitácora</Menu.Item>
                            <Menu.Item className="" key="proyecto-ofertas" onClick={key => this.navigate('/reportes/proyecto-ofertas')}>Proyectos y Ofertas</Menu.Item>
                            <Menu.Item className="" key="ofertas-adjudicados" onClick={key => this.navigate('/reportes/ofertas-adjudicados')}>Ofertas vs Adjudicados</Menu.Item>
                            <Menu.Item className="" key="reporte-financiero" onClick={key => this.navigate('/reportes/financiero')}>Reporte Financiero</Menu.Item>
                        </SubMenu>
                        <Menu.Item key="config_globales" icon={<i className="icon-menu fa fa-cog"></i>} onClick={key => this.navigate('/config_global')}>
                            Configuración Global
                        </Menu.Item>
                    </Menu>
                    <div className="text-center py-3 bg-gris">
                        <img
                            className="main-logo img-fluid"
                            height={50}
                            src={require('assets/img/logomenu.png')}
                            alt="Logo"
                        />
                    </div>
                </div>
            </aside>
        );
    }
}


const ShowTheLocationWithRouter = withRouter(SideBar);

const ms2p = (state) => {
    return {
        ...state.centroNotificaciones,
    };
};

const md2p = { ...actions };

export default connect(ms2p, md2p)(ShowTheLocationWithRouter);
