import React, { Component } from 'react'
import Grid from "../../../Utils/Grid";
import Card from "Utils/Cards/Card"
import { TableHeaderColumn } from "react-bootstrap-table";
import { standardActions } from "../../../Utils/Grid/StandardActions";
import Link from "react-router-dom/es/Link";
import Header from "Utils/Grid/Header";
import { RenderDollar } from '../../../Utils/renderField/renderReadField';
export default class UsuariosList extends Component {
    componentWillMount() {
        const { listarValidados} = this.props;
        listarValidados(1);
    }

    render() {
        const { listarValidados: onPageChange, onSortChange, eliminar } = this.props; //Funciones
        const { data, loader, page } = this.props; //Constantes
        return (
            <div className="mb-4 col-12 mt-4">
                <Card titulo="TRANSPORTISTAS VALIDADOS">
                    <Header searchEnd={true} {...this.props} />
                    <Grid hover striped data={data} loading={loader} onPageChange={onPageChange} onSortChange={onSortChange}
                        page={page}>
                        <TableHeaderColumn
                            dataField="id"
                            dataAlign="center"
                            isKey
                            width='15%'
                            dataFormat={standardActions({ editar: "transportista_validado", eliminar, ver: "transportista_validado", estadoFinanciero: "panel-financiero-transportista" })}
                        />
                        <TableHeaderColumn
                            dataField="nombre"
                        >
                            NOMBRE
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="email"
                        >
                            EMAIL
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="username"
                        >
                            USUARIO
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="saldos_transportista"
                            dataFormat={(cell) => {
                                return <RenderDollar value={cell && cell.saldo_total ? cell.saldo_total : 0} />
                            }}
                        >
                            SALDO
                        </TableHeaderColumn>

                        <TableHeaderColumn
                            dataField="saldos_transportista"
                            dataFormat={(cell) => {
                                return <RenderDollar value={cell && cell.puntos_total_dinero ? cell.puntos_total_dinero : 0} />
                            }}
                        >
                            SALDO PUNTOS
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataAlign="left"
                            dataField="id"
                            width='10%'
                        >
                            CÓDIGO
                        </TableHeaderColumn>
                    </Grid>
                </Card>
            </div>
        )
    }
}
