import { connect } from 'react-redux';
import { actions } from '../../../../../redux/modules/rango_precio/rango_precio';
import Crear from './RangoPrecioCrear';

const mstp = state => {
    return {...state.rangos_precios}
};

const mdtp = {
    ...actions
};

export default connect(mstp, mdtp)(Crear)
