import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { RenderCurrency, RenderDollar } from '../renderField/renderReadField';
import { SIN_PROPUESTA, NO_REALIZADOS, YA_REALIZADOS, USER_CONTRATISTA_EMPRESA, USER_CONTRATISTA_INDIVIDUAL, USER_TRANSPORTISTA } from '../../../../utility/constants';
import moment from 'moment';
import { Rate } from 'antd';

moment.locale('es');

const persona_img = require('assets/img/icons/person.svg');

function CardOfertaPequeno(props) {
    const { me, descripcionTransportista, presupuesto, creado, nombreTransportista, avatarTransportista, idOferta, idProyecto, adjudicado} = props;
    return (
        <div className="col-md-12 col-lg-6 col-xl-4 col-12 mb-3">
            <div className="card-proyecto borde-naranja">
                <div className="p-0 px-3 pt-3 d-flex flex-column flex-sm-row ">
                    <img src={avatarTransportista ? avatarTransportista: persona_img } style={{height: "40px"}} alt="Persona" className="img-fluid mr-2"/>
                    <div className="d-flex flex-column">
                        <span className="bold gris-oscuro text-sm mb-0">{nombreTransportista ? nombreTransportista : ""}</span>
                        <Rate disabled defaultValue={me && me.calificacion ? me.calificacion : 1} className="naranja mt-0"/>
                        <span className="mb-1 gris-letras bold">Descripción del perfil transportista: </span>
                        <p className="gris-letras bold parrafo-dos-lineas" >{descripcionTransportista ? descripcionTransportista : ""}   </p>
                        <span className="mb-1 gris-letras bold">Presupuesto <RenderDollar value={presupuesto ? presupuesto : 0} className="gris-oscuro bold"/></span>
                        <span className="gris-letras text-xsm">{`Recibido ${creado ? moment(creado).format('DD [de] MMMM YYYY ') : moment().format('DD [de] MMMM YYYY ')}`}</span>
                    </div>
                </div>
                {!adjudicado &&
                    <div className="p-0 px-3 pt-1">
                        <div className="d-flex flex-wrap justify-content-end align-items-center">
                            <div className="mb-2">
                                <Link className="btn py-1 btn-naranja" to={`/reportes/proyecto/${idProyecto}/${idOferta}/`} >Ver Oferta</Link>
                            </div>
                        </div>
                    </div>
                }
            </div>
        </div>
    )
}
export default CardOfertaPequeno;
