// Tipos de usuarios
export const USER_ADMIN = 10;
export const USER_CONTRATISTA_INDIVIDUAL = 20;
export const USER_CONTRATISTA_EMPRESA = 30;
export const USER_TRANSPORTISTA = 40;

export const tipo_vehiculo = [
    { value: 10, label: "Camión" },
    { value: 20, label: "Tráiler" },
    { value: 30, label: "Panel" },
    { value: 40, label: "Furgón" },
    { value: 50, label: "Chasis" },
    { value: 60, label: "Plataforma" },
    { value: 70, label: "Lowboys" },
];

export const tipo_refrigeracion = [
    { value: 10, label: "Refrigerado" },
    { value: 20, label: "Seco" },
];

// Estados del proyecto
export const EN_RECEPCION_OFERTAS = 10;
export const ADJUDICADO_PENDIENTE_DE_INICIAR = 20;
export const EN_CURSO = 30;
export const FINALIZADO = 40;
export const BORRADOR = 50;
export const VENCIDO = 100;

export const ESTADO_PROYECTO = [
    { color: "#7EA04D", value: EN_RECEPCION_OFERTAS, label: "En recepción", title: "PROYECTO EN RECEPCIÓN" },
    { color: "#FA7D09", value: ADJUDICADO_PENDIENTE_DE_INICIAR, label: "Adjudicados", title: "PROYECTO ADJUDICADO" },
    { color: "#10426D", value: EN_CURSO, label: "En curso", title: "PROYECTO EN CURSO" },
    { color: "#323232", value: FINALIZADO, label: "Finalizados", title: "PROYECTO FINALIZADO" },
    { color: "#804000", value: BORRADOR, label: "Borrador", title: "PROYECTO BORRADOR" },
    { color: "#D92027", value: VENCIDO, label: "Vencidos", title: "PROYECTO VENCIDO" },
];

//estado de publicidad

export const EN_EDICION = 10
export const EN_REVISION = 20
export const VALIDADA = 30
export const RECHAZADA = 40

//Tipo de movimiento, detalle de movimiento
export const INGRESO = 10
export const EGRESO = 20

//Estados de Devoluciones
export const DEVOLUCION_PENDIENTE = 10
export const DEVOLUCION_REALIZADA = 20

// Cambiar estilo para los Swal,
import Swal from 'sweetalert2';
export const SWALMOD = Swal.mixin({
    customClass: {
        confirmButton: 'btn btn-naranja m-2',
        cancelButton: 'btn btn-gris-oscuro m-2',
        title: 'text-azul-alerta',
        image: 'blink-danger',
        validationMessage: 'my-validation-message'
    },
    preConfirm: (value) => {
      if (!value) {
        SWALMOD.showValidationMessage(
          'La justificación es importante.'
        )
      }
    },
    buttonsStyling: false,
    focusConfirm: false,
    focusCancel: false,
})

export const SWALMODERROR = Swal.mixin({
    customClass: {
        confirmButton: 'btn btn-gris-oscuro m-2',
        title: 'text-azul-alerta',
        image: 'blink-danger',
    },
    buttonsStyling: false,
    focusConfirm: false,
    focusCancel: false,
})
