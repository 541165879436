import { combineReducers } from 'redux';
import { routerReducer as routing } from 'react-router-redux';
import { reducer as formReducer } from 'redux-form';
import login from './modules/cuenta/login';
import register from './modules/cuenta/register';
import profile from './modules/cuenta/profile';
import usuarios from './modules/usuarios/usuarios';
import notificaciones from './modules/notificaciones/notificaciones';

// Reducers de la aplicación
import administradores from './modules/administradores';
import config_globales from './modules/config_global/config_global';
import contratistas from './modules/contratistas';
import transportistas from './modules/transportistas';
import tipos_vehiculo from './modules/tipo_vehiculo/tipo_vehiculo';
import paises from './modules/paises/paises';
import rangos_precios from './modules/rango_precio/rango_precio';
import proyectos from './modules/proyectos';
import publicidades from './modules/publicidades';
// Reducers de los reportes
import proyectosYOfertas from './modules/reportes/proyectosYOfertas'
import ofertasYAdjudicados from './modules/reportes/ofertasYAdjudicados'
import financiero from './modules/reportes/financiero'
// Panel financiero del transportista
import panelFinanciero from './modules/panelFinanciero'
import devoluciones from './modules/devoluciones'
// Bitacora
import bitacora from './modules/bitacora';
// CENTRO DE NOTIFICACION
import centroNotificaciones from './modules/centroNotificaciones';

// Dashboard
import dashboard from './modules/dashboard'

export default combineReducers({
    form: formReducer,
    login,
    register,
    profile,
    usuarios,
    routing,
    notificaciones,
    // Reducers de la aplicación
    administradores,
    contratistas,
    transportistas,
    tipos_vehiculo,
    paises,
    config_globales,
    rangos_precios,
    proyectos,
    publicidades,
    bitacora,
    dashboard,
    // Reducers de los reportes
    proyectosYOfertas,
    ofertasYAdjudicados,
    panelFinanciero,
    devoluciones,
    centroNotificaciones,
    financiero,
});
