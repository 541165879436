import React from 'react';
import './alerta.css'
const alerta_img = require('assets/img/icons/alert.svg');

const Alerta = ({texto, danger, noMarginBottom}) =>(
    <div className={`alerta-container ${noMarginBottom ? ' mb-0' : ''}`}>
        <img src={alerta_img} alt="Alerta" className={`mr-1 title_img blink-danger`}/>
        <span className={!danger ? "alerta-text" : "alerta-danger-text"}>{texto}</span>
    </div>
);

export default Alerta;
