import { handleActions } from 'redux-actions';
import { push } from 'react-router-redux';
import { initialize as initializeForm } from 'redux-form';
import { NotificationManager } from 'react-notifications';
import { api } from 'api';

const SUBMIT = 'LOGIN_SUBMIT';
const LOADER = 'LOGIN_LOADER';
const ME = 'LOGIN_ME';

export const constants = {
    SUBMIT,
};

// ------------------------------------
// Pure Actions
// ------------------------------------

export const setLoader = (loader) => ({
    type: LOADER,
    loader,
});

export const setMe = (me) => ({
    type: ME,
    me,
});

// ------------------------------------
// Actions
// ------------------------------------

export const onSubmit = (data = {}) => (dispatch, getStore) => {
    dispatch(setLoader(true));
    api.post('user/login', { ...data, es_admin: true })
        .then((response) => {
            localStorage.setItem('token', response.token);
            dispatch(initializeForm('profile', response.user));
            dispatch(setMe(response.user));
            dispatch(push('/'));
        })
        .catch((error) => {
            let mensaje = 'Credenciales incorrectas, vuelva a intentar';
            if (error) {
                if (error.detail)
                    mensaje = error.detail;
            }
            NotificationManager.error(mensaje, 'ERROR', 5000);
        })
        .finally(() => {
            dispatch(setLoader(false));
        });
};

export const getMe = () => (dispatch) => {
    api.get('/user/me')
        .then((me) => {
            dispatch(initializeForm('profile', me));
            dispatch(setMe(me));
        })
        .catch(() => { })
        .finally(() => { });
};

export const logOut = () => (dispatch) => {
    api.post('/user/logout')
        .then(() => { })
        .catch(() => { })
        .finally(() => { });
    localStorage.removeItem('token');
};

export const newToken = (token) => (dispatch) => {
    const body = { token };
    api.post('fcm', body).catch((error) => { }).then((data) => {
        // console.log('Se registro el token')
    });
};

export const changePassword = (data = {}) => (dispatch) => {
    dispatch(setLoader(true));
    api.post('user/change_password', data).then((response) => {
        NotificationManager.success('Tu contraseña ha sido actualizada.');
        dispatch(setMe(response));
        dispatch(push("/"));
    }).catch(() => {
        NotificationManager.error('Error al modificar la contraseña, vuelva a intentar', 'ERROR', 0);
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

export const sendRecover = (data) => (dispatch) => {
    const username = data.username;
    dispatch(setLoader(true));
    api.post('user/pass_reset_mail', { username, es_admin: true }).then(res => {
        NotificationManager.success('El correo para recuperar la contraseña ha sido enviada, por favor revisa tu correo');
        dispatch(push("/"));
    }).catch(error => {
        let mensaje = 'A ocurrido un error al enviar el correo para recuperar la contraseña, por favor intenta de nuevo.';
        if (error) {
            if (error.detail)
                mensaje = error.detail;
        }
        NotificationManager.error(mensaje, 'ERROR', 0);
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

export const resetPassword = (data) => (dispatch) => {
    dispatch(setLoader(true));
    api.post('user/pass_reset', data).then(res => {
        NotificationManager.success('Tu contraseña ha sido reestablecida correctamente');
        dispatch(push('/login'));
    }).catch(err => {
        NotificationManager.error((err && err.detail) ? err.detail : 'Ocurrió un error en el reseteo de contraseña, por favor intente de nuevo.', 'ERROR');
        dispatch(push('/login'));
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

export const actions = {
    onSubmit,
    logOut,
    changePassword,
    sendRecover,
    resetPassword
};

export const reducers = {
    [LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    [ME]: (state, { me }) => {
        return {
            ...state,
            me,
        };
    },
};

export const initialState = {
    loader: false,
    me: {},
};

export default handleActions(reducers, initialState);
