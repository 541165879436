import React, { Component, Fragment } from "react";
import Card from "Utils/Cards/Card";
import LoadMask from "Utils/LoadMask/LoadMask";
import { Link, Redirect } from "react-router-dom";
import moment from 'moment';
import { ESTADO_PROYECTO } from '../../../../../utility/constants';

import CardOfertaAdjudicar from "../../../Utils/Cards/CardOfertaAdjudicar";
import CardGrandeProyecto from "../../../Utils/Cards/CardGrandeProyecto";


export default class DetalleOferta extends Component {

    componentWillMount() {
        this.props.leer(this.props.match.params.idProyecto);
        this.props.ofertaDetalle(this.props.match.params.idOferta);
    }

    estilo = (tipo) => {
        if (tipo) {
            const item = _.find(ESTADO_PROYECTO, { value: tipo });
            return item.color
        }
        return "red"
    }

    render() {
        const { item, loader, oferta_detalle } = this.props;

        return (
            <div className="mt-4 col-12">
                {item.adjudicado && <Redirect to={`/proyecto/${item.id}`} />}
                <Card titulo="DETALLE OFERTA">
                    <LoadMask loading={loader} blur>
                        <div className="p-0 pb-3  p-sm-5">

                            <CardGrandeProyecto
                                nombre={item && item.nombre ? item.nombre : ""}
                                direccion_origen={item && item.direccion_origen ? item.direccion_origen : ""}
                                ciudad_origen={item && item.ciudad_origen && item.ciudad_origen.nombre ? item.ciudad_origen.nombre : ""}
                                pais_origen={item && item.pais_origen && item.pais_origen.nombre ? item.pais_origen.nombre : ""}
                                direccion_destino={item && item.direccion_destino ? item.direccion_destino : ""}
                                ciudad_destino={item && item.ciudad_destino && item.ciudad_destino.nombre ? item.ciudad_destino.nombre : ""}
                                pais_destino={item && item.pais_destino && item.pais_destino.nombre ? item.pais_destino.nombre : ""}
                                tipo_vehiculo={item && item.tipo_vehiculo && item.tipo_vehiculo.nombre ? item.tipo_vehiculo.nombre : ""}
                                presupuesto={item && item.presupuesto ? item.presupuesto : 0}
                                saldo_a_favor={item && item.saldo_a_favor ? item.saldo_a_favor : 0}
                                dia_entrega={item && item.dia_entrega ? item.dia_entrega : ""}
                                fecha_cierre_ofertas={item && item.fecha_cierre_ofertas ? item.fecha_cierre_ofertas : ""}
                                fecha_hora_posicionamiento={item && item.fecha_hora_posicionamiento ? item.fecha_hora_posicionamiento : ""}
                                descripcion_trabajo={item && item.descripcion_trabajo ? item.descripcion_trabajo : ""}
                                total_ofertas={item && item.total_ofertas ? item.total_ofertas : 0}
                                notas={item && item.notas ? item.notas : ""}
                                estado_proyecto={item && item.estado_proyecto ? item.estado_proyecto : 0}
                                codigo_tracking={item && item.codigo_tracking ? item.codigo_tracking : null}
                            />
                            <h5 className="mb-1 bold mb-3 mt-5">Oferta</h5>
                            {oferta_detalle && oferta_detalle.proyecto && oferta_detalle.proyecto.id && oferta_detalle.proyecto.id == item.id &&
                                <CardOfertaAdjudicar
                                    key={oferta_detalle.id}
                                    idOferta={oferta_detalle.id}
                                    estadoProyecto={item.estado_proyecto}
                                    idProyecto={item.id}
                                    adjudicado={item.adjudicado}
                                    calificacionTransportista={oferta_detalle.transportista && oferta_detalle.transportista.calificacion ? oferta_detalle.transportista.calificacion : 1}
                                    nombreTransportista={oferta_detalle.transportista && oferta_detalle.transportista.nombre ? oferta_detalle.transportista.nombre : ""}
                                    avatarTransportista={oferta_detalle.transportista && oferta_detalle.transportista.avatar ? oferta_detalle.transportista.avatar : false}
                                    descripcionTransportista={oferta_detalle.transportista && oferta_detalle.transportista.descripcion ? oferta_detalle.transportista.descripcion : ""}
                                    descripcion={oferta_detalle.descripcion ? oferta_detalle.descripcion : ""}
                                    presupuesto={oferta_detalle.presupuesto_oferta ? oferta_detalle.presupuesto_oferta : 0}
                                    creado={oferta_detalle.creado ? oferta_detalle.creado : moment()}
                                    {...this.props}
                                />
                            }
                            <div className="d-flex flex-wrap justify-content-center align-items-center mt-5">
                                <div className="col-md-5 col-lg-5 col-xl-4 col-12">
                                    <Link className="btn btn-dark btn-block" to={`/reportes/proyecto/${item.id}`}>Regresar</Link>
                                </div>
                            </div>
                        </div>
                    </LoadMask>
                </Card>
            </div>
        );
    }
}
