import { api } from "api";
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import SearchInput from './SearchInput';

export default class HeaderReportes extends Component {
    render() {
        const { filtro1, filtro2, filtro3, filtro4 } = this.props;
        const { filtroText1, filtroText2, filtroText3, filtroText4 } = this.props;

        return (
            <div className="card-proyecto mb-3 py-3">
                <div className="d-flex w-100">
                    <div className="d-flex flex-1 flex-column flex-lg-row col-12">
                        <div className={`d-flex flex-column flex-1 mx-1 px-0 my-2 my-lg-0`}>
                            <div className="col-12 px-0 d-flex justify-content-center align-items-center">
                                <span className="gris-oscuro text-center bold text-sm">Filtrar</span>
                            </div>
                        </div>
                        <div className={`d-flex flex-column flex-2 mx-1 px-0 ${filtro1 ? "my-2 my-lg-0" : "d-sm-none d-md-none d-lg-inline"}`}>
                            {(filtroText1 !== undefined) && (
                                <label className="gris-oscuro">{filtroText1}</label>
                            )}
                            {filtro1 && filtro1}
                        </div>
                        <div className={`d-flex flex-column flex-2 mx-1 px-0 ${filtro2 ? "my-2 my-lg-0" : "d-sm-none d-md-none d-lg-inline"}`}>
                            {(filtroText2 !== undefined) && (
                                <label className="gris-oscuro">{filtroText2}</label>
                            )}
                            {filtro2 && filtro2}
                        </div>
                        <div className={`d-flex flex-column flex-2 mx-1 px-0 ${filtro3 ? "my-2 my-lg-0" : "d-sm-none d-md-none d-lg-inline"}`}>
                            {(filtroText3 !== undefined) && (
                                <label className="gris-oscuro">{filtroText3}</label>
                            )}
                            {filtro3 && filtro3}
                        </div>
                        <div className={`d-flex flex-column flex-2 mx-1 px-0 ${filtro4 ? "my-2 my-lg-0" : "d-sm-none d-md-none d-lg-inline"}`}>
                            {(filtroText4 !== undefined) && (
                                <label className="gris-oscuro">{filtroText4}</label>
                            )}
                            {filtro4 && filtro4}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
