import { connect } from 'react-redux';
import { actions } from '../../../../../redux/modules/tipo_vehiculo/tipo_vehiculo';
import List from './TiposVehiculo';


const ms2p = (state) => {
  return {
    ...state.tipos_vehiculo,
  };
};

const md2p = { ...actions };

export default connect(ms2p, md2p)(List);
