import React, { Fragment } from 'react';


function Card(props) {
    const { noBorder, colorFondo } = props;
    return (
        <Fragment>
            <div className="d-flex align-items-baseline ml-3 mb-3">
                <h3>{props.titulo}</h3>
            </div>
            <div className={`mb-4${noBorder ? '' : ' card-empty card-small'}`}>
                <div className={`mb-4 mb-md-3 card-content p-0 px-3 pt-3 ${colorFondo ? colorFondo : ''}`}>
                    {props.children}
                </div>
            </div>
        </Fragment>

    )
}

export default Card;
