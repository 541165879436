import React, { Fragment, Component } from 'react';
import { Link } from 'react-router-dom';
import { validators } from 'validate-redux-form';
import { Field, reduxForm, formValueSelector, } from 'redux-form';
import { renderField, renderSwitch, SelectField, renderCurrency, AsyncSelectField2 } from '../../../Utils/renderField/renderField';
import { tipo_vehiculo } from '../../../../../utility/constants';
import { connect } from 'react-redux'
import { api } from "api";


const getPaisOrigen = (search) => {
    return api.get("pais/listar_pais", { search }).then((data) => {
        if (data) return data.results;
        return [];
    }).catch(() => {
        return [];
    });
};

const getPaisDestino = (search) => {
    return api.get("pais/listar_pais", { search }).then((data) => {
        if (data) return data.results;
        return [];
    }).catch(() => {
        return [];
    });
};

const getVehiculo = (search) => {
    return api.get("tipo_vehiculo", { search }).then((data) => {
        if (data) return data.results;
        return [];
    }).catch(() => {
        return [];
    });
};


class RangoPrecioForm extends Component {

    state = {
        CiudadOrigen: [],
        CiudadDestino: []
    }
    getCiudadOrigen = (search) => {
        const { pais_origen } = this.props;
        const params = { search }

        if (pais_origen.id) {
            params.pais = pais_origen.id
        }

        return api.get("ciudad", params).then((data) => {
            if (data) {
                this.setState({ CiudadOrigen: data.results });
                return data.results
            };
            return [];
        }).catch(() => {
            return [];
        });
    };
    getCiudadDestino = (search) => {
        const { pais_destino } = this.props;
        const params = { search }

        if (pais_destino.id) {
            params.pais = pais_destino.id
        }

        return api.get("ciudad", params).then((data) => {
            if (data) {
                this.setState({ CiudadDestino: data.results });
                return data.results
            };
            return [];
        }).catch(() => {
            return [];
        });
    };

    componentDidUpdate(prevProps) {
        if (this.props.pais_origen !== prevProps.pais_origen) {
            this.getCiudadOrigen('');
        }
        if (this.props.pais_destino !== prevProps.pais_destino) {
            this.getCiudadDestino('');
        }
    }

    render() {
        const { handleSubmit, clearSelectPais } = this.props;
        return (
            <form onSubmit={handleSubmit}>
                <div className="form-group grid-container col-12 px-0 px-sm-4">
                    <div className="row  col-md-12 p-0">
                        <div className="col-md-6 p-0">


                            <div className="col-md-12 col-sm-12">
                                <label htmlFor="pais_origen" className="m-0">País Origen*</label>
                            </div>
                            <div className="col-md-12  form-group">
                                <Field
                                    valueKey="id"
                                    name="pais_origen"
                                    labelKey="nombre"
                                    label="nombre"
                                    component={AsyncSelectField2}
                                    loadOptions={getPaisOrigen}
                                    onCambio={() => clearSelectPais("ciudad_origen")}
                                />
                            </div>


                        </div>
                        <div className="col-md-6 p-0">


                            <div className="col-md-12 col-sm-12">
                                <label htmlFor="ciudad_origen" className="m-0">Ciudad Origen*</label>
                            </div>
                            <div className="col-md-12  form-group">
                                <Field
                                    valueKey="id"
                                    name="ciudad_origen"
                                    labelKey="nombre"
                                    label="nombre"
                                    component={AsyncSelectField2}
                                    loadOptions={this.getCiudadOrigen}
                                    defaultOptions={this.state.CiudadOrigen}
                                />
                            </div>
                        </div>
                        <div className="col-md-6 p-0">
                            <div className="col-md-12 col-sm-12">
                                <label htmlFor="pais_destino" className="m-0">País Destino*</label>
                            </div>
                            <div className="col-md-12  form-group">
                                <Field
                                    valueKey="id"
                                    name="pais_destino"
                                    labelKey="nombre"
                                    label="nombre"
                                    component={AsyncSelectField2}
                                    loadOptions={getPaisDestino}
                                    onCambio={() => clearSelectPais("ciudad_destino")}
                                />
                            </div>
                        </div>
                        <div className="col-md-6 p-0">
                            <div className="col-md-12 col-sm-12">
                                <label htmlFor="ciudad_destino" className="m-0">Ciudad Destino*</label>
                            </div>
                            <div className="col-md-12  form-group">
                                <Field
                                    valueKey="id"
                                    name="ciudad_destino"
                                    labelKey="nombre"
                                    label="nombre"
                                    component={AsyncSelectField2}
                                    loadOptions={this.getCiudadDestino}
                                    defaultOptions={this.state.CiudadDestino}
                                />
                            </div>
                        </div>
                        <div className="col-md-6 p-0">
                            <div className="col-md-12 col-sm-12">
                                <label htmlFor="vehiculo" className="m-0">Tipo de Vehículo*</label>
                            </div>
                            <div className="col-md-12  form-group">
                                <Field
                                    valueKey="id"
                                    name="vehiculo"
                                    labelKey="nombre"
                                    label="nombre"
                                    component={AsyncSelectField2}
                                    loadOptions={getVehiculo}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="row  col-md-12 p-0">
                        <div className="col-md-6 p-0">
                            <div className="col-md-12 col-sm-12">
                                <label htmlFor="minimo" className="m-0">Precio mínimo*</label>
                            </div>
                            <div className="col-md-12  form-group">
                                <Field
                                    name="minimo"
                                    prefix="$ "
                                    component={renderCurrency}
                                />
                            </div>
                        </div>
                        <div className="col-md-6 p-0">
                            <div className="col-md-12 col-sm-12">
                                <label htmlFor="maximo" className="m-0">Precio máximo*</label>
                            </div>
                            <div className="col-md-12  form-group">
                                <Field
                                    name="maximo"
                                    prefix="$ "
                                    component={renderCurrency}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="col-12 text-right">
                        <span className="azul bold text-xsm mr-4">* Campos requeridos</span>
                    </div>
                    <div className="d-flex flex-wrap justify-content-center align-items-center mt-5">
                        <div className="col-md-4 col-12">
                            <Link className="btn btn-dark btn-block" to="/rangos_precios">Cancelar</Link>
                        </div>
                        <div className="col-md-4 col-12 mt-md-0 mt-3">
                            <button type="submit" className="btn btn-primary btn-block">Guardar</button>
                        </div>
                    </div>
                </div>
            </form>
        )
    }
};



const validate = (values) => {
    // console.log("Values", values);
    const errors = {};
    if (!values.pais_origen) {
        errors.pais_origen = "Campo requerido";
    }
    if (!values.pais_destino) {
        errors.pais_destino = "Campo requerido";
    }
    if (!values.ciudad_origen) {
        errors.ciudad_origen = "Campo requerido";
    }
    if (!values.ciudad_destino) {
        errors.ciudad_destino = "Campo requerido";
    }
    if (!values.vehiculo) {
        errors.vehiculo = "Campo requerido vehiculo";
    }
    if (!values.maximo) {
        errors.maximo = "Campo requerido";
    }
    if (!values.minimo) {
        errors.minimo = "Campo requerido";
    }
    if (values.maximo) {
        if (parseFloat(values.maximo) < 0) {
            errors.maximo = "El Campo no puede ser negativo";
        }
        if (parseFloat(values.maximo) == 0) {
            errors.maximo = "El Campo no puede ser cero";
        }
    }
    if (values.minimo) {
        if (parseFloat(values.minimo) < 0) {
            errors.minimo = "El Campo no puede ser negativo";
        }

        if (parseFloat(values.minimo) == 0) {
            errors.minimo = "El Campo no puede ser cero";
        }
    }
    if (values.maximo && values.minimo) {
        if (parseFloat(values.maximo) < parseFloat(values.minimo)) {
            errors.maximo = "El precio maximo debe ser mayor que el minimo";
        }
    }

    return errors;
};

RangoPrecioForm = reduxForm({
    form: 'RangoPrecioForm',
    validate
})(RangoPrecioForm);




RangoPrecioForm = connect(state => {
    const form = state.form.RangoPrecioForm;
    let pais_origen = "";
    let pais_destino = "";

    if (form && form.values) {
        const values = form.values;

        if (values.pais_origen) {
            pais_origen = values.pais_origen
        }
        if (values.pais_destino) {
            pais_destino = values.pais_destino
        }
    }
    return {
        pais_origen,
        pais_destino
    }
})(RangoPrecioForm);

export default RangoPrecioForm
