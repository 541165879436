import { handleActions } from 'redux-actions';
import { api } from "api";
import { initialize as initializeForm } from "redux-form";
import { NotificationManager } from "react-notifications";
import { push } from "react-router-redux";
import { DEVOLUCION_PENDIENTE, DEVOLUCION_REALIZADA } from "../../../utility/constants";

const LOADER = 'LOADER_PROYECTOS';
const DATA = 'DATA_PROYECTOS';
const ITEM_DATA = 'ITEM_PROYECTOS';
const PAGE = 'PAGE_PROYECTOS';
const SEARCH = 'SEARCH_PROYECTOS';
// ESTADO TAB
const ESTADO_PROYECTO_TAB = 'ESTADO_PROYECTO_TAB';


// -----------------------------------
// Pure Actions
// -----------------------------------

const setLoader = loader => ({
    type: LOADER,
    loader,
});

const setData = data => ({
    type: DATA,
    data,
});
const setPage = (page) => ({
    type: PAGE,
    page,
});

const setItem = item => ({
    type: ITEM_DATA,
    item,
});


const setSearch = search => ({
    type: SEARCH,
    search,
});

const setEstadoProyectoTab = estado_devolucion_tab => ({
    type: ESTADO_PROYECTO_TAB,
    estado_devolucion_tab,
});


const leer = id => (dispatch) => {
    dispatch(setLoader(true));
    api.get(`${'devolucion'}/${id}`).then((response) => {
        dispatch(setItem(response));
        dispatch(initializeForm('ProyectoForm', response));
        if (response.adjudicado) {
            dispatch(viajeDetalle(id));
        }
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const realizarDevolucion = (id) => (dispatch) => {
    dispatch(setLoader(true));
    api.put(`devolucion/${id}/realizar_devolucion`).then(() => {
        NotificationManager.success('Acción realizada con éxito', 'Éxito', 3000);

    }).catch(() => {
        NotificationManager.error('Error en la acción', 'ERROR', 3000);
    }).finally(() => {
        dispatch(setLoader(false));
        dispatch(push("/devoluciones"));
    });
}

const searchChange = search => (dispatch) => {
    dispatch(setSearch(search));
    dispatch(listarDevoluciones());
};


const listarDevoluciones = (page = 1) => (dispatch, getStore) => {
    const resource = getStore().devoluciones;
    const params = {
        page,
        devolucion_concluida: resource.estado_devolucion_tab == DEVOLUCION_REALIZADA
    };

    params.search = resource.search;
    dispatch(setLoader(true));
    api.get('devolucion', params).then((response) => {
        dispatch(setData(response));
        dispatch(setPage(page));

    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};
const estadoProyectoTab = estado => (dispatch) => {
    dispatch(setEstadoProyectoTab(estado));
    dispatch(setSearch(''));
    dispatch(listarDevoluciones());
};


export const actions = {
    leer,
    realizarDevolucion,
    searchChange,
    listarDevoluciones,
    estadoProyectoTab,
};

// ------------------------------------
// Reducers
// ------------------------------------

export const reducers = {
    [LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    [DATA]: (state, { data }) => {
        return {
            ...state,
            data,
        };
    },
    [ITEM_DATA]: (state, { item }) => {
        return {
            ...state,
            item,
        };
    },
    [PAGE]: (state, { page }) => {
        return {
            ...state,
            page,
        };
    },
    [ESTADO_PROYECTO_TAB]: (state, { estado_devolucion_tab }) => {
        return {
            ...state,
            estado_devolucion_tab,
        };
    },
    [SEARCH]: (state, { search }) => {
        return {
            ...state,
            search,
        };
    },
};



export const initialState = {
    loader: false,
    data: {
        results: [],
        count: 0,
    },
    item: {},
    page: 1,
    search: '',
    estado_devolucion_tab: DEVOLUCION_PENDIENTE,
};

export default handleActions(reducers, initialState);
