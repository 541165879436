import { connect } from 'react-redux';
import { actions } from '../../../../../redux/modules/panelFinanciero';
import ListarMovimientoTransportista from './ListarMovimiento';


const ms2p = (state) => {
    return {
        ...state.panelFinanciero,
    };
};

const md2p = { ...actions };

export default connect(ms2p, md2p)(ListarMovimientoTransportista);
