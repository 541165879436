import React, { Fragment} from 'react';


function CardEmpty(props) {
    return (
        <Fragment>
            <div className={`card-empty`}>
                <div className={`card-content ${props.noPadding ? '' : 'p-0 px-3 pt-3'}`}>
                    {props.children}
                </div>
            </div>
        </Fragment>

    )
}

export default CardEmpty;
