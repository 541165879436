import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { Field, reduxForm, formValueSelector, } from 'redux-form';
import { renderCurrency, renderTextArea } from '../../../Utils/renderField/renderField';
import { RenderDollar } from '../../../Utils/renderField/renderReadField';
const alerta_img = require('assets/img/icons/alert.svg');

let RealizarRetiroForm = props => {
    const { handleSubmit, saldoDisponible = 0, totalPuntosDinero = 0, totalPuntos = 0, idTransportista = 0, tipoRetiro } = props;
    return (
        <form onSubmit={handleSubmit}>
            <div className="form-group grid-container col-12 px-0 px-sm-4">
                {tipoRetiro == "puntos" &&
                    <Fragment>
                        <div className="col-12 d-flex align-content-center px-0 flex-sm-row flex-column mb-3">
                            <div className="flex-1 text-center px-auto px-sm-4">
                                <span className="gris-oscuro bold text-sm">
                                    Total puntos:
                                <span className="azul bold text-md"> {totalPuntos}</span>
                                    <br />
                                &nbsp;equivalente a:&nbsp;
                                <RenderDollar value={totalPuntosDinero} className="naranja bold text-md" />
                                </span>
                            </div>
                        </div>
                    </Fragment>
                }


                {tipoRetiro == "parcial" &&
                    <Fragment>
                        <div className="row p-0 flex-column text-center mb-3">
                            <label htmlFor="nombre" className="m-0 text-sm bold">Saldo disponible:</label>
                            <RenderDollar className="naranja bold text-lg" value={saldoDisponible} />
                        </div>
                        <div className="row justify-content-center p-0 flex-row ">
                            <div className="col-md-6 p-0">
                                <div className="col-md-12 col-sm-12">
                                    <label className="m-0">Monto a retirar</label>
                                </div>
                                <div className="col-md-12  form-group">
                                    <Field
                                        name="monto_solicitud"
                                        prefix="$ "
                                        component={renderCurrency}
                                    />
                                </div>
                            </div>
                        </div>
                    </Fragment>
                }

                <div className="row justify-content-center p-0 flex-row ">
                    <div className="col-md-6 p-0">
                        <div className="col-md-12 col-sm-12">
                            <label className="m-0">Descripción:</label>
                        </div>
                        <div className="col-md-12  form-group">
                            <Field
                                name="descripcion"
                                component={renderTextArea}
                            />
                        </div>
                    </div>
                </div>

                <div className="d-flex flex-column flex-sm-row align-items-center justify-content-center mt-3">
                    <img src={alerta_img} alt="Alerta" width={25} height={20} className="mr-2  blink-danger" />
                    <span className="bold azul">¡Se enviara la notificación del retiro!</span>
                </div>

                <div className="d-flex flex-wrap justify-content-center align-items-center mt-5">
                    <div className="col-md-4 col-12">
                        <Link className="btn btn-dark btn-block" to={`/panel-financiero-transportista/${idTransportista}`}>Cancelar</Link>
                    </div>
                    <div className="col-md-4 col-12 mt-md-0 mt-3">
                        <button type="submit" className="btn btn-primary btn-block">{tipoRetiro == "parcial" ? "REALIZAR RETIRO" : "RETIRAR PUNTOS"}</button>
                    </div>
                </div>
            </div>
        </form >
    )
};

const validate = (values, props) => {
    const errors = {};

    if (!values.monto_solicitud) {
        errors.monto_solicitud = "Campo requerido";
    }

    if (values.monto_solicitud > values.monto_saldo) {
        errors.monto_solicitud = "El valor del monto a retirar no puede ser más mayor que tu saldo";
    }

    if (values.monto_solicitud <= 0) {
        errors.monto_solicitud = "El valor del monto a retirar debe ser mayor que cero";
    }
    if (!values.descripcion) {
        errors.descripcion = "Campo requerido";
    }

    return errors;
};

RealizarRetiroForm = reduxForm({
    form: 'RealizarRetiroForm',
    validate,
})(RealizarRetiroForm);

export default RealizarRetiroForm

