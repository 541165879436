import React, { Fragment, useState, useEffect } from 'react';
import { connect } from 'react-redux'
import { Link } from 'react-router-dom';
import { validate, validators, combine } from 'validate-redux-form';
import { Field, reduxForm, formValueSelector, FieldArray } from 'redux-form';
import { renderField, renderFilePickerPequeno, renderSwitch, AsyncSelectField2, SelectField, renderTextArea, renderNumber, renderPasswordField } from '../../../Utils/renderField/renderField';
import { validationTransportista } from '../../../../../utility/validationForm'

import { USER_CONTRATISTA_EMPRESA, USER_CONTRATISTA_INDIVIDUAL, tipo_refrigeracion } from '../../../../../utility/constants'


import { api } from "api";
const eliminar_img = require('assets/img/icons/borrar_hover.svg');


const getVehiculo = (search) => {
    return api.get("tipo_vehiculo", { search }).then((data) => {
        if (data) return data.results;
        return [];
    }).catch(() => {
        return [];
    });
};

const renderVehiculos = ({ fields, meta: { error, submitFailed }, contactos }) => {
    if (!fields.length) {
        fields.push({});
    }
    return (
        <div className="col-12 form-group m-auto px-0 px-sm-0 pt-2">
            <div className="row my-n2">
                <div className="react-bs-container-header" style={{ height: 25 }} >
                    <div className="d-none d-md-flex row border-publicidad py-1 px-0 px-sm-1 px-lg-1 px-xl-1">
                        <div className="col-1 mb-2 d-none d-md-flex justify-content-center align-items-center">
                        </div>
                        <div className="col-md-4 mb-2 px-1">
                            <label htmlFor="tipo" className="ml-2 blanco d-block">TIPO</label>
                        </div>
                        <div className="col-md-4 mb-2 px-1">
                            <label htmlFor="regrigeracion" className="blanco d-block">REFRIGERACIÓN</label>
                        </div>
                        <div className="col-md-3 mb-2 px-1">
                            <label htmlFor="capacidad" className="blanco d-block">CAPACIDAD</label>
                        </div>
                    </div>
                </div>
            </div>

            {fields.map((transporte, index) => {
                return (
                    <div className="row border-publicidad py-3 px-0 px-sm-1 flex-md-row flex-column" key={index}>
                        <div className="order-1 order-md-0 mx-auto px-1">
                            {fields.length > 1 &&
                                <img src={eliminar_img} alt="Eliminar"
                                    className="action_img"
                                    onClick={() => {
                                        fields.remove(index)
                                    }}
                                />
                            }
                        </div>
                        <div className="flex-1 px-1 pb-2 pb-md-0">
                            <label htmlFor="tipo" className="gris-oscuro d-block d-md-none">TIPO</label>
                            <Field
                                valueKey="id"
                                name={`${transporte}.vehiculo`}
                                labelKey="nombre"
                                label="nombre"
                                component={AsyncSelectField2}
                                loadOptions={getVehiculo}
                            />
                        </div>
                        <div className="flex-1 px-1 pb-2 pb-md-0">
                            <label htmlFor="regrigeracion" className="gris-oscuro d-block d-md-none">REFRIGERACIÓN</label>
                            <Field name={`${transporte}.refrigeracion`} component={SelectField} options={tipo_refrigeracion} className="form-control" />
                        </div>
                        <div className="flex-1 px-1 pb-2 pb-md-0">
                            <label htmlFor="capacidad" className="gris-oscuro d-block d-md-none">CAPACIDAD</label>
                            <Field
                                name={`${transporte}.capacidad`}
                                type="text"
                                component={renderField}
                                placeholder="30 pies"
                                maxLength={validationTransportista.vehiculosCapacidad.MaxLength}
                            />
                        </div>
                    </div>
                )
            })}
            <div className="d-flex justify-content-center mt-3">
                <button type="button" className="btn btn-secondary py-1" onClick={() => {
                    fields.push({})
                }}>
                    AGREGAR
                </button>
            </div>
        </div>
    )
}



const renderPolizas = ({ fields, meta: { error, submitFailed }, contactos, setPoilizaImgs, eliminarImgsPoliza }) => {
    if (!fields.length) {
        fields.push({});
    }
    return (
        <div className="col-12 form-group m-auto px-0 px-sm-0 pt-2">
            <div className="row my-n2">
                <div className="react-bs-container-header" style={{ height: 25 }} >
                    <div className="d-none d-md-flex row border-publicidad py-1 px-0 px-sm-1 px-lg-1 px-xl-1">
                        <div className="col-1 mb-2 d-none d-md-flex justify-content-center align-items-center">
                        </div>
                        <div className="col-md-4 mb-2 px-1">
                            <label htmlFor="numero de poliza" className="ml-2 blanco d-block">NO. DE POLIZA</label>
                        </div>
                        <div className="col-md-4 mb-2 px-1">
                            <label htmlFor="empresa" className="blanco d-block">EMPRESA</label>
                        </div>
                        <div className="col-md-3 mb-2 px-1">
                            <label htmlFor="imagen del seguro" className="blanco d-block">IMAGEN</label>
                        </div>
                    </div>
                </div>
            </div>
            {fields.map((poliza, index) => {
                const polizaActual = fields.get(index)
                return (
                    <div className="row border-publicidad py-3 px-0 px-sm-1 flex-md-row flex-column" key={index}>
                        <div className="order-1 order-md-0 mx-auto px-1">
                            {fields.length > 1 &&
                                <img src={eliminar_img} alt="Eliminar"
                                    className="action_img"
                                    onClick={() => {
                                        fields.remove(index)
                                        eliminarImgsPoliza(index)
                                    }}
                                />
                            }
                        </div>
                        <div className="flex-1 px-1 pb-2 pb-md-0">
                            <label htmlFor="numero de poliza" className="gris-oscuro d-block d-md-none">NO. DE POLIZA</label>
                            <Field
                                name={`${poliza}.numero`}
                                type="text"
                                component={renderField}
                                maxLength={validationTransportista.polizaNumero.MaxLength}
                            />
                        </div>
                        <div className="flex-1 px-1 pb-2 pb-md-0">
                            <label htmlFor="empresa" className="gris-oscuro d-block d-md-none">EMPRESA</label>
                            <Field
                                name={`${poliza}.nombre`}
                                type="text"
                                component={renderField}
                                maxLength={validationTransportista.polizaNombre.MaxLength}
                            />
                        </div>
                        <div className="flex-1 px-1 pb-2 pb-md-0">
                            <label htmlFor="imagen del seguro" className="gris-oscuro d-block d-md-none">IMAGEN</label>
                            <Field
                                name={`${poliza}.imagen`}
                                photo={polizaActual.imagen ? polizaActual.imagen : null}
                                setFile={(file, toBase64) => setPoilizaImgs(index, file, toBase64)}
                                component={renderFilePickerPequeno}
                            />
                        </div>
                    </div>
                )
            })}
            <div className="d-flex justify-content-center mt-3">
                <button type="button" className="btn btn-secondary py-1" onClick={() => {
                    fields.push({})
                }}>
                    AGREGAR
                </button>
            </div>
        </div>
    )
}

const getPais = (search) => {
    return api.get("pais/listar_pais", { search }).then((data) => {
        if (data) return data.results;
        return [];
    }).catch(() => {
        return [];
    });
};
let TransportistaForm = props => {
    const { handleSubmit, editando, item, setRtuImg, setFianzaImg, setPatenteImg, setPoilizaImgs, eliminarImgsPoliza, clearSelectPais } = props;
    const { fianza, rtu_img, patente_img, } = props;
    const [Ciudad, setCiudad] = useState([]);
    const getCiudad = (search) => {
        const { pais_residencia } = props;
        const params = { search }

        if (pais_residencia.id) {
            params.pais = pais_residencia.id
        }

        return api.get("ciudad", params).then((data) => {
            if (data) {
                setCiudad(data.results)
                return data.results
            };
            return [];
        }).catch(() => {
            return [];
        });
    };
    useEffect(() => {
        getCiudad('')
    },[props.pais_residencia]);
    return (
        <form onSubmit={handleSubmit}>
            <div className="form-group grid-container col-12 px-0 px-sm-4">
                <div className="row  col-md-12 p-0">
                    <div className="col-md-6 p-0">
                        <div className="col-md-12 col-sm-12">
                            <label htmlFor="username" className="m-0">Usuario*</label>
                        </div>
                        <div className="col-md-12  form-group">
                            <Field name="username" component={renderField} type="text" className="form-control" maxLength={validationTransportista.username.MaxLength} />
                        </div>
                    </div>
                    <div className="col-md-6 p-0">
                        <div className="col-md-12 col-sm-12">
                            <label htmlFor="nombre" className="m-0">{item && item.tipo_usuario && item.tipo_usuario === USER_CONTRATISTA_INDIVIDUAL ? "Nombre completo*" : "Nombre comercial*"}</label>
                        </div>
                        <div className="col-md-12  form-group">
                            <Field name="nombre" component={renderField} type="text" className="form-control" maxLength={validationTransportista.nombre.MaxLength} />
                        </div>
                    </div>
                </div>
                <div className="row  col-md-12 p-0">
                    <div className="col-md-6 p-0">
                        <div className="col-md-12 col-sm-12">
                            <label htmlFor="email" className="m-0">Email*</label>
                        </div>
                        <div className="col-md-12  form-group">
                            <Field name="email" component={renderField} type="text" className="form-control" maxLength={validationTransportista.email.MaxLength} />
                        </div>
                    </div>
                    <div className="col-md-6 p-0">
                        <div className="col-md-12 col-sm-12">
                            <label htmlFor="telefono" className="m-0">Teléfono*</label>
                        </div>
                        <div className="col-md-12  form-group">
                            <Field name="telefono" component={renderNumber} type="text" className="form-control" maxLength={validationTransportista.telefono.MaxLength} />
                        </div>
                    </div>
                </div>
                <div className="row  col-md-12 p-0">
                    <div className="col-md-6 p-0">
                        <div className="col-md-12 col-sm-12">
                            <label htmlFor="pais_residencia" className="m-0">País*</label>
                        </div>
                        <div className="col-md-12  form-group">
                            <Field
                                valueKey="id"
                                name="pais_residencia"
                                labelKey="nombre"
                                label="nombre"
                                component={AsyncSelectField2}
                                loadOptions={getPais}
                                onCambio={clearSelectPais}
                            />
                        </div>
                    </div>
                    <div className="col-md-6 p-0">
                        <div className="col-md-12 col-sm-12">
                            <label htmlFor="ciudad_residencia" className="m-0">Ciudad*</label>
                        </div>
                        <div className="col-md-12  form-group">
                            <Field
                                valueKey="id"
                                name="ciudad_residencia"
                                labelKey="nombre"
                                label="nombre"
                                component={AsyncSelectField2}
                                loadOptions={getCiudad}
                                defaultOptions={Ciudad}
                            />
                        </div>
                    </div>
                </div>


                <div className="row  col-md-12 p-0">
                    <div className="col-12 p-0">
                        <div className="col-md-12 col-sm-12">
                            <label htmlFor="descripcion" className="m-0">Descripción (describe tu empresa/servicio)*</label>
                        </div>
                        <div className="col-md-12  form-group">
                            <Field name="descripcion" component={renderTextArea} type="text" className="form-control" />
                        </div>
                    </div>
                </div>


                <div className="row  col-md-12 p-0">
                    <div className="col-md-6 p-0">
                        <div className="col-md-12 col-sm-12">
                            <label htmlFor="nit" className="m-0">NIT*</label>
                        </div>
                        <div className="col-md-12  form-group">
                            <Field name="nit" component={renderField} type="text" className="form-control" maxLength={validationTransportista.nit.MaxLength} />
                        </div>
                    </div>

                    <div className="col-md-6 p-0">
                        <div className="col-md-12 col-sm-12">
                            <label htmlFor="fianza" className="m-0">Fianza (jpg o pdf)</label>
                        </div>
                        <div className="col-md-12  form-group">
                            <Field photo={fianza ? fianza : null} setFile={setFianzaImg} name="fianza" component={renderFilePickerPequeno} />
                        </div>
                    </div>

                </div>
                <div className="row  col-md-12 p-0">
                    <div className="col-md-6 p-0">
                        <div className="col-md-12 col-sm-12">
                            <label htmlFor="rtu" className="m-0">Constancia RTU*</label>
                        </div>
                        <div className="col-md-12  form-group">
                            <Field name="rtu" component={renderField} type="text" className="form-control" maxLength={validationTransportista.rtu.MaxLength} />
                        </div>
                    </div>
                    <div className="col-md-6 p-0">
                        <div className="col-md-12 col-sm-12">
                            <label htmlFor="patente_img" className="m-0">Patente (jpg o pdf)*</label>
                        </div>
                        <div className="col-md-12  form-group">
                            <Field photo={patente_img ? patente_img : null} setFile={setPatenteImg} name="patente_img" component={renderFilePickerPequeno} />
                        </div>
                    </div>
                </div>

                <div className="row  col-md-12 p-0">
                    <div className="col-md-6 p-0">
                        <div className="col-md-12 col-sm-12">
                            <label htmlFor="rtu_img" className="m-0">Constancia RTU (jpg o pdf)</label>
                        </div>
                        <div className="col-md-12  form-group">
                            <Field photo={rtu_img ? rtu_img : null} setFile={setRtuImg} name="rtu_img" component={renderFilePickerPequeno} />
                        </div>
                    </div>
                    <div className="col-md-6 p-0 d-flex">
                        <div className="col-md-12 col-sm-12 align-self-center">
                            <Field name="new_password" placeholder="Nueva contraseña" component={renderPasswordField} minLength={6} className="form-control" />
                        </div>
                    </div>
                </div>

                <div className="col-12 text-right">
                    <span className="azul bold text-xsm">* Campos requeridos</span>
                </div>

                <div className="row  justify-content-center p-0 mt-3">
                    <div className="col-12 p-0 px-0 px-md-3">
                        <div className="col-md-12 col-sm-12 text-center">
                            <label htmlFor="polizas" className="m-0">Pólizas de seguro</label>
                        </div>
                        <div className="col-md-12  form-group">
                            <FieldArray name="polizas" setPoilizaImgs={setPoilizaImgs} eliminarImgsPoliza={eliminarImgsPoliza} component={renderPolizas} />
                        </div>
                    </div>
                </div>

                <div className="row  justify-content-center p-0 mt-3">
                    <div className="col-12 p-0 px-0 px-md-3">
                        <div className="col-md-12 col-sm-12 text-center">
                            <label htmlFor="transportes" className="m-0">Vehículos</label>
                        </div>
                        <div className="col-md-12 form-group">
                            <FieldArray name="transportes" component={renderVehiculos} />
                        </div>
                    </div>
                </div>


                {/* {(editando) && (
                    <div className="row  col-md-12 p-0">
                        <div className="col-md-6 p-0">
                            <div className="col-md-12 col-sm-12">
                                <label htmlFor="metodo_pago" className="m-0">Usuario activo</label>
                            </div>
                            <div className="col-md-12  form-group">
                                <Field
                                    name="is_active"
                                    label=""
                                    component={renderSwitch}
                                />
                            </div>
                        </div>
                    </div>
                )} */}
                <div className="d-flex flex-wrap justify-content-center align-items-center mt-5">
                    <div className="col-md-5 col-lg-5 col-xl-4 col-12">
                        <Link className="btn btn-dark btn-block" to="/transportistas_validados">Cancelar</Link>
                    </div>
                    <div className="col-md-5 col-lg-5 col-xl-4 col-12 mt-md-0 mt-3">
                        <button type="submit" className="btn btn-primary btn-block">Guardar</button>
                    </div>
                </div>
            </div>
        </form>
    )
};


const validatePrimary = (values) => {
    let errors = {};
    let DetalleArrayErrors = [];
    if (values && values.polizas && values.polizas.length) {
        values.polizas.forEach((detalle, detalleIndex) => {
            DetalleArrayErrors[detalleIndex] = validate(detalle, {
                nombre: validationTransportista.polizaNombre.Combine,
                numero: validationTransportista.polizaNumero.Combine,
            })
        });
    }
    errors.polizas = DetalleArrayErrors

    DetalleArrayErrors = [];
    if (values && values.transportes && values.transportes.length) {
        values.transportes.forEach((detalle, detalleIndex) => {
            DetalleArrayErrors[detalleIndex] = validate(detalle, {
                vehiculo: validationTransportista.vehiculosVehiculo.Combine,
                refrigeracion: validationTransportista.vehiculosRefrigeracion.Combine,
                capacidad: validationTransportista.vehiculosCapacidad.Combine,
            })
        });
    }
    errors.transportes = DetalleArrayErrors

    if (values && values.new_password && values.new_password.length < 6) {
        errors.new_password = "Mínimo 6 carácteres"
    }
    if (values && values.new_password && values.new_password.length > 150) {
        errors.new_password = "Máximo 150 carácteres"
    }

    errors = {
        ...errors,
        ...validate(values, {
            nombre: validationTransportista.nombre.Combine,
            email: validationTransportista.email.Combine,
            telefono: validationTransportista.telefono.Combine,
            descripcion: validationTransportista.descripcion.Combine,
            username: validationTransportista.username.Combine,
            pais_residencia: validationTransportista.pais_residencia.Combine,
            ciudad_residencia: validationTransportista.ciudad_residencia.Combine,

            nit: validationTransportista.nit.Combine,
            patente_img: validationTransportista.patente_img.Combine,
            rtu: validationTransportista.rtu.Combine,
        })
    }
    return errors

};

TransportistaForm = connect(state => {
    const form = state.form.EditarTransportistaForm;
    let fianza = "";
    let rtu_img = "";
    let patente_img = "";

    let pais_residencia = "";

    if (form && form.values) {
        const values = form.values;

        if (values.fianza) {
            fianza = values.fianza
        }
        if (values.rtu_img) {
            rtu_img = values.rtu_img
        }
        if (values.patente_img) {
            patente_img = values.patente_img
        }
        if (values.pais_residencia) {
            pais_residencia = values.pais_residencia
        }
    }
    return {
        fianza,
        rtu_img,
        patente_img,
        pais_residencia,
    }
})(TransportistaForm);

TransportistaForm = reduxForm({
    form: 'EditarTransportistaForm',
    validate: validatePrimary
})(TransportistaForm);

export default TransportistaForm;
