import React, {Component} from 'react'
import Grid from "../../../Utils/Grid";
import Card from "Utils/Cards/Card"
import {TableHeaderColumn} from "react-bootstrap-table";
import {standardActions} from "../../../Utils/Grid/StandardActions";
import Link from "react-router-dom/es/Link";
import HeaderReportes from "Utils/Grid/HeaderReportes";
import classNames from 'classnames';
import DatePicker from "react-datepicker";
import moment from 'moment';
import Select, { Async } from "react-select";
import { api } from "api";

const getUser = (search) => {
    return api.get("user", { search }).then((data) => {
        if (data) return data.results;
        return [];
    }).catch(() => {
        return [];
    });
};

export default class UsuariosList extends Component{
    componentWillMount() {
        const { listar, page } = this.props;
        listar(page);
    }

    render() {
        const { listar: onPageChange, onSortChange } = this.props; //Funciones
        const { data, loader, page } = this.props; //Constantes
        return(
            <div className="mb-4 col-12 mt-4">
                <Card titulo="BITÁCORA">
                    <HeaderReportes
                        filtro1={
                            <DatePicker
                                showPopperArrow={false}
                                dateFormat="DD/MM/YYYY"
                                placeholderText="Fecha inicial"
                                onChange={(value) => { this.props.filtroFechaInicio(value) }}
                                selected={this.props.filtro_fecha_inicio}
                            />
                        }
                        filtroText1="Fecha Inicio"
                        filtro2={
                            <DatePicker
                                showPopperArrow={false}
                                dateFormat="DD/MM/YYYY"
                                placeholderText="Fecha final"
                                onChange={(value) => { this.props.filtroFechaFin(value) }}
                                selected={this.props.filtro_fecha_fin}
                            />
                        }
                        filtroText2="Fecha Fin"
                        filtro3={
                            <Async
                                isClearable={true}
                                backspaceRemovesValue={false}
                                value={this.props.filtro_usuario}
                                isSearchable={true}
                                loadOptions={getUser}
                                getOptionValue={(option) => option["id"]}
                                getOptionLabel={(option) => option["nombre"]}
                                type="text"
                                onChange={(e) => this.props.filtroUsuario(e)}
                                multi={false}
                                autoload={false}
                                cache={false}
                                className={classNames("react-select-container w-100")}
                                defaultOptions={true}
                                placeholder="Filtrar por usuario.."
                            />
                        }
                        filtroText3="Usuario"
                    />
                    <Grid hover striped data={data} loading={loader} onPageChange={onPageChange} onSortChange={onSortChange}
                    page={page}>
                        <TableHeaderColumn
                            dataField="id"
                            dataAlign="center"
                            isKey
                            width='15%'
                            dataFormat={standardActions({ ver: "bitacora"})}
                        >
                            ACCIONES
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="creado"
                            dataFormat={(cell, row) => moment(cell).format("DD/MM/YYYY hh:mm A")}
                        >
                            FECHA
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="usuario"
                            dataFormat={(cell, row) => cell ? cell.nombre : ''}
                        >
                            USUARIO
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="accion"
                            width='40%'
                        >
                            ACCIÓN
                        </TableHeaderColumn>
                    </Grid>
                </Card>
            </div>
        )
    }
}
