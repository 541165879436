import React, {Component} from 'react'
import Grid from "../../../Utils/Grid";
import {TableHeaderColumn} from "react-bootstrap-table";
import {standardActions} from "../../../Utils/Grid/StandardActions";
import Link from "react-router-dom/es/Link";
import Card from "Utils/Cards/Card"
import Header from "Utils/Grid/Header";
import { tipo_vehiculo } from '../../../../../utility/constants';

const DatoCiudad = ({value,cabecera}) => {

    return (
        <div>
            {value}  {cabecera && <span> - <span className="naranja"> Cabecera</span> </span>  }
        </div>
    )

}

const expandComponent = (row) => {
    let _data = row.ciudades ? row.ciudades.slice() : [];
    return(
        <div className="row ml-5">
            <div className="col-10 ml-5">
                <div className="tabla-adentro-pais" style={{width: '100%'}}>
                    <BootstrapTable
                        headerStyle={ { backgroundColor: '#e24647' } }
                        data={_data}>
                        <TableHeaderColumn width="10%"/>
                        <TableHeaderColumn isKey hidden dataField="id">
                            Id
                        </TableHeaderColumn>
                        <TableHeaderColumn dataField="nombre"
                            width="10%"
                            dataFormat={(cell, row) => cell ? <DatoCiudad value={cell} cabecera={row.cabecera}/> : '---' }
                        >
                            Nombre
                        </TableHeaderColumn>
                        <TableHeaderColumn width="10%"/>
                    </BootstrapTable>
                </div>
            </div>
        </div>
    )
};



export default class List extends Component{
    componentWillMount() {
        const { listar, page } = this.props;
        listar(page);
    }

    render() {
        const { listar: onPageChange, onSortChange, eliminar } = this.props;
        const { data, loader, page } = this.props;
        return(
            <div className="mb-4 col-12 mt-4">
                <Card
                    titulo="PAISES">
                    <Header to="pais/crear" textBtn="Crear país" {...this.props} />
                    <Grid hover striped data={data} loading={loader} onPageChange={onPageChange}
                          onSortChange={onSortChange} page={page}
                        expandableRow={()=>true} expandComponent={expandComponent}
                        >
                        <TableHeaderColumn
                            dataField="id"
                            dataAlign="center"
                            dataSort
                            width='20%'
                            dataFormat={standardActions({ editar: "pais", eliminar})}
                        />
                        <TableHeaderColumn
                            isKey
                            dataField="nombre"
                            dataSort
                            width='20%'
                        >
                            Pais
                        </TableHeaderColumn>

                        <TableHeaderColumn
                            dataAlign="left"
                            dataField="total_ciudades"
                            width='10%'
                            dataSort
                        >
                            CIUDADES
                        </TableHeaderColumn>
                        <TableHeaderColumn width="30%"/>
                    </Grid>
                </Card>
            </div>
        )
    }
}
