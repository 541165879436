import { connect } from 'react-redux';
import { actions } from '../../../../../redux/modules/paises/paises';
import Crear from './Crear';

const mstp = state => {
    return {...state.paises}
};

const mdtp = {
    ...actions
};

export default connect(mstp, mdtp)(Crear)
