import React, { Component, Fragment } from 'react'
import Grid from "../../../Utils/Grid";
import Card from "Utils/Cards/Card"
import { TableHeaderColumn } from "react-bootstrap-table";
import { standardActions } from "../../../Utils/Grid/StandardActions";
import Link from "react-router-dom/es/Link";
import LoadMask from 'Utils/LoadMask/LoadMask';
import HeaderReportes from "Utils/Grid/HeaderReportes";
import Select, { Async } from "react-select";
import classNames from 'classnames';
import DatePicker from "react-datepicker";
import { api } from "api";
import chroma from 'chroma-js';
import { ESTADO_PROYECTO } from '../../../../../utility/constants';
import { RenderNumber } from '../../../Utils/renderField/renderReadField';
// Grafica
import Chart from "react-google-charts";
import {
    BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, Cell, Label
} from 'recharts';

const dot = (color = '#ccc') => ({
    alignItems: 'center',
    display: 'flex',
    ':before': {
        backgroundColor: color,
        borderRadius: 10,
        content: '" "',
        display: 'block',
        marginRight: 8,
        height: 10,
        width: 10,
    },
});

const colourStyles = {
    control: styles => ({ ...styles, backgroundColor: 'white' }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
        const color = chroma(data.color);
        return {
            ...styles,
            backgroundColor: isDisabled
                ? null
                : isSelected
                    ? data.color
                    : isFocused
                        ? color.alpha(0.1).css()
                        : null,
            color: isDisabled
                ? '#ccc'
                : isSelected
                    ? chroma.contrast(color, 'white') > 2
                        ? 'white'
                        : 'black'
                    : data.color,
            cursor: isDisabled ? 'not-allowed' : 'default',
            ':active': {
                ...styles[':active'],
                backgroundColor: !isDisabled && (isSelected ? data.color : color.alpha(0.3).css()),
            },
        };
    },
    input: styles => ({ ...styles, ...dot() }),
    placeholder: styles => ({ ...styles, ...dot() }),
    singleValue: (styles, { data }) => ({ ...styles, ...dot(data.color) }),
};

const getUser = (search) => {
    return api.get("user/contratistas_validados", { search }).then((data) => {
        if (data) return data.results;
        return [];
    }).catch(() => {
        return [];
    });
};


export default class ProyectosYOfertas extends Component {
    componentWillMount() {
        this.props.dataTotalesReporteProyectosYOfertas()
        this.props.listarProyectosReporte()
        this.props.dataGraficaProyectosReporte()
    }

    render() {
        const { listarProyectosReporte: onPageChange, onSortChange, eliminar } = this.props; //Funciones
        const { totales, data, data_grafica, loader, page } = this.props; //Constantes
        return (
            <div className="mb-4 col-12 mt-4">
                <Card titulo="REPORTE PROYECTOS">
                    <HeaderReportes
                        filtro1={
                            <DatePicker
                                showPopperArrow={false}
                                dateFormat="DD/MM/YYYY"
                                placeholderText="Fecha inicial"
                                onChange={(value) => { this.props.changeFechaInicio(value) }}
                                selected={this.props.filtro_fecha_inicio}
                            />
                        }
                        filtroText1="Fecha Inicio"
                        filtro2={
                            <DatePicker
                                showPopperArrow={false}
                                dateFormat="DD/MM/YYYY"
                                placeholderText="Fecha final"
                                onChange={(value) => { this.props.changeFechaFinal(value) }}
                                selected={this.props.filtro_fecha_fin}
                            />
                        }
                        filtroText2="Fecha Fin"
                        filtro3={
                            <Async
                                isClearable={true}
                                backspaceRemovesValue={false}
                                value={this.props.filtro_contratista}
                                isSearchable={true}
                                loadOptions={getUser}
                                getOptionValue={(option) => option["id"]}
                                getOptionLabel={(option) => option["nombre"]}
                                type="text"
                                onChange={(e) => this.props.changeContratista(e)}
                                multi={false}
                                autoload={false}
                                cache={false}
                                className={classNames("react-select-container w-100")}
                                defaultOptions={true}
                                placeholder="Filtrar por contratista.."
                            />
                        }
                        filtroText3="Contratista"
                        filtro4={
                            <Select
                                isClearable={true}
                                className={classNames('react-select-container')}
                                backspaceRemovesValue={false}
                                isSearchable={true}
                                options={ESTADO_PROYECTO}
                                placeholder={"Filtrar por estado"}
                                value={this.props.filtro_estado}
                                onChange={this.props.changeEstado}
                                styles={colourStyles}
                            />
                        }
                        filtroText4="Estado"
                    />
                    <div className="col-12 p-0">
                        <div className="row p-0">
                            <div className="col-lg-4 col-12 mb-3 px-0 px-lg-3">
                                <LoadMask loading={loader} blur>
                                    <div className="card-proyecto">
                                        <div className="col-12 mt-3">
                                            <span className="gris-oscuro bold">OFERTAS EN PROYECTOS</span>
                                        </div>
                                        <div className="col-12">
                                            <RenderNumber value={totales && totales.total_ofertas ? totales.total_ofertas : 0} className="verde text-reporte ml-lg-3 ml-0" />
                                        </div>
                                    </div>
                                </LoadMask>
                            </div>
                            <div className="col-lg-4 col-12 mb-3 px-0 px-lg-3">
                                <LoadMask loading={loader} blur>
                                    <div className="card-proyecto">
                                        <div className="col-12 mt-3">
                                            <span className="gris-oscuro bold">ADJUDICACIONES</span>
                                        </div>
                                        <div className="col-12">
                                            <RenderNumber value={totales && totales.total_adjudicaciones ? totales.total_adjudicaciones : 0} className="naranja text-reporte ml-lg-3 ml-0" />
                                        </div>
                                    </div>
                                </LoadMask>
                            </div>
                            <div className="col-lg-4 col-12 mb-3 px-0 px-lg-3">
                                <LoadMask loading={loader} blur>
                                    <div className="card-proyecto">
                                        <div className="col-12 mt-3">
                                            <span className="gris-oscuro bold">EN CURSO</span>
                                        </div>
                                        <div className="col-12">
                                            <RenderNumber value={totales && totales.total_pendientes_pago ? totales.total_pendientes_pago : 0} className="rojo text-reporte ml-lg-3 ml-0" />
                                        </div>
                                    </div>
                                </LoadMask>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 p-0">
                        <div className="row p-0">
                            <div className="col-lg-6 col-12 mb-3 px-0 px-lg-3">
                                <div className="card-proyecto">
                                    <div className="col-12 my-3">
                                        <span className="gris-oscuro bold">PROYECTO</span>
                                    </div>
                                    <div className="col-12">
                                        <Grid hover striped data={data} loading={loader} onPageChange={onPageChange} page={page}>
                                            <TableHeaderColumn
                                                dataField="id"
                                                dataAlign="center"
                                                dataSort
                                                isKey
                                                width='15%'
                                                dataFormat={standardActions({ ver: "proyecto" })}
                                            />
                                            <TableHeaderColumn
                                                dataField="nombre"
                                                dataSort
                                            >
                                                PROYECTO
                                            </TableHeaderColumn>
                                            <TableHeaderColumn
                                                dataField="creador"
                                                dataSort
                                                dataFormat={(cell) => cell && cell.nombre ? cell.nombre : ""}
                                            >
                                                CONTRATISTA
                                            </TableHeaderColumn>
                                            <TableHeaderColumn
                                                dataField="total_ofertas"
                                                dataSort
                                                width='20%'
                                                dataAlign='center'
                                            >
                                                OFERTAS
                                            </TableHeaderColumn>
                                        </Grid>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 col-12 mb-3 px-0 px-lg-3">
                                <div className="card-proyecto h-100">
                                    <span className="gris-oscuro bold mx-3 my-3">ESTADOS</span>
                                    <div className="d-flex h-100 mb-3 align-items-center px-3 gris">
                                        <LoadMask loading={loader} blur>
                                            <ResponsiveContainer width="100%" height={400} minWidth={150} minHeight={350}>
                                                <BarChart width={730} height={250} data={data_grafica} layout="vertical" barCategoryGap={1} barSize={30}>
                                                    <XAxis type="number" />
                                                    <Tooltip />
                                                    <CartesianGrid strokeDasharray="3 3" />
                                                    <YAxis type="category" width={150} tick={{ dx: -7 }} padding={{ left: 20 }} dataKey="name" />
                                                    <Bar dataKey="value" fill="#2d8de6" background={{ fill: '#eee' }} label={{ position: 'insideRight', fontSize: '14px', fill: '#fff', fontWeight: 'bold' }}>
                                                        {data_grafica && data_grafica.map((entry, index) => (
                                                            <Cell key={`cell-${index}`} fill={entry.color} />
                                                        ))}
                                                    </Bar>
                                                </BarChart>
                                            </ResponsiveContainer>
                                        </LoadMask>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Card>
            </div>
        )
    }
}
