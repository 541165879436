import { connect } from 'react-redux';
import { actions } from '../../../../../redux/modules/publicidades';
import ValidadosList from './ListaValidados';


const ms2p = (state) => {
    return {
        ...state.publicidades,
    };
};

const md2p = { ...actions };

export default connect(ms2p, md2p)(ValidadosList);
