import { createAction, handleActions } from 'redux-actions';
import { api } from "api";
import { initialize as initializeForm } from "redux-form";
import { NotificationManager } from "react-notifications";
import { push } from "react-router-redux";
import _ from "lodash";
import { reset } from 'redux-form';
import { USER_ADMIN } from "../../../utility/constants";
import moment from 'moment';

const LOADER = 'LOADER_REPORTES_PROYECTOS_OFERTAS';
const DATA = 'DATA_REPORTES_PROYECTOS_OFERTAS';
const ITEM_DATA = 'ITEM_REPORTES_PROYECTOS_OFERTAS';
const PAGE = 'PAGE_REPORTES_PROYECTOS_OFERTAS';
const ORDERING = 'ORDERING_REPORTES_PROYECTOS_OFERTAS';
const SEARCH = 'SEARCH_REPORTES_PROYECTOS_OFERTAS';
const TOTALES = 'TOTALES_REPORTES_PROYECTOS_OFERTAS';
const GRAFICA = 'GRAFICA_REPORTES_PROYECTOS_OFERTAS';

// FILTROS DE REPORTES PROYECTOS Y OFERTAS
const FILTRO_FECHA_INICIO_PROYECTOS_OFERTAS = 'FILTRO_FECHA_INICIO_PROYECTOS_OFERTAS';
const FILTRO_FECHA_FIN_PROYECTOS_OFERTAS = 'FILTRO_FECHA_FIN_PROYECTOS_OFERTAS';
const FILTRO_CONTRATISTAS_PROYECTOS_OFERTAS = 'FILTRO_CONTRATISTAS_PROYECTOS_OFERTAS';
const FILTRO_ESTADO_PROYECTOS_OFERTAS = 'FILTRO_ESTADO_PROYECTOS_OFERTAS';

// -----------------------------------
// Pure Actions
// -----------------------------------

const setLoader = loader => ({
    type: LOADER,
    loader,
});

const setData = data => ({
    type: DATA,
    data,
});

const setItem = item => ({
    type: ITEM_DATA,
    item,
});

const setPage = page => ({
    type: PAGE,
    page,
});

const setOrdering = ordering => ({
    type: ORDERING,
    ordering,
});

const setSearch = search => ({
    type: SEARCH,
    search,
});

const setTotales = totales => ({
    type: TOTALES,
    totales,
});

const setGrafica = data_grafica => ({
    type: GRAFICA,
    data_grafica,
});

// ------------------------------------
// Actions
// ------------------------------------
const listarProyectosReporte = (page = 1) => (dispatch, getStore) => {
    const resource = getStore().proyectosYOfertas;
    const params = { page };

    if (resource.filtro_fecha_inicio && resource.filtro_fecha_fin) {
        params.fecha_inicio = moment(resource.filtro_fecha_inicio).format('YYYY-MM-DD');
        params.fecha_fin = moment(resource.filtro_fecha_fin).format('YYYY-MM-DD');
    }
    if (resource.filtro_contratista) {
        params.contratista = resource.filtro_contratista.id;
    }
    if (resource.filtro_estado && resource.filtro_estado.value > 0) {
        params.estado = resource.filtro_estado.value;
    }
    dispatch(setLoader(true));
    api.get('proyecto/proyectos_reporte_proyectos_ofertas', params).then((response) => {
        dispatch(setData(response));
        dispatch(setPage(page));
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const dataGraficaProyectosReporte = () => (dispatch, getStore) => {
    const resource = getStore().proyectosYOfertas;
    const params = {};
    if (resource.filtro_fecha_inicio && resource.filtro_fecha_fin) {
        params.fecha_inicio = moment(resource.filtro_fecha_inicio).format('YYYY-MM-DD');
        params.fecha_fin = moment(resource.filtro_fecha_fin).format('YYYY-MM-DD');
    }
    if (resource.filtro_contratista) {
        params.contratista = resource.filtro_contratista.id;
    }
    if (resource.filtro_estado && resource.filtro_estado.value > 0) {
        params.estado = resource.filtro_estado.value;
    }
    dispatch(setLoader(true));
    dispatch(setGrafica([]));
    api.get('reportes/data_grafica_reporte_proyectos_ofertas', params).then((response) => {
        dispatch(setGrafica(response));
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const dataTotalesReporteProyectosYOfertas = () => (dispatch, getStore) => {
    const resource = getStore().proyectosYOfertas;
    const params = {};

    if (resource.filtro_fecha_inicio && resource.filtro_fecha_fin) {
        params.fecha_inicio = moment(resource.filtro_fecha_inicio).format('YYYY-MM-DD');
        params.fecha_fin = moment(resource.filtro_fecha_fin).format('YYYY-MM-DD');
    }
    if (resource.filtro_contratista) {
        params.contratista = resource.filtro_contratista.id;
    }
    if (resource.filtro_estado && resource.filtro_estado.value > 0) {
        params.estado = resource.filtro_estado.value;
    }
    dispatch(setLoader(true));
    api.get('reportes/reporte_proyectos_ofertas', params).then((response) => {
        dispatch(setTotales(response));
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const changeFechaInicio = filtro_fecha_inicio => (dispatch) => {
    dispatch({ type: FILTRO_FECHA_INICIO_PROYECTOS_OFERTAS, filtro_fecha_inicio });
    dispatch(dataTotalesReporteProyectosYOfertas())
    dispatch(listarProyectosReporte())
    dispatch(dataGraficaProyectosReporte())
};

const changeFechaFinal = filtro_fecha_fin => (dispatch) => {
    dispatch({ type: FILTRO_FECHA_FIN_PROYECTOS_OFERTAS, filtro_fecha_fin });
    dispatch(dataTotalesReporteProyectosYOfertas())
    dispatch(listarProyectosReporte())
    dispatch(dataGraficaProyectosReporte())
};

const changeContratista = filtro_contratista => (dispatch) => {
    dispatch({ type: FILTRO_CONTRATISTAS_PROYECTOS_OFERTAS, filtro_contratista });
    dispatch(dataTotalesReporteProyectosYOfertas())
    dispatch(listarProyectosReporte())
    dispatch(dataGraficaProyectosReporte())
};

const changeEstado = filtro_estado => (dispatch) => {
    dispatch({ type: FILTRO_ESTADO_PROYECTOS_OFERTAS, filtro_estado });
    dispatch(dataTotalesReporteProyectosYOfertas())
    dispatch(listarProyectosReporte())
    dispatch(dataGraficaProyectosReporte())
};

export const actions = {
    listarProyectosReporte,
    dataGraficaProyectosReporte,
    dataTotalesReporteProyectosYOfertas,
    changeFechaInicio,
    changeFechaFinal,
    changeContratista,
    changeEstado
};

// ------------------------------------
// Reducers
// ------------------------------------

export const reducers = {
    [LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    [DATA]: (state, { data }) => {
        return {
            ...state,
            data,
        };
    },
    [ITEM_DATA]: (state, { item }) => {
        return {
            ...state,
            item,
        };
    },
    [PAGE]: (state, { page }) => {
        return {
            ...state,
            page,
        };
    },
    [ORDERING]: (state, { ordering }) => {
        return {
            ...state,
            ordering,
        };
    },
    [SEARCH]: (state, { search }) => {
        return {
            ...state,
            search,
        };
    },
    [SEARCH]: (state, { search }) => {
        return {
            ...state,
            search,
        };
    },
    [TOTALES]: (state, { totales }) => {
        return {
            ...state,
            totales,
        };
    },
    [GRAFICA]: (state, { data_grafica }) => {
        return {
            ...state,
            data_grafica,
        };
    },
    // FILTROS DE REPORTES PROYECTOS Y OFERTAS
    [FILTRO_FECHA_INICIO_PROYECTOS_OFERTAS]: (state, { filtro_fecha_inicio }) => {
        return {
            ...state,
            filtro_fecha_inicio,
        };
    },
    [FILTRO_FECHA_FIN_PROYECTOS_OFERTAS]: (state, { filtro_fecha_fin }) => {
        return {
            ...state,
            filtro_fecha_fin,
        };
    },
    [FILTRO_CONTRATISTAS_PROYECTOS_OFERTAS]: (state, { filtro_contratista }) => {
        return {
            ...state,
            filtro_contratista,
        };
    },
    [FILTRO_ESTADO_PROYECTOS_OFERTAS]: (state, { filtro_estado }) => {
        return {
            ...state,
            filtro_estado,
        };
    },
};

export const initialState = {
    loader: false,
    data: {
        results: [],
        count: 0,
    },
    data_grafica: [],
    item: {},
    totales: {},
    page: 1,
    ordering: '',
    search: '',
    filtro_fecha_inicio: null,
    filtro_fecha_fin: moment().endOf('month'),
    filtro_contratista: null,
    filtro_estado: -1,
};

export default handleActions(reducers, initialState);
