import { connect } from 'react-redux';
import { actions } from '../../../../../redux/modules/tipo_vehiculo/tipo_vehiculo';
import Crear from './TipoVehiculoCrear';

const mstp = state => {
    return {...state.tipos_vehiculo}
};

const mdtp = {
    ...actions
};

export default connect(mstp, mdtp)(Crear)
