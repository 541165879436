import React, { Component } from 'react'
import Grid from "../../../Utils/Grid";
import Card from "Utils/Cards/Card"
import { TableHeaderColumn } from "react-bootstrap-table";
import { standardActions } from "../../../Utils/Grid/StandardActions";

export default class Lista extends Component {
    componentWillMount() {
        const { listarPendientesValidar } = this.props;
        listarPendientesValidar(1);
    }

    render() {
        const { listarPendientesValidar: onPageChange, onSortChange } = this.props; //Funciones
        const { data, loader, page } = this.props; //Constantes
        return (
            <div className="mb-4 col-12 mt-4">
                <Card titulo="PUBLICIDADES PENDIENTES DE VALIDAR">
                    <Grid hover striped data={data} loading={loader} onPageChange={onPageChange} onSortChange={onSortChange}
                        page={page}>
                        <TableHeaderColumn
                            dataField="id"
                            dataAlign="center"
                            dataSort
                            isKey
                            width='15%'
                            dataFormat={standardActions({ ver: "publicidad" })}
                        />
                        <TableHeaderColumn
                            dataField="creador"
                            dataSort
                            dataFormat={(cell) => cell && cell.nombre ? cell.nombre : ''}
                        >
                            NOMBRE
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataAlign="left"
                            dataField="id"
                            dataSort
                        >
                            CÓDIGO
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="creador"
                            dataSort
                            dataFormat={(cell) => cell && cell.username ? cell.username : ''}
                        >
                            USUARIO
                        </TableHeaderColumn>
                    </Grid>
                </Card>
            </div>
        )
    }
}
