import React, {Component} from 'react'
import Grid from "../../../Utils/Grid";
import Card from "Utils/Cards/Card"
import {TableHeaderColumn} from "react-bootstrap-table";
import {standardActions} from "../../../Utils/Grid/StandardActions";
import Link from "react-router-dom/es/Link";
import Header from "Utils/Grid/Header";

export default class UsuariosList extends Component{
    componentWillMount() {
        const { listarPendientesValidar} = this.props;
        listarPendientesValidar(1);
    }

    render() {
        const { listarPendientesValidar: onPageChange, onSortChange, eliminar } = this.props; //Funciones
        const { data, loader, page } = this.props; //Constantes
        return(
            <div className="mb-4 col-12 mt-4">
                <Card titulo="CONTRATISTAS PENDIENTES DE VALIDAR">
                    {/* <Header to="administrador/crear" textBtn="CREAR ADMINISTRADOR" {...this.props} /> */}
                    <Grid hover striped data={data} loading={loader} onPageChange={onPageChange} onSortChange={onSortChange}
                    page={page}>
                        <TableHeaderColumn
                            dataField="id"
                            dataAlign="center"
                            dataSort
                            isKey
                            width='15%'
                            dataFormat={standardActions({ ver: "contratista"})}
                        />
                        <TableHeaderColumn
                            dataField="nombre"
                            dataSort
                        >
                            NOMBRE
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="email"
                            dataSort
                        >
                            EMAIL
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="username"
                            dataSort
                        >
                            USUARIO
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="tipo_usuario_text"
                            dataSort
                        >
                            TIPO DE USUARIO
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataAlign="left"
                            dataField="id"
                            dataSort
                        >
                            CÓDIGO
                        </TableHeaderColumn>
                    </Grid>
                </Card>
            </div>
        )
    }
}
