import { connect } from 'react-redux';
import { actions } from '../../../../../redux/modules/dashboard';
import DashboardContainer from './DashboardContainer';

const ms2p = (state) => {
    return {
        ...state.dashboard,
        me: state.login.me,
    };
};

const md2p = { ...actions };

export default connect(ms2p, md2p)(DashboardContainer);
