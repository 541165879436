import React, { useState, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { RenderCurrency, RenderDollar } from '../renderField/renderReadField';
import { ADJUDICADO_PENDIENTE_DE_INICIAR, BORRADOR, EN_CURSO, EN_RECEPCION_OFERTAS, FINALIZADO, USER_CONTRATISTA_EMPRESA, USER_CONTRATISTA_INDIVIDUAL, USER_TRANSPORTISTA } from '../../../../utility/constants';
import { COLOR_BORDE_LEFT, COLOR_BOTON, COLOR_ESTADO, TITULO_ESTADO } from '../../../../utility/utils';
import moment from 'moment';

function CardGrandeProyecto(props) {
    const {
        nombre = "",
        direccion_origen = "",
        ciudad_origen = "",
        pais_origen = "",
        direccion_destino = "",
        ciudad_destino = "",
        pais_destino = "",
        tipo_vehiculo = "",
        presupuesto = 0,
        dia_entrega = "",
        fecha_cierre_ofertas = "",
        fecha_hora_posicionamiento = "",
        descripcion_trabajo = "",
        notas = "",
        estado_proyecto = 0,
        margenTop = true,
        saldo_a_favor = 0,
        justificacion_cancelacion = "",
        codigo_tracking,
        total_ofertas = 0,
    } = props;
    return (
        <div className="card-login pl-0 pl-sm-3 pt-3 pr-4 pb-3" style={COLOR_BORDE_LEFT(estado_proyecto)}>

            <Fragment>
                {props.children}
            </Fragment>

            <div className={`px-0 ${margenTop && "py-2"} d-flex align-items-center justify-content-end flex-wrap`}>
                <div className="col-12 mb-3">
                    <h4 className="gris-oscuro bold">{nombre}</h4>
                </div>
                <div className="align-self-start  d-flex flex-column col-md-12 col-lg-6 col-12">
                    {!!codigo_tracking && codigo_tracking !== null && (
                        <span className="mb-1 gris-letras bold">Código de trackeo: <span className="gris-oscuro bold">{codigo_tracking}</span></span>
                    )}
                    <span className="mb-1 gris-letras bold">Origen: <span className="gris-oscuro bold">{`${direccion_origen}, ${ciudad_origen}, ${pais_origen}`}</span></span>
                    <span className="mb-1 gris-letras bold">Destino: <span className="gris-oscuro bold">{`${direccion_destino}, ${ciudad_destino}, ${pais_destino}`}</span></span>
                    <span className="mb-1 gris-letras bold">Tipo de transporte: <span className="gris-oscuro bold">{tipo_vehiculo}</span></span>
                    <span className="mb-1 gris-letras bold">Presupuesto: <RenderDollar value={presupuesto} className="gris-oscuro bold" /></span>
                    {saldo_a_favor > 0 &&
                        <span className="mb-1 gris-oscuro bold">Saldo a favor: <RenderDollar value={saldo_a_favor} className="gris-oscuro bold" /></span>
                    }
                    <span className="mb-1 gris-letras bold">Ofertas: <span className="gris-oscuro bold">{total_ofertas}</span></span>
                    <span className="mb-1 gris-letras bold mb-0 mb-lg-4">Fecha de entrega: <span className="gris-oscuro bold">{moment(dia_entrega).format('DD-MM-YYYY')}</span></span>

                    <span className="mb-1 gris-letras bold">Fecha cierre de ofertas: <span className="gris-oscuro bold">{moment(fecha_cierre_ofertas).format('DD-MM-YYYY')}</span></span>
                    <span className="mb-1 gris-letras bold">Fecha y Hora de posicionamiento: <span className="gris-oscuro bold">{moment(fecha_hora_posicionamiento).format('DD-MM-YYYY HH:mm')}</span></span>
                    {saldo_a_favor > 0 && justificacion_cancelacion &&
                        <Fragment>
                            <span className="gris-letras bold mt-1 mt-lg-3">Descripcion de la cancelación</span>
                            <span className="gris-oscuro bold mb-1">{justificacion_cancelacion}</span>
                        </Fragment>
                    }
                </div>
                <div className="align-self-start d-flex flex-column col-md-12 col-lg-6 col-12 mb-lg-4">
                    <span className="gris-letras bold">Especificaciones del viaje:</span>
                    <span className="gris-oscuro bold mb-1 mb-lg-5">{descripcion_trabajo}</span>
                    <span className="gris-letras bold">Nota:</span>
                    <span className="gris-oscuro bold mb-1">{notas}</span>
                </div>
                <div className="col-12">
                    <div className="row">
                        <div className="col-md-12 col-lg-6 col-12 px-0 px-lg-3 mt-4 mb-1">
                            <span className="mb-1 text-sm bold" style={{ color: COLOR_ESTADO(estado_proyecto) }}>
                                {TITULO_ESTADO(estado_proyecto)}
                            </span>
                        </div>
                    </div>
                </div>
            </div>

        </div >
    )
}

export default CardGrandeProyecto;
