import { createAction, handleActions } from 'redux-actions';
import { api } from "api";
import { initialize as initializeForm, change } from "redux-form";
import { NotificationManager } from "react-notifications";
import { push } from "react-router-redux";
import { getMe } from '../cuenta/login';
import _ from "lodash";
import { reset } from 'redux-form';
import { USER_ADMIN } from "../../../utility/constants";

const LOADER = 'LOADER_PROYECTOS';
const DATA = 'DATA_PROYECTOS';
const ITEM_DATA = 'ITEM_PROYECTOS';
const PAGE = 'PAGE_PROYECTOS';
const ORDERING = 'ORDERING_PROYECTOS';
const SEARCH = 'SEARCH_PROYECTOS';
const OFERTA_DETALLE = 'OFERTA_DETALLE_PROYECTOS';
const VIAJE_DETALLE = 'VIAJE_DETALLE';

// -----------------------------------
// Pure Actions
// -----------------------------------

const setLoader = loader => ({
    type: LOADER,
    loader,
});

const setData = data => ({
    type: DATA,
    data,
});

const setItem = item => ({
    type: ITEM_DATA,
    item,
});

const setPage = page => ({
    type: PAGE,
    page,
});

const setOrdering = ordering => ({
    type: ORDERING,
    ordering,
});

const setSearch = search => ({
    type: SEARCH,
    search,
});

// ------------------------------------
// Actions
// ------------------------------------

const listar = (page = 1) => (dispatch, getStore) => {
    const resource = getStore().proyectos;
    const params = { page };
    params.ordering = resource.ordering;
    params.search = resource.search;
    if (resource.estado_proyecto && resource.estado_proyecto.value > 0) {
        params.estado_proyecto = resource.estado_proyecto.value;
    }
    dispatch(setLoader(true));
    api.get('proyecto', params).then((response) => {
        dispatch(setData(response));
        dispatch(setPage(page));
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const leer = id => (dispatch) => {
    dispatch(setLoader(true));
    dispatch(setViajeDetalle(false));
    api.get(`${'proyecto'}/${id}`).then((response) => {
        dispatch(setItem(response));
        if (response.adjudicado) {
            dispatch(viajeDetalle(id));
        }

    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const crear = data => (dispatch) => {
    dispatch(setLoader(true));
    api.post('proyecto', data).then(() => {
        NotificationManager.success('Proyecto creado', 'Éxito', 3000);
        dispatch(cleanForm()); // Limpiar el formulario
        dispatch(push('/proyectos'));
    }).catch(() => {
        NotificationManager.error('Error en la creación', 'ERROR');
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

const editar = (id, data) => (dispatch) => {
    dispatch(setLoader(true));
    api.put(`${'proyecto'}/${id}`, data).then(() => {
        NotificationManager.success('Proyecto actualizado', 'Éxito', 3000);
        dispatch(push('/proyectos'));
    }).catch(() => {
        NotificationManager.error('Error en la edición', 'ERROR', 3000);
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

const eliminar = id => (dispatch) => {
    dispatch(setLoader(true));
    api.eliminar(`${'proyecto'}/${id}`).then(() => {
        dispatch(listar());
        NotificationManager.success('Registro eliminado', 'Éxito', 3000);
        dispatch(push('/reportes/proyecto-ofertas'));
    }).catch(() => {
        NotificationManager.error('Error en la transacción', 'ERROR', 3000);
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const cleanForm = () => (dispatch) => {
    dispatch(initializeForm('ProyectoForm', {}));
};

const searchChange = search => (dispatch) => {
    dispatch(setSearch(search));
    dispatch(listar());
};

const onChangeEstadoProyecto = estado_proyecto => (dispatch) => {
    dispatch({ type: FILTRO_ESTADO_PROYECTOS, estado_proyecto })
    dispatch(listar());
};

const onSortChange = ordering => (dispatch, getStore) => {
    const sort = getStore().proyectos.ordering;
    if (ordering === sort) {
        dispatch(setOrdering(`-${ordering}`));
    } else {
        dispatch(setOrdering(ordering));
    }
    dispatch(listar());
};

const setOfertaDetalle = oferta_detalle => (dispatch) => {
    dispatch({ type: OFERTA_DETALLE, oferta_detalle })
};

const ofertaDetalle = (id) => (dispatch) => {
    dispatch(setLoader(true));
    api.get(`${'oferta'}/${id}`).then((response) => {
        dispatch(setOfertaDetalle(response));
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};


const viajeDetalle = (id) => (dispatch) => {
    dispatch(setLoader(true));
    api.get(`${'proyecto'}/${id}/detalle_viaje_proyecto`).then((response) => {
        dispatch(setViajeDetalle(response));
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const cancelarViajeDefinitivamente = (id, justificacion = null) => (dispatch) => {
    const data = {}
    if (justificacion) {
        data.justificacion = justificacion;
    }
    dispatch(setLoader(true));
    api.put(`${'viaje'}/${id}/cancelar_viaje_definitivamente`, data).then(() => {
        NotificationManager.success('Viaje cancelado correctamente, pronto se realizara la devolución monetario.', 'Éxito', 3000);
    }).catch(() => {
        let mensaje = 'Error al cancelar el viaje';
        if (error) {
            if (error.detail)
                mensaje = error.detail;
        }
        NotificationManager.error(mensaje, 'ERROR', 5000);
    }).finally(() => {
        dispatch(setLoader(false));
        dispatch(push('/reportes/proyecto-ofertas'));
    });
}

const setViajeDetalle = viaje_detalle => (dispatch) => {
    dispatch({ type: VIAJE_DETALLE, viaje_detalle })
};
export const actions = {
    listar,
    leer,
    crear,
    editar,
    eliminar,
    searchChange,
    onSortChange,
    onChangeEstadoProyecto,
    cleanForm,
    ofertaDetalle,
    cancelarViajeDefinitivamente,
};

// ------------------------------------
// Reducers
// ------------------------------------

export const reducers = {
    [LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    [DATA]: (state, { data }) => {
        return {
            ...state,
            data,
        };
    },
    [ITEM_DATA]: (state, { item }) => {
        return {
            ...state,
            item,
        };
    },
    [PAGE]: (state, { page }) => {
        return {
            ...state,
            page,
        };
    },
    [ORDERING]: (state, { ordering }) => {
        return {
            ...state,
            ordering,
        };
    },
    [SEARCH]: (state, { search }) => {
        return {
            ...state,
            search,
        };
    },
    [OFERTA_DETALLE]: (state, { oferta_detalle }) => {
        return {
            ...state,
            oferta_detalle,
        };
    },
    [VIAJE_DETALLE]: (state, { viaje_detalle }) => {
        return {
            ...state,
            viaje_detalle,
        };
    },
};



export const initialState = {
    loader: false,
    data: {
        results: [],
        count: 0,
    },
    item: {},
    page: 1,
    ordering: '',
    search: '',
    oferta_detalle: {},
    viaje_detalle: {},
};

export default handleActions(reducers, initialState);
