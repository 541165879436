import React, { Fragment } from 'react';
import { Field, reduxForm } from 'redux-form';
import { validate, validators, combine } from 'validate-redux-form';
import { renderField } from 'Utils/renderField';
import CardEmpty from 'Utils/Cards/CardEmpty';
import Alerta from 'Utils/Alerta/Alerta';
import { Link } from "react-router-dom";

const alerta_img = require('assets/img/icons/alert.svg');

const ChangeForm = (props) => {
    const { handleSubmit } = props;
    return (
        <form name="loginForm" className="form-validate d-flex flex-sm-row align-items-center" onSubmit={handleSubmit}>
            <div className="col-12">
                <div className="d-flex justify-content-center flex-column align-items-center mb-3">
                    <img src={alerta_img} alt="Alerta" width={50} className="img-fluid blink-danger" />
                    <span className="gris-oscuro bold mt-1">¿Olvidaste tu contraseña?</span>
                </div>
                <div className="d-flex flex-wrap justify-content-center align-items-center">
                    <div className="col-md-11 col-12 px-md-3 px-0">
                        <div className="col-md-12 col-sm-12 text-center">
                            <label className="pl-0" htmlFor="username">USUARIO</label>
                        </div>
                        <div className="col-md-12">
                            <Field name="username" component={renderField} type="text" className="form-control" maxLength="255" />
                        </div>
                        <div className="col-md-12 form-group text-center">
                            <span className="azul text-xsm">Enviaremos un correo de recuperación de contraseña</span>
                        </div>
                    </div>
                </div>
                <div className="d-flex flex-wrap justify-content-center align-items-center mt-3">
                    <div className="col-md-5 col-12">
                        <Link className="btn btn-dark btn-block" to="/login">Cerrar</Link>
                    </div>
                    <div className="col-md-5 col-12 mt-md-0 mt-3 login">
                        <button type="submit" className="btn btn-primary btn-block">Recuperar</button>
                    </div>
                </div>
            </div>
        </form>
    );
};

export default reduxForm({
    form: 'sendPass',
    validate: (data) => {
        return validate(data, {
            username: validators.exists()('Campo requerido.'),
        });
    },
})(ChangeForm);
