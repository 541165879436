import { connect } from 'react-redux';
import { actions } from '../../../../../redux/modules/devoluciones';
import Ver from './DevolucionVer';

const mstp = state => {
    return { ...state.devoluciones }
};

const mdtp = {
    ...actions
};

export default connect(mstp, mdtp)(Ver)
