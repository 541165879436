import PropTypes from 'prop-types';
import React, { Component } from 'react';
import {Redirect} from 'react-router-dom';
import ChangeForm from './ChangeForm';
// import './change.css';
import LoadMask from "Utils/LoadMask/LoadMask";

class ChangePassword extends Component {
    onSubmitReset = (data) => {
        this.props.resetPassword({...data, token: this.props.match.params.token});
    };
    render() {
        const { changePassword, me, loader } = this.props;
        let recover = false;
        if(this.props.match && this.props.match.params && this.props.match.params.token){
            recover = true;
        }
        return (
            <div className="blue-gradient-bg ">
                <div className="contenedor-principal-form">
                    <div className="col-12 h-100 d-flex justify-content-center align-items-center flex-wrap">
                        <div className="col-12 login-wrapper d-flex flex-column">
                            {recover ?
                                <React.Fragment>
                                    <div className="col-xl-4 col-lg-6 col-md-6 col-11 mb-3 text-center">
                                        <img src={require('assets/img/logo_completo.svg')} className="img-fluid" alt="Logo"/>
                                    </div>
                                    <div className="card card-login col-xl-4 col-lg-6 col-md-7 col-11 mb-5">
                                        <LoadMask loading={loader} light>
                                            <ChangeForm onSubmit={recover ? this.onSubmitReset : changePassword} reset_pass={recover} />
                                        </LoadMask>
                                    </div>
                                </React.Fragment>
                                :
                                <div className="card card-login col-xl-6 col-lg-8 col-md-9 col-12 my-5">
                                    <LoadMask loading={loader} light>
                                        <ChangeForm onSubmit={recover ? this.onSubmitReset : changePassword} reset_pass={recover} me={me} />
                                    </LoadMask>
                                </div>
                            }

                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default ChangePassword;
